import { environment } from "environments/environment";

// prettier-ignore
export class ApiConstants {
    // EXPORT
    public static API_EXPORT_FOCUS_AREA_EXCEL = (id: number) => `${environment.base_url}/api/focus-area/export-excel/${id}`;
    public static API_EXPORT_FOCUS_AREA_CSV = (id: number) => `${environment.base_url}/api/focus-area/export-csv/${id}`;
    public static API_EXPORT_KICK_OFF_EXCEL = (id: number) => `${environment.base_url}/api/event/export-excel/${id}/kickOffSession`;
    public static API_EXPORT_KICK_OFF_CSV = (id: number) => `${environment.base_url}/api/event/export-csv/${id}/kickOffSession`;
    public static API_EXPORT_WORKSHOP_EXCEL = (id: number) => `${environment.base_url}/api/event/export-excel/${id}/workshopSession`;
    public static API_EXPORT_WORKSHOP_CSV = (id: number) => `${environment.base_url}/api/event/export-csv/${id}/workshopSession`;
    public static API_EXPORT_CHECK_IN_EXCEL = (id: number) => `${environment.base_url}/api/event/export-excel/${id}/checkInSession`;
    public static API_EXPORT_CHECK_IN_CSV = (id: number) => `${environment.base_url}/api/event/export-csv/${id}/checkInSession`;
    public static API_EXPORT_TEAM_EXCEL = (id: number) => `${environment.base_url}/api/team/export-excel/${id}`;
    public static API_EXPORT_TEAM_CSV = (id: number) => `${environment.base_url}/api/team/export-csv/${id}`;
    public static API_EXPORT_TEAM_CL_EXCEL =`${environment.base_url}/api/team/export-excel-cl`;
    public static API_EXPORT_TEAM_CL_CSV =`${environment.base_url}/api/team/export-csv-cl`;
    public static API_MEDIA_UPLOAD = `${environment.base_url}/api/media/upload`;
    public static API_MEDIA_PREVIEW = `${environment.base_url}/api/media/preview`;
    public static API_EXPORT_GLOBAL_EXCEL = `${environment.base_url}/api/company/export-excel`
    public static API_EXPORT_GLOBAL_CSV = `${environment.base_url}/api/company/csv`
  }
