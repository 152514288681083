import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { KissSvgIconModule } from "@kiss/components/common";
import { CustomToastComponent } from "./custom-toast.component";

@NgModule({
  declarations: [CustomToastComponent],
  imports: [CommonModule, KissSvgIconModule],
  exports: [CustomToastComponent]
})
export class CustomToastModule {}
