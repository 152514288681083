import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class FileStateService {
  private files: { name: string; type: string; size: number; sizeReadable: string; preview: string | null }[] = [];

  setFiles(files: { name: string; type: string; size: number; sizeReadable: string; preview: string | null }[]) {
    this.files = files;
  }

  getFiles() {
    return this.files;
  }
}
