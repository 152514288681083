import { Injectable } from "@angular/core";
import { Router, Route, UrlSegment } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { RoutesConstants } from "../constants/routes.constrants";
import { AuthService } from "../services/auth/auth.service";
import { TOAST_AUTH_POSITION_URLS, TOASTR_AUTH_CONFIG } from "../constants/custom-toast-config";

@Injectable()
export class AuthGuard {
  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _toastr: ToastrService
  ) {}

  async canMatch(route: Route, segments: UrlSegment[]) {
    if (this._auth.isAuthenticated()) {
      return true;
    }

    const isRefreshSuccess = await this.refreshingToken();

    if (!isRefreshSuccess) {
      const fullPath = segments.reduce((path, currentSegment) => {
        return `${path}/${currentSegment.path}`;
      }, "");

      let queryParams = { returnUrl: fullPath };

      this.redirectUser(queryParams);
    }

    return isRefreshSuccess;
  }

  private redirectUser(queryParams?: any) {
    this._router.navigate([RoutesConstants.BASE_AUTH_REDIRECT], { queryParams: queryParams });
  }

  private async refreshingToken(): Promise<boolean> {
    const accessToken = this._auth.getAccessTokenToken();
    const refreshToken = this._auth.getRefreshToken();

    if (!accessToken || !refreshToken) {
      return this._ifNoTokenFound();
    }

    return this._triggerRefresh();
  }

  private _getToastPosition() {
    const isAuthRoute = TOAST_AUTH_POSITION_URLS.some((url: string) => this._router.url.includes(url));

    if (isAuthRoute) {
      return TOASTR_AUTH_CONFIG;
    }
  }

  private async _ifNoTokenFound() {
    const toastPosition = this._getToastPosition();

    await lastValueFrom(this._auth.logout()).then(() => {
      this.redirectUser();
    });

    this._toastr.warning("This page requires authentication. Please log in to access.", undefined, toastPosition);

    return false;
  }

  private async _triggerRefresh() {
    try {
      await lastValueFrom(this._auth.refreshToken());
      return true;
    } catch (error) {
      const toastPosition = this._getToastPosition();

      await lastValueFrom(this._auth.logout()).then(() => {
        this.redirectUser();
      });

      this._toastr.warning("Your session has expired, please login again to continue using the application.", undefined, toastPosition);
      return false;
    }
  }
}
