import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { KissDatepickerDaySettings } from "../kiss-datepicker-settings/kiss-datepicker-day-settings";
import { KissDatepickerCalendarSettings } from "../types/kiss-datepicker-calendar-settings";
import { KissDatepickerSelectionMode, KissDatepickerSelectionModeEnums } from "../types/kiss-datepicker-selection-mode";
import { KissDatepickerCalendar } from "./kiss-datepicker-calendar";
import { KissDatepickerDay } from "./kiss-datepicker-day";

@Component({
  selector: "kiss-datepicker-day",
  templateUrl: "./kiss-datepicker-day.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "kiss-datepicker__day"
  }
})
export class KissDatepickerDayComponent implements OnChanges {
  calendar: KissDatepickerCalendar;

  @Input("kissDatepickerDaySettings") daySettings: KissDatepickerDaySettings;

  @Input() selected: Date | Date[];

  @Input() viewDate: Date;

  @Input() selectionMode: KissDatepickerSelectionMode;

  @Output() onChange = new EventEmitter();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["viewDate"] || changes["selected"] || changes["daySettings"] || changes["selectionMode"]) {
      const settings: KissDatepickerCalendarSettings = {
        viewDate: this.viewDate,
        enabledDates: this.daySettings.enabledDates,
        selected: this.selected,
        selectionMode: this.selectionMode,
        minDate: this.daySettings.minDate,
        maxDate: this.daySettings.maxDate
      };

      this.calendar = new KissDatepickerCalendar(settings);
    }

    if (changes["viewDate"]) {
      this._updateDateSelection(this.selected);
    }

    if (changes["selected"]) {
      const current = changes["selected"].currentValue;
      const previous = changes["selected"].previousValue;

      if (current !== previous) {
        this._updateDateSelection(this.selected);
      }
    }
  }

  onDateClick(event, date: KissDatepickerDay) {
    event.stopPropagation();
    this.onChange.next(date);
  }

  onDateMouseover(event, date: KissDatepickerDay) {
    this._showBetweenDateHover(date);
  }

  private _showBetweenDateHover(date: KissDatepickerDay) {
    if (this.selectionMode !== KissDatepickerSelectionModeEnums.RANGE || (this.selected as Date[])?.length != 1) return;
    for (const row of this.calendar.dates) {
      for (const cell of row) {
        const isBetweenLess = cell.date.getTime() > date.date.getTime() && cell.date.getTime() < (this.selected as Date[])[0].getTime();
        const isBetweenMore = cell.date.getTime() < date.date.getTime() && cell.date.getTime() > (this.selected as Date[])[0].getTime();

        cell.isBetweenRangeHover = isBetweenLess || isBetweenMore;
      }
    }
  }

  private _updateDateSelection(date: Date | Date[]) {
    for (const row of this.calendar.dates) {
      for (const cell of row) {
        switch (this.selectionMode) {
          case KissDatepickerSelectionModeEnums.RANGE:
            this._updateRangeDateSelection(cell, date as Date[]);
            break;
          default:
            this._updateDatepickerDateSelection(cell, date as Date);
        }
      }
    }
  }

  private _updateDatepickerDateSelection(cell: KissDatepickerDay, date: Date) {
    cell.isSelected = cell.date?.toDateString() === date?.toDateString();
  }

  private _updateRangeDateSelection(cell: KissDatepickerDay, dates: Date[]) {
    cell.isSelected = !!dates?.some((date) => cell.date?.toDateString() === date?.toDateString());
  }
}
