import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { KissMenuModule, KissSvgIconModule } from "@kiss/components/common";
import { KissBreadcrumbsModule } from "@kiss/components/common/kiss-breadcrumbs/kiss-breadcrumbs.module";
import { KissDividerModule } from "@kiss/components/common/kiss-divider/kiss-divider.module";
import { KissUserAvatarModule } from "@kiss/components/common/kiss-user-avatar/kiss-user-avatar.module";
import { KissMediaObserverService } from "@kiss/services";
import { KissToolbarComponent } from "./kiss-toolbar.component";

@NgModule({
  declarations: [KissToolbarComponent],
  imports: [
    CommonModule,
    KissBreadcrumbsModule.forRoot({
      icon: {
        type: "text",
        data: " - "
      }
    }),
    KissDividerModule,
    KissUserAvatarModule,
    KissMenuModule,
    KissSvgIconModule
  ],
  exports: [KissToolbarComponent],
  providers: [KissMediaObserverService]
})
export class KissToolbarModule {}
