import { CompanyColorEnum } from "app/shared/enums/company-color.enum";

export const COMPANY_COLOR_MAP = {
  [CompanyColorEnum.BLACK]: {
    base: "#000000",
    highlight: "#4d4d4d",
    text: "#FFFFFF",
    button: "#000000",
    "button-highlight": "rgba(0, 0, 0, 0.25)" // 75% opacity for black
  },
  [CompanyColorEnum.CHARCOAL]: {
    base: "#34383A",
    highlight: "#717475",
    text: "#FFFFFF",
    button: "#34383A",
    "button-highlight": "rgba(52, 56, 58, 0.25)" // 75% opacity for charcoal
  },
  [CompanyColorEnum.DEFAULT]: {
    base: "#342D66",
    highlight: "#716C94",
    text: "#FFFFFF",
    button: "#342D66",
    "button-highlight": "rgba(0, 103, 91, 0.25)" // 75% opacity for default
  },
  [CompanyColorEnum.ZAFFRE]: {
    base: "#00199F",
    highlight: "#4d5ebc",
    text: "#FFFFFF",
    button: "#00199F",
    "button-highlight": "rgba(0, 25, 159, 0.25)" // 75% opacity for zaffre
  },
  [CompanyColorEnum.TEAL_GREEN]: {
    base: "#00675B",
    highlight: "#4C8780",
    text: "#FFFFFF",
    button: "#00675B",
    "button-highlight": "rgba(0, 103, 91, 0.25)" // 75% opacity for teal green
  },
  [CompanyColorEnum.MAGENTA]: {
    base: "#890062",
    highlight: "#AC4C91",
    text: "#FFFFFF",
    button: "#890062",
    "button-highlight": "rgba(137, 0, 98, 0.25)" // 75% opacity for magenta
  },
  [CompanyColorEnum.RUSSET]: {
    base: "#A53C00",
    highlight: "#c0774d",
    text: "#FFFFFF",
    button: "#A53C00",
    "button-highlight": "rgba(165, 60, 0, 0.25)" // 75% opacity for russet
  },
  [CompanyColorEnum.RED_WINE]: {
    base: "#A1011E",
    highlight: "#bd4e62",
    text: "#FFFFFF",
    button: "#A1011E",
    "button-highlight": "rgba(161, 1, 30, 0.25)" // 75% opacity for red wine
  }
};
