<div class="company-onboarding__wrapper company-logo-colors">
  <div class="company-onboarding__form-content">
    <div class="company-onboarding__step-info">
      <p class="company-onboarding__step-info__name">Step 1: Set up logo and colours</p>
      <p class="company-onboarding__step-info__title">Company Configuration</p>
      <p class="company-onboarding__step-info__description">Here you can set up Company logo and colours.</p>
    </div>
    <div class="company-onboarding__form" [formGroup]="form">
      <div class="logo-upload">
        <p class="company-onboarding__form__title">Add Company Logo</p>

        <div class="logo-upload__button">
          <app-image-upload [size]="'big'" [formGroup]="form" [imagePreview]="imagePreview"></app-image-upload>
        </div>
      </div>
      <div class="color-picker">
        <p class="color-picker__title">Choose colour</p>

        <div class="color-picker__colors">
          <div
            class="color active"
            *ngFor="let color of colors"
            (click)="toggleActive(color)"
            [ngStyle]="{
              'background-color': color.base,
              outline: color.active ? '3px solid ' + color.base : ''
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
