<div class="auth-page login-page">
  <div class="auth-form">
    <div class="auth-form__header">
      <img src="assets/company-logo.svg" />
    </div>
    <form class="form-element" name="loginForm" [formGroup]="resetPassForm" novalidate (ngSubmit)="onSubmit()">
      <div class="form-text">
        <h2>Welcome to</h2>
        <h1>Culture Consultancy</h1>
        <p>Set new password.</p>
      </div>
      <div class="inputs-wrapper">
        <div class="form-item-container">
          <label for="email" class="form-label">Email</label>
          <div class="cc-input-container">
            <input
              type="email"
              name="email"
              id="email"
              class="cc-input disabled"
              readonly
              (ngModelChange)="onInputChange()"
              placeholder="Enter e-mail address"
              formControlName="email"
              required
            />
          </div>
        </div>
        <div class="form-item-container">
          <label for="password" class="form-label">
            Password
            <i
              class="fal fa-info-circle kiss-tooltip__icon kiss-tooltip__icon--sm"
              kissTooltip="Ensure it is at least 8 characters long and includes at least one uppercase letter (A-Z), one lowercase letter (a-z), one
            numeric digit (0-9), and one special character <br /> (e.g., !, &#64;, #, $, %, ^, &, *)."
              kissTooltipType="html"
              kissTooltipTimeout="100"
            ></i>
          </label>
          <div
            class="cc-input-container"
            [ngClass]="{ invalid: (!resetPassForm.get('newPassword').valid && resetPassForm.get('newPassword').touched) || error }"
          >
            <input
              [type]="showPass ? 'text' : 'password'"
              name="password"
              id="password"
              (ngModelChange)="onInputChange()"
              class="cc-input"
              placeholder="Enter new password"
              formControlName="newPassword"
              autocomplete="on"
              required
            />
            <div class="show-hide-pass" (click)="onTogglePassword()" *ngIf="resetPassForm.get('newPassword').value?.length > 0">
              <span class="eye">
                <i class="far" [ngClass]="showPass ? 'fa-eye-slash' : 'fa-eye'"></i>
              </span>
            </div>
          </div>
          <div class="error-placeholder">
            <div
              class="form-info-text error"
              *ngIf="!resetPassForm.get('newPassword').valid && resetPassForm.get('newPassword').touched && !error"
            >
              <span
                class="error"
                *ngIf="resetPassForm.get('newPassword').hasError('required') && !resetPassForm.get('newPassword').hasError('minLength')"
              >
                The password field is mandatory!
              </span>
              <span
                class="error"
                *ngIf="!resetPassForm.get('newPassword').hasError('required') && resetPassForm.get('newPassword').hasError('minlength')"
              >
                The password field must be at least 8 characters long!
              </span>
            </div>
          </div>
        </div>
        <div class="form-item-container">
          <label for="passwordConfirm" class="form-label">
            Confirm Password
            <i
              class="fal fa-info-circle kiss-tooltip__icon kiss-tooltip__icon--sm"
              kissTooltip="Ensure it is at least 8 characters long and includes at least one uppercase letter (A-Z), one lowercase letter (a-z), one
        numeric digit (0-9), and one special character <br /> (e.g., !, &#64;, #, $, %, ^, &, *)."
              kissTooltipType="html"
              kissTooltipTimeout="100"
            ></i>
          </label>
          <div
            class="cc-input-container"
            [ngClass]="{ invalid: (!resetPassForm.get('passwordConfirm').valid && resetPassForm.get('passwordConfirm').touched) || error }"
          >
            <input
              [type]="showConfirmPass ? 'text' : 'password'"
              name="passwordConfirm"
              id="passwordConfirm"
              (ngModelChange)="onInputChange()"
              class="cc-input"
              placeholder="Repeat new password"
              formControlName="passwordConfirm"
              autocomplete="on"
              required
            />
            <div class="show-hide-pass" (click)="onToggleConfirmPassword()" *ngIf="resetPassForm.get('passwordConfirm').value?.length > 0">
              <span class="eye">
                <i class="far" [ngClass]="showConfirmPass ? 'fa-eye-slash' : 'fa-eye'"></i>
              </span>
            </div>
          </div>
          <div class="error-placeholder">
            <div
              class="form-info-text error"
              *ngIf="!resetPassForm.get('passwordConfirm').valid && resetPassForm.get('passwordConfirm').touched && !error"
            >
              <span
                class="error"
                *ngIf="
                  resetPassForm.get('passwordConfirm').hasError('required') && !resetPassForm.get('passwordConfirm').hasError('minLength')
                "
              >
                The confirm password field is mandatory!
              </span>
              <span
                class="error"
                *ngIf="
                  !resetPassForm.get('passwordConfirm').hasError('required') && resetPassForm.get('passwordConfirm').hasError('minlength')
                "
              >
                The confirm password field must be at least 8 characters long!
              </span>
              <span
                class="error"
                *ngIf="
                  !resetPassForm.get('passwordConfirm').hasError('required') &&
                  resetPassForm.get('passwordConfirm').hasError('passwordMismatch')
                "
              >
                Passwords do not match. Please re-enter matching passwords.
              </span>
            </div>
            <div class="form-info-text error" *ngIf="error">
              <span class="error">{{ error }}</span>
            </div>
          </div>
        </div>
        <div class="action-btn-wrap">
          <button
            class="cc-btn cc-btn__primary"
            color="primary"
            aria-label="RESET PASSWORD"
            [disabled]="resetPassForm.invalid || requestPending"
          >
            Reset password
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="auth-image"></div>
</div>
