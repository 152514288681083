import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { CompanyService } from "./company.service";
import { CompanyModel } from "app/shared/models/company/company.model";
import { tap } from "rxjs";

@Injectable()
export class CompanyResolver implements Resolve<any> {
  constructor(private _companyService: CompanyService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const companyId = route?.params?.companyId || null;

    return this.getCompanyData({
      id: companyId
    });
  }

  getCompanyData({ id }) {
    return this._companyService
      .getActiveCompanyApi({
        id: id
      })
      .pipe(
        tap((company: CompanyModel) => {
          this._companyService.setActiveCompany(company);
        })
      );
  }
}
