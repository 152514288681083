import { HttpClient, HttpEventType, HttpRequest } from "@angular/common/http";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ControlContainer, FormBuilder, FormGroup } from "@angular/forms";
import { KissLoadingBarService } from "@kiss/components/common";
import { FileStateService } from "app/main/company-onboarding/company-onboarding-documents/files-state.service";
import { ApiConstants } from "app/shared/constants/api.constants";
import { MediaTypeEnum } from "app/shared/enums/media-type.enum";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-files-upload",
  templateUrl: "./files-upload.component.html",
  styleUrls: ["./files-upload.component.scss"]
})
export class FilesUploadComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Output() setFormValid = new EventEmitter<boolean>();

  typeEnums = MediaTypeEnum;
  form: FormGroup;
  isDragOver = false;
  files: Array<{ name: string; type: string; size: number; sizeReadable: string; preview: string | null }> = [];
  displayColumns = ["name", "size", "upload", "action"];
  dataSource: any[];

  isLoading = false;
  constructor(
    private controlContainer: ControlContainer,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private http: HttpClient,
    private loadingBar: KissLoadingBarService,
    private fileStateService: FileStateService
  ) {}

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
    this.files = this.fileStateService.getFiles();
    this.updateDataSource();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
    this.toggleDragOverClass(event, true);
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
    this.toggleDragOverClass(event, false);
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
    this.toggleDragOverClass(event, false);

    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.handleFiles(files);
      event.dataTransfer.clearData();
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files && files.length > 0) {
      this.handleFiles(files);
      input.value = "";
    }
  }
  private handleFiles(fileList: FileList): void {
    const fileArray = Array.from(fileList);
    const maxFileSize = 100 * 1024 * 1024; // 100 MB in bytes

    const validFiles = fileArray.filter((file) => {
      if (file.size > maxFileSize) {
        this.toastr.error(`${file.name} is too large. Maximum file size is 100 MB.`);
        return false;
      }
      return true;
    });

    if (validFiles.length > 0) {
      this.processFilesSequentially(validFiles);
    } else {
      this.setFormValid.emit(false); // Set form validity to false if no valid files are left
    }
  }

  private processFilesSequentially(files: File[], index: number = 0): void {
    if (index >= files.length) {
      this.setFormValid.emit(true);
      return;
    }

    const file = files[index];
    const fileData = {
      name: file.name,
      type: this.getFileExtension(file.name),
      size: file.size,
      sizeReadable: this.formatFileSize(file.size),
      preview: null,
      progress: 0,
      uploading: true
    };

    // Immediately add the file data to the files array
    this.files.push(fileData);
    this.updateDataSource();
    this.fileStateService.setFiles(this.files);

    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (typeof result === "string") {
        fileData.preview = result;
        this.uploadFile(file, fileData)
          .then(() => {
            // Once the file is uploaded, process the next file
            this.processFilesSequentially(files, index + 1);
          })
          .catch(() => {
            // If upload fails, move to the next file
            this.processFilesSequentially(files, index + 1);
          });
      }
    };

    reader.readAsDataURL(file);
  }

  removeFile(index: number): void {
    this.files.splice(index, 1);
    this.updateDataSource();
    this.fileStateService.setFiles(this.files);
    this.updateMediaControl(index);
  }

  private formatFileSize(size: number): string {
    if (size < 1024) return `${size} bytes`;
    if (size < 1048576) return `${(size / 1024).toFixed(1)} KB`;
    if (size < 1073741824) return `${(size / 1048576).toFixed(1)} MB`;
    return `${(size / 1073741824).toFixed(1)} GB`;
  }

  private getFileExtension(fileName: string): string {
    return fileName.split(".").pop() || "";
  }

  private uploadFile(
    file: File,
    fileData: {
      name: string;
      type: string;
      size: number;
      sizeReadable: string;
      preview: string | null;
      progress: number;
      uploading: boolean;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("files", file);

      fileData.uploading = true;

      this.loadingBar.show();
      this.isLoading = true;

      const req = new HttpRequest("POST", ApiConstants.API_MEDIA_UPLOAD, formData, {
        reportProgress: true
      });

      this.http.request(req).subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            fileData.progress = percentDone;
            this.updateDataSource();
          } else if (event.type === HttpEventType.Response) {
            this.loadingBar.hide();
            this.isLoading = false;
            fileData.uploading = false;

            const responseBody = event.body as any[];

            if (responseBody && responseBody.length > 0) {
              this.addUploadedFiles(responseBody, fileData);
              resolve();
            } else {
              this.toastr.error("Failed to upload file");
              reject(new Error("Upload failed"));
            }
          }
        },
        error: (err) => {
          this.loadingBar.hide();
          this.isLoading = false;
          fileData.uploading = false;
          this.toastr.error("Failed to upload file");
          reject(err);
        }
      });
    });
  }
  private updateDataSource(): void {
    this.dataSource = [...this.files];
  }

  private updateMediaControl(index: number): void {
    const mediaControl = this.form.get("media") ? this.form.get("media.mediaIds") : this.form.get("mediaIds");
    mediaControl?.value.splice(index, 1);
  }

  private addUploadedFiles(
    res: any[],
    fileData: { name: string; type: string; size: number; sizeReadable: string; preview: string | null }
  ): void {
    const mediaIdsControl = this.form.get("media") ? this.form.get("media.mediaIds") : this.form.get("mediaIds");
    const existingIds = mediaIdsControl?.value || [];
    const newIds = res.map((file) => file.id);

    mediaIdsControl?.setValue([...existingIds, ...newIds]);
  }

  private toggleDragOverClass(event: DragEvent, isDragOver: boolean): void {
    const target = event.currentTarget as HTMLElement;
    isDragOver ? target.classList.add("dragover") : target.classList.remove("dragover");
  }

  ngOnDestroy(): void {
    this.files = [];
    this.updateDataSource();
    this.fileStateService.setFiles(this.files);
  }
}
