import { gql } from "apollo-angular";
import { USER_FRAGMENT } from "./user.fragment";
import { GROUP_FRAGMENT } from "./group.fragment";

export const TEAM_FRAGMENT = gql`
  ${USER_FRAGMENT}
  ${GROUP_FRAGMENT}
  fragment TeamFragment on Team {
    id
    name
    companyId
    isActive
    createdAt
    updatedAt
    groupCount
    cultureLeads {
      user {
        ...UserFragment
      }
    }
    groups {
      ...GroupFragment
    }
  }
`;
