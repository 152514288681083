<button class="nav-expand-btn" [ngClass]="{ 'nav-expand-btn__expanded': settings?.expand }" (click)="toggleExpand()">
  <i class="fas fa-angle-left" *ngIf="settings?.expand"></i>
  <i class="fas fa-angle-right" *ngIf="!settings?.expand"></i>
</button>

<navigation-company [isAuto]="!settings?.expand"></navigation-company>

<!-- NAVIGATION CONTENT -->
<kiss-navigation [folded]="!settings?.expand"></kiss-navigation>
<!-- !NAVIGATION CONTENT -->

<navigation-user></navigation-user>
