<div class="auth-page login-page">
  <div class="auth-form">
    <div class="auth-form__header">
      <img src="assets/company-logo.svg" alt="Culture Consultancy Logo" />
    </div>
    <form class="form-element" name="loginForm" [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">
      <div class="form-text">
        <h2>Welcome to</h2>
        <h1>Culture Consultancy</h1>
        <p>To start please log in.</p>
      </div>
      <div class="inputs-wrapper">
        <div class="form-item-container">
          <label for="email" class="form-label">Email</label>
          <div
            class="cc-input-container"
            [ngClass]="{
              invalid:
                (loginForm.get('email').invalid && loginForm.get('email').touched && !loginForm.get('email').hasError('email')) || error
            }"
          >
            <input
              type="email"
              name="email"
              id="email"
              class="cc-input"
              (ngModelChange)="onInputChange()"
              placeholder="Enter e-mail address"
              formControlName="email"
              required
            />
          </div>
          <div class="error-placeholder">
            <div class="form-info-text error">
              <span
                class="error"
                *ngIf="
                  !loginForm.get('email').valid && loginForm.get('email').touched && !loginForm.get('email').hasError('email') && !error
                "
              >
                The email field is mandatory!
              </span>
              <span
                class="error"
                *ngIf="!loginForm.get('email').hasError('required') && loginForm.get('email').hasError('email') && !error"
              >
                Please enter a valid email address.
              </span>
            </div>
          </div>
        </div>
        <div class="form-item-container">
          <label for="password" class="form-label"> Password </label>
          <div
            class="cc-input-container"
            [ngClass]="{
              invalid:
                (loginForm.get('password').invalid &&
                  loginForm.get('password').touched &&
                  !loginForm.get('password').hasError('password')) ||
                error
            }"
          >
            <input
              #passInput
              [type]="isShown ? 'text' : 'password'"
              name="password"
              id="password"
              (ngModelChange)="onInputChange()"
              class="cc-input"
              placeholder="Enter password"
              formControlName="password"
              autocomplete="on"
              required
            />
            <div class="show-hide-pass" (click)="onTogglePassword()" *ngIf="loginForm.get('password').value?.length > 0">
              <span [hidden]="isShown" class="eye">
                <i class="far fa-eye"></i>
              </span>
              <span [hidden]="!isShown" class="eye">
                <i class="far fa-eye-slash"></i>
              </span>
            </div>
          </div>
          <div class="error-placeholder">
            <div class="form-info-text error" *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched && !error">
              <span
                class="error"
                *ngIf="loginForm.get('password').hasError('required') && !loginForm.get('password').hasError('minLength')"
              >
                The password field is mandatory!
              </span>
              <span
                class="error"
                *ngIf="!loginForm.get('password').hasError('required') && loginForm.get('password').hasError('minlength')"
              >
                The password field must be at least 10 characters long!
              </span>
            </div>
          </div>
        </div>
        <div class="action-btn-wrap">
          <button class="cc-btn cc-btn__primary" color="primary" aria-label="LOG IN" [disabled]="loginForm.invalid || requestPending">
            Log in
          </button>
        </div>
        <div class="cc-link">
          <a routerLink="/forgot-password">Forgotten password?</a>
        </div>
      </div>
    </form>
  </div>
  <div class="auth-image"></div>
</div>
