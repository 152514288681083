import { KissDatepickerCalendarSettings } from "../types/kiss-datepicker-calendar-settings";
import { KissDatepickerSelectionMode, KissDatepickerSelectionModeEnums } from "../types/kiss-datepicker-selection-mode";
import { KissDatepickerDay, KissDatepickerDayOptions } from "./kiss-datepicker-day";

export class KissDatepickerCalendar {
  numOfCells = 7;
  today: Date = new Date();
  dates: KissDatepickerDay[][] = [];
  enabledDates = [];
  minDate: Date;
  maxDate: Date;
  selected: Date | Date[];
  viewDate: Date;
  selectionMode: KissDatepickerSelectionMode;

  constructor({ enabledDates, selected, viewDate, selectionMode, minDate, maxDate }: KissDatepickerCalendarSettings) {
    this.enabledDates = enabledDates;
    this.selected = selected;
    this.viewDate = viewDate;
    this.selectionMode = selectionMode;
    this.minDate = minDate;
    this.maxDate = maxDate;

    this._init();
  }

  private _init() {
    this.dates = this._generateCalendar(this.viewDate);
  }

  private _generateCalendar(date: Date) {
    const table: KissDatepickerDay[][] = [];

    const y = date.getFullYear();
    const m = date.getMonth();

    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);

    //RECALCULATE START DATE
    const startDate = this._getStartDate(firstDay);
    const newStartPoint = new Date(y, m, startDate);

    //GENERATE THIS MONTH DATA
    for (let day = newStartPoint; day <= lastDay; day.setDate(day.getDate())) {
      const row: KissDatepickerDay[] = this._generateRow(date, day);
      table.push(row);
    }

    return table;
  }

  private _generateRow(date: Date, currentDay: Date) {
    const row: KissDatepickerDay[] = [];

    //GENERATE ROWS BY DATE
    for (let i = 0; i < this.numOfCells; i++) {
      const newDay = this._generateCell(date, currentDay);
      row.push(newDay);
      currentDay.setDate(currentDay.getDate() + 1);
    }

    return row;
  }

  private _generateCell(date: Date, currentDay: Date) {
    switch (this.selectionMode) {
      case KissDatepickerSelectionModeEnums.RANGE:
        return this._generateRangeCells(date, currentDay);
      default:
        return this._generateDatepickerCells(date, currentDay);
    }
  }

  private _generateDatepickerCells(date: Date, currentDay: Date): KissDatepickerDay {
    const selectedDate = this.selected as Date;

    const isCurrentDay = currentDay.toDateString() === this.today.toDateString();
    const isCurrentMonth = currentDay.getMonth() == date.getMonth();
    const isSelected = currentDay.toDateString() === selectedDate?.toDateString();
    const isDisabledDate = this._checkCellValidity(currentDay);

    const options: KissDatepickerDayOptions = {
      isCurrentDay,
      isCurrentMonth,
      isSelected,
      isDisabledDate
    };

    return new KissDatepickerDay(currentDay, options);
  }

  private _generateRangeCells(date: Date, currentDay: Date): KissDatepickerDay {
    const selectedDates = this.selected as Date[];

    const isCurrentDay = currentDay.toDateString() === this.today.toDateString();
    const isCurrentMonth = currentDay.getMonth() == date.getMonth();
    const isSelected = !!selectedDates?.some((item) => item?.toDateString() === currentDay.toDateString());

    const isStartRangeDate = selectedDates && currentDay.toDateString() === selectedDates[0]?.toDateString();
    const isEndRangeDate = selectedDates && currentDay.toDateString() === selectedDates[1]?.toDateString();

    const isDisabledDate = this._checkCellValidity(currentDay);

    const isBetweenRange =
      selectedDates && currentDay.getTime() > selectedDates[0]?.getTime() && currentDay.getTime() < selectedDates[1]?.getTime();

    const isBetweenRangeHover = false;

    const options: KissDatepickerDayOptions = {
      isCurrentDay,
      isCurrentMonth,
      isSelected,
      isStartRangeDate,
      isEndRangeDate,
      isBetweenRange,
      isDisabledDate,
      isBetweenRangeHover
    };

    return new KissDatepickerDay(currentDay, options);
  }

  private _getStartDate(date: Date) {
    const startDayOfWeek = date.getDay();

    let startDate = date.getDate() - startDayOfWeek + 1;
    if (startDate > 1) {
      startDate = startDate - 7;
    }

    return startDate;
  }

  private _checkCellValidity(currentDay: Date) {
    const currentDateStr = currentDay.toDateString();
    const includesEnabledDate = this.enabledDates?.length && !this.enabledDates.includes(currentDateStr);

    const isLessThanMinDate = this.minDate && currentDay.getTime() <= this.minDate.getTime();
    const isMoreThanMaxDate = this.maxDate && currentDay.getTime() >= this.maxDate.getTime();

    return includesEnabledDate || isLessThanMinDate || isMoreThanMaxDate;
  }
}
