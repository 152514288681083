<!-- HEADER -->
<div #header class="kiss-select-container" (click)="onSelectClick()">
  <div class="kiss-select__icon kiss-select__pre-icon" *ngIf="preIconDirective">
    <ng-template [ngTemplateOutlet]="preIconDirective.templateRef" [ngTemplateOutletContext]="{ open, disabled, multiple }"></ng-template>
  </div>

  <div class="kiss-select__label-container">
    <div class="kiss-select__placeholder" *ngIf="placeholder && !selected?.length">
      {{ placeholder }}
    </div>
    <!-- LABEL -->
    <span class="kiss-select__label" *ngIf="label && !enableHeaderTemplate">
      {{ label }}
    </span>
    <!-- !LABEL -->

    <div class="kiss-select__header-template" *ngIf="enableHeaderTemplate">
      <div class="kiss-select__header-template__item" *ngFor="let item of selected; let i = index">
        <ng-template [ngTemplateOutlet]="item.headerTemplate?.templateRef"></ng-template>
        <span class="kiss-select__header-template__divider" *ngIf="i != selected.length - 1"> , </span>
      </div>
    </div>
  </div>
  <div class="kiss-select__icon kiss-select__post-icon">
    <ng-container *ngIf="!postIconDirective && !open">
      <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.5 7.125C6.25391 7.125 6.03516 7.04297 5.87109 6.87891L0.621094 1.62891C0.265625 1.30078 0.265625 0.726562 0.621094 0.398438C0.949219 0.0429688 1.52344 0.0429688 1.85156 0.398438L6.5 5.01953L11.1211 0.398438C11.4492 0.0429688 12.0234 0.0429688 12.3516 0.398438C12.707 0.726562 12.707 1.30078 12.3516 1.62891L7.10156 6.87891C6.9375 7.04297 6.71875 7.125 6.5 7.125Z"
          fill="currentColor"
        />
      </svg>
    </ng-container>

    <ng-container *ngIf="!postIconDirective && open">
      <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.75 7.375C11.5039 7.375 11.2852 7.29297 11.1211 7.12891L6.5 2.50781L1.85156 7.12891C1.52344 7.48438 0.949219 7.48438 0.621094 7.12891C0.265625 6.80078 0.265625 6.22656 0.621094 5.89844L5.87109 0.648438C6.19922 0.292969 6.77344 0.292969 7.10156 0.648438L12.3516 5.89844C12.707 6.22656 12.707 6.80078 12.3516 7.12891C12.1875 7.29297 11.9687 7.375 11.75 7.375Z"
          fill="currentColor"
        />
      </svg>
    </ng-container>

    <ng-template
      *ngIf="postIconDirective"
      [ngTemplateOutlet]="postIconDirective.templateRef"
      [ngTemplateOutletContext]="{ open, disabled, multiple }"
    ></ng-template>
  </div>
</div>
<!-- !HEADER -->

<!-- DROPDOWN -->
<ng-template kissOverlay [kissOverlayOpen]="open" (onContainerClick)="onContainerClick()">
  <div
    class="kiss-select__dropdown-container"
    kissSetPosition
    [kissSetPositionParent]="header"
    [handleOverflow]="handleOverflow"
    [inheritWidth]="inheritWidth"
    [ngClass]="[dropdownClass || '']"
    #container
  >
    <div class="kiss-select__dropdown" tabindex="0">
      <!-- DROPDOWN HEADER -->
      <div class="kiss-select__dropdown-header" *ngIf="filter || multiple">
        <!-- SEARCH -->
        <kiss-search #search *ngIf="filter" tabindex="0" (onInput)="onSearch($event)"></kiss-search>
        <!-- !SEARCH -->

        <kiss-checkbox
          *ngIf="multiple && toggleAll"
          tabindex="0"
          (onValueChange)="onToggleSelectAll($event)"
          class="kiss-select__option__checkbox"
          >Select All</kiss-checkbox
        >
      </div>

      <!-- !DROPDOWN HEADER -->

      <!-- OPTIONS -->
      <div class="kiss-select__option-container" #optionContainerRef>
        <ng-content></ng-content>
      </div>
      <!-- OPTIONS -->

      <!-- EMPTY -->
      <div class="kiss-select__empty-container" *ngIf="showEmptySelectText && !options?.length">
        <div class="kiss-select__empty-text">
          {{ emptySelectText || "No data found" }}
        </div>
      </div>
      <!-- EMPTY -->
    </div>
    <div class="kiss-select__tab-trap" tabindex="0" #tabTrap></div>
  </div>
</ng-template>
<!-- !DROPDOWN -->
