import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChooseCompanyComponent } from "./choose-company.component";
import { RouterModule, Routes } from "@angular/router";
import { RoleEnum } from "app/shared/enums/role.enum";
import { AuthGuard } from "app/shared/guards/auth.guard";
import { RoleGuard } from "app/shared/guards/role.guard";
import { ChooseCompanyService } from "./choose-company.service";
import { CompanyImageComponent } from "app/shared/components/company-image/company-image.component";
import { CompanyGuard } from "app/shared/guards/company.guard";

const routes: Routes = [
  {
    path: "choose-company",
    component: ChooseCompanyComponent,
    canMatch: [AuthGuard],
    canActivate: [RoleGuard, CompanyGuard],
    resolve: {
      data: ChooseCompanyService
    },
    data: {
      allowedRoles: [RoleEnum.CONSULTANT_ADMIN],
      layoutConfig: {
        layoutType: "vertical-classic",
        verticalClassicConfig: {
          navigationSidebar: {
            enabled: false
          },
          toolbar: {
            enabled: false
          }
        }
      }
    }
  }
];

@NgModule({
  declarations: [ChooseCompanyComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CompanyImageComponent],
  providers: [ChooseCompanyService]
})
export class ChooseCompanyModule {}
