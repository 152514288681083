import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { KissLoadingBarService } from "@kiss/components/common";
import { TOASTR_AUTH_CONFIG } from "app/shared/constants/custom-toast-config";

import { AuthService } from "app/shared/services/auth/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html"
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassForm: FormGroup;
  error: string;
  requestPending = false;
  requestSubmitted = false;
  requestSubmitMsg = "";
  queryParams: Params;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private _loadingBar: KissLoadingBarService
  ) {}

  ngOnInit(): void {
    this.initialise();
  }

  initialise() {
    this.queryParams = Object.assign({}, this.activatedRoute.snapshot.queryParams);
    this.forgotPassForm = new FormGroup({
      email: new FormControl(this.queryParams.email, [Validators.required, Validators.email])
    });
  }

  onInputChange() {
    this.error = null;
  }

  onSubmit() {
    if (!this.forgotPassForm.valid) return;
    this.requestPending = true;
    this.error = null;
    const { email } = this.forgotPassForm.value;
    this._loadingBar.show();
    this.authService
      .forgotPassword(email)
      .subscribe({
        next: (res: any) => {
          this.requestPending = false;
          if (res.data.forgotPassword.status == "success") {
            this.forgotPassForm.reset();
            this.requestSubmitMsg = res.message;
            this.requestSubmitted = true;
            this.router.navigate(["/login"]);
            this.toastr.success(res.data.forgotPassword.message, undefined, TOASTR_AUTH_CONFIG);
          }
        },
        error: (err) => {
          this.requestPending = false;
          this.error = err.message;
        }
      })
      .add(() => {
        this._loadingBar.hide();
      });
  }
}
