<ng-container *ngIf="!item.hidden">
  <div class="nav-seperator" *ngIf="item.seperator"></div>

  <!-- normal  -->
  <a class="nav-list" [ngClass]="item.classes" *ngIf="!item.url && !item.function" (click)="toggleOpen($event)">
    <ng-container *ngTemplateOutlet="listContent"></ng-container>
  </a>

  <!-- item.url -->
  <a
    class="nav-list"
    [ngClass]="item.classes"
    *ngIf="item.url && !item.externalUrl && !item.function"
    (click)="toggleOpen($event)"
    [routerLink]="[item.url]"
    [routerLinkActive]="item.hideActive ? [] : ['active-link']"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    [queryParams]="item.params"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="listContent"></ng-container>
  </a>

  <!-- item.externalUrl -->
  <a
    class="nav-list"
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl && !item.function"
    (click)="toggleOpen($event)"
    [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="listContent"></ng-container>
  </a>

  <!-- item.url && item.function -->
  <a
    class="nav-list"
    [ngClass]="item.classes"
    *ngIf="item.url && !item.externalUrl && item.function"
    (click)="toggleOpen($event); item.function()"
    [routerLink]="[item.url]"
    [routerLinkActive]="item.hideActive ? [] : ['active-link']"
    [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    [queryParams]="item.params"
  >
    <ng-container *ngTemplateOutlet="listContent"></ng-container>
  </a>

  <!-- item.externalUrl && item.function -->
  <a
    class="nav-list"
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl && item.function"
    (click)="toggleOpen($event); item.function()"
    [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="listContent"></ng-container>
  </a>

  <!-- item.function -->
  <span class="nav-list" [ngClass]="item.classes" *ngIf="!item.url && item.function" (click)="toggleOpen($event); item.function()">
    <ng-container *ngTemplateOutlet="listContent"></ng-container>
  </span>

  <!-- LIST -->
  <ng-template #listContent>
    <!-- ICON -->

    <div *ngIf="item.icon" class="nav-icon" [ngClass]="[item.icon?.iconClass || '']">
      <img *ngIf="item.icon?.imgSrc" [ngClass]="{ 'active-icon': !isActive }" [src]="item.icon?.imgSrc" />

      <img *ngIf="item.icon?.imgSrcActive" [ngClass]="{ 'active-icon': isActive }" [src]="item.icon?.imgSrcActive" />

      <i *ngIf="item.icon?.fontAwesomeClass" [class]="item.icon?.fontAwesomeClass"></i>

      <div *ngIf="item.icon?.svg" class="nav-icon__svg" [innerHTML]="item.icon.svg | safeHtml"></div>
    </div>
    <!-- !ICON -->

    <span class="nav-title" *ngIf="item?.title">
      {{ item?.title }}
    </span>

    <div class="nav-list-icon">
      <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.1875 1.49219L6.96484 6.49609C6.80078 6.63281 6.63672 6.6875 6.5 6.6875C6.33594 6.6875 6.17188 6.63281 6.03516 6.52344L0.785156 1.49219C0.511719 1.24609 0.511719 0.808594 0.757812 0.5625C1.00391 0.289062 1.44141 0.289062 1.6875 0.535156L6.5 5.12891L11.2852 0.535156C11.5312 0.289062 11.9688 0.289062 12.2148 0.5625C12.4609 0.808594 12.4609 1.24609 12.1875 1.49219Z"
          fill="currentColor"
        />
      </svg>

      <!-- <svg
        width="13"
        height="8"
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        *ngIf="isOpen"
      >
        <path
          d="M0.8125 6.00781L6.03516 1.00391C6.19922 0.867187 6.36328 0.812499 6.5 0.812499C6.66406 0.812499 6.82813 0.867187 6.96484 0.976562L12.2148 6.00781C12.4883 6.25391 12.4883 6.69141 12.2422 6.9375C11.9961 7.21094 11.5586 7.21094 11.3125 6.96484L6.5 2.37109L1.71484 6.96484C1.46875 7.21094 1.03125 7.21094 0.785157 6.9375C0.539063 6.69141 0.539063 6.25391 0.8125 6.00781Z"
          fill="currentColor"
        />
      </svg> -->
    </div>
  </ng-template>
  <!-- !LIST -->

  <div class="nav-children" *ngIf="item?.children?.length && isOpen" @expandCollapse>
    <ng-container *ngFor="let child of item?.children">
      <!-- NESTED LISTS -->
      <kiss-navigation-list class="kiss-navigation-list-child" *ngIf="child?.type === 'list'" [item]="child"></kiss-navigation-list>
      <!-- NESTED LISTS -->

      <!-- NESTED GROUPS -->
      <kiss-navigation-group class="kiss-navigation-list-child" *ngIf="child?.type === 'group'" [item]="child"></kiss-navigation-group>
      <!-- !NESTED GROUPS -->

      <!-- NESTED ITEMS -->
      <kiss-navigation-item class="kiss-navigation-list-child" *ngIf="child?.type === 'item'" [item]="child"></kiss-navigation-item>
      <!-- !NESTED ITEMS -->
    </ng-container>
  </div>
</ng-container>
