import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { KissLoadingBarService } from "@kiss/components/common";
import { RoutesConstants } from "app/shared/constants/routes.constrants";
import { AuthService } from "app/shared/services/auth/auth.service";
import { Subject, takeUntil } from "rxjs";
import { RoleService } from "app/shared/services/auth/role.service";
import { PlatformLocation } from "@angular/common";
import { CompanyService } from "app/shared/services/company/company.service";
import { ApiConstants } from "app/shared/constants/api.constants";

@Component({
  selector: "app-choose-company",
  templateUrl: "./choose-company.component.html",
  styleUrl: "./choose-company.component.scss"
})
export class ChooseCompanyComponent implements OnInit {
  companies: any;
  user: any;
  private _destroy: Subject<void> = new Subject();
  photoUrl = ApiConstants.API_MEDIA_PREVIEW;
  isAnyOnboarding = false;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _auth: AuthService,
    private _loadingBar: KissLoadingBarService,
    private _roleService: RoleService,
    private location: PlatformLocation,
    private _companyService: CompanyService
  ) {
    this._router.events.pipe(takeUntil(this._destroy)).subscribe((data) => {
      this.companies = this._route.snapshot.data.data;
      if (this.companies.some((company: any) => !company.company.isOnboardingFinished)) {
        this.isAnyOnboarding = true;
      }

      this.user = JSON.parse(localStorage.getItem("user_data"));
    });
  }
  ngOnInit(): void {
    history.pushState(null, null, location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  onLogout() {
    this._loadingBar.show();
    this._auth
      .logout()
      .subscribe(() => {
        this._router.navigate([RoutesConstants.BASE_AUTH_REDIRECT]);
      })
      .add(() => {
        this._loadingBar.hide();
      });
  }

  navigateToCompany(company: any) {
    if (company.isOnboardingFinished) {
      this._companyService.setActiveCompany(company);
      const baseRoute = this._roleService.getBaseUrl();
      this._router.navigate([baseRoute]);
    } else {
      this._router.navigate([`/onboarding/${company.id}`]);
    }
  }
}
