const DEFAULT_SETTINGS = {
  enabled: true,
};

export class KissDatepickerMonthSettings {
  enabled: boolean;
  constructor(data: any) {
    data = data || {};

    this.enabled = data.enabled ?? DEFAULT_SETTINGS.enabled;
  }
}
