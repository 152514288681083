import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';

import { KissDialogConfig } from './helpers/kiss-dialog-config';
import { KissDialogRef } from './helpers/kiss-dialog-ref';
import { KissDialogInjector } from './helpers/kiss-dialog.injector';
import { KissDialogComponent } from './kiss-dialog.component';

@Injectable()
export class KissDialogService {
  dialogRefMap: Map<KissDialogRef, ComponentRef<KissDialogComponent>> = new Map();

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _appRef: ApplicationRef
  ) {}

  /**
   * Creates a dialog with the passed `componentType` and `config` and returns the reference
   * @param componentType
   * @param config
   * @returns {KissDialogRef} KissDialogRef
   */
  public open(componentType: Type<any>, config: KissDialogConfig = {}): KissDialogRef {
    const dialogRef = this._insertDialogComponentIntoBody(config);

    this.dialogRefMap.get(dialogRef).instance.childComponentType = componentType;

    return dialogRef;
  }

  /**
   * Insert the dialog parent inside DOM which will then be passed to `kiss-overlay`
   * @param config
   * @returns
   */
  private _insertDialogComponentIntoBody(config: KissDialogConfig) {
    const map = new WeakMap();
    map.set(KissDialogConfig, config);

    const dialogRef = new KissDialogRef();
    map.set(KissDialogRef, dialogRef);

    this._listenForEvents(dialogRef);

    const componentFactory =
      this._componentFactoryResolver.resolveComponentFactory(KissDialogComponent);
    const componentRef = componentFactory.create(new KissDialogInjector(this._injector, map));

    this._appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogRefMap.set(dialogRef, componentRef);

    return dialogRef;
  }

  /**
   * Remove dialog and it's parent from DOM
   * @param dialogRef
   * @returns
   */
  private _removeDialogComponentFromBody(dialogRef: KissDialogRef) {
    if (!dialogRef || !this.dialogRefMap.has(dialogRef)) {
      return;
    }

    const dialogComponentRef = this.dialogRefMap.get(dialogRef);
    dialogComponentRef.destroy();
    this.dialogRefMap.delete(dialogRef);
  }

  /**
   * Listen for dialog reference events
   * @param dialogRef
   */
  private _listenForEvents(dialogRef) {
    const sub = dialogRef.onClose.subscribe(() => {
      this.dialogRefMap.get(dialogRef).instance.close();
    });

    const destroySub = dialogRef.onDestroy.subscribe(() => {
      this._removeDialogComponentFromBody(dialogRef);
      destroySub.unsubscribe();
      sub.unsubscribe();
    });
  }
}
