import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CompanyModel } from "app/shared/models/company/company.model";
import { filter, Subject, takeUntil } from "rxjs";
import { CompanyOnboardingService } from "./company-onboarding.service";
import { CompanyTeamFormService } from "app/shared/components/company-team-form/company-team-form.service";
import { CompanyService } from "app/shared/services/company/company.service";
import { CanComponentDeactivate } from "./can-deactivate.guard";
import { RoleService } from "app/shared/services/auth/role.service";

enum StepEnums {
  LOGO_AND_COLORS = "logo-and-colors",
  COMPANY_VALUES = "company-values",
  COMPANY_GOALS = "company-goals",
  KICK_OFF = "kick-off",
  SUPPORTING_DOCUMENTS = "supporting-documents",
  TEAMS = "teams",
  COMPLETE = "complete"
}

@Component({
  selector: "app-company-onboarding",
  templateUrl: "./company-onboarding.component.html",
  styleUrl: "./company-onboarding.component.scss"
})
export class CompanyOnboardingComponent implements OnInit {
  dataSource: CompanyModel;
  stepEnums = StepEnums;
  steps = [
    { name: "Logo & colors", id: StepEnums.LOGO_AND_COLORS, skipped: false },
    { name: "Company Values", id: StepEnums.COMPANY_VALUES, skipped: false },
    { name: "Company strategic goals", id: StepEnums.COMPANY_GOALS, skipped: false },
    { name: "Kick - off Sessions", id: StepEnums.KICK_OFF, skipped: false },
    // HIDDEN AND DISABLED
    // { name: "Adding Supporting documents", id: StepEnums.SUPPORTING_DOCUMENTS, skipped: false },
    { name: "Teams Configuration", id: StepEnums.TEAMS, skipped: false }
  ];

  form: FormGroup;
  activeStep: any;
  isLoading = false;
  private _destroy: Subject<void> = new Subject();

  constructor(
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _companyOnboardingService: CompanyOnboardingService,
    private _companyTeamFormService: CompanyTeamFormService,
    private _companyService: CompanyService,
    private _roleService: RoleService
  ) {
    this.router.events
      .pipe(
        takeUntil(this._destroy),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.dataSource = this.route.snapshot.data.data;
        this.activeStep = this.steps[0].id;
      });
  }

  ngOnInit(): void {
    this.form = this.createForm(this.dataSource);
  }

  canDeactivate(): boolean {
    if (this.form.dirty) {
      return confirm("You have unsaved changes. Are you sure you want to leave?");
    }
    return true;
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any) {
    if (this.form.dirty) {
      $event.returnValue = true; // Display the confirmation dialog
    }
  }

  createForm(data?: any) {
    return this._fb.group({
      //logo-colors
      logoId: [data?.logoId, [Validators.required]],
      companyColors: [data.companyColor, [Validators.required]],
      //company-values
      companyValues: this._fb.array(this.populateCompanyValues(data?.companyValues || [])),
      companyStrategies: this._fb.array(this.populateCompanyStrategies(data?.strategicGoals || [])),
      //kick-off-docs,
      kickOffVisibleToCultureLead: [this.dataSource?.kickOffVisibleToCultureLead.toString() || null, [Validators.required]],
      media: this._fb.group({
        type: [""],
        name: [""],
        description: [],
        mediaIds: [[]]
      }),
      teams: this._fb.array([])
    });
  }

  onNext() {
    this._goToNextStep();
  }

  onSkip() {
    this._goToNextStep({
      stepSkipped: true
    });
  }

  onPrev() {
    this._goToPreviousStep();
  }

  private _goToNextStep(settings?: { stepSkipped?: boolean }) {
    const step = this.steps.find((step) => step.id === this.activeStep);

    if (settings?.stepSkipped) {
      step.skipped = settings.stepSkipped;
    }

    const index = this.steps.indexOf(step);
    const nextStep = index + 1;
    const isLastStep = nextStep === this.steps.length;

    if (isLastStep) {
      this.onLastStep();
    } else {
      this.activeStep = this.steps[nextStep].id;
    }
  }

  private _goToPreviousStep() {
    const step = this.steps.find((step) => step.id === this.activeStep);
    const index = this.steps.indexOf(step);
    const prevStep = index - 1;

    this.activeStep = this.steps[prevStep].id;
  }

  onStepClick(step: number) {
    this.activeStep = step;
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onLastStep() {
    this.onSave();
  }

  onSave() {
    const postModel = this.form.getRawValue();
    let kickOffVisibleToCultureLead = postModel.kickOffVisibleToCultureLead ? postModel.kickOffVisibleToCultureLead === "true" : false;

    const postBody = {
      media: this._formatMedia(postModel),
      logoId: postModel.logoId || null,
      companyColor: postModel.companyColors || null,
      strategicGoals: postModel.companyStrategies || null,
      companyValues: postModel.companyValues || null,
      kickOffVisibleToCultureLead: kickOffVisibleToCultureLead,
      isOnboardingFinished: true,
      teams: this._formatTeam(postModel.teams) || []
    };

    this.isLoading = true;
    this._companyOnboardingService
      .updateCompany(postBody)
      .then((response: any) => {
        this._companyService.setActiveCompany(response.data.updateCompany);
        this.activeStep = this.stepEnums.COMPLETE;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private _formatMedia(data: any) {
    if (data.media?.type === "files") {
      return {
        files: {
          mediaIds: data.media?.mediaIds || []
        }
      };
    }

    return {
      folder: {
        description: data.media?.description || "",
        mediaIds: data.media?.mediaIds || [],
        title: data.media?.name || ""
      }
    };
  }

  private _formatTeam(data: any): any {
    return data.map((team: any) => {
      return this._companyTeamFormService.formatTeamResponse(team);
    });
  }

  populateCompanyValues(values: any[]): FormGroup[] {
    return values.map((value) =>
      this._fb.group({
        name: [value.name || "", [Validators.required]],
        description: [value.description || ""]
      })
    );
  }

  populateCompanyStrategies(strategies: any[]): FormGroup[] {
    return strategies.map((strategy) =>
      this._fb.group({
        name: [strategy.name || "", [Validators.required]],
        description: [strategy.description || ""]
      })
    );
  }

  goToDashboard() {
    const baseRoute = this._roleService.getBaseUrl();
    this.router.navigate([baseRoute]);
  }
}
