import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Apollo } from "apollo-angular";
import { map, Observable } from "rxjs";
import { GET_MY_COMPANIES } from "app/shared/gql/operations/choose-company/choose-company.operations";

@Injectable()
export class ChooseCompanyService {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private apollo: Apollo) {}

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.getMyCompanies();
  }

  getMyCompanies() {
    return this.apollo
      .query({
        query: GET_MY_COMPANIES
      })
      .pipe(map((result: any) => result.data.myCompanies));
  }
}
