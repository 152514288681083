import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiConstants } from "app/shared/constants/api.constants";
import { RoleEnum } from "app/shared/enums/role.enum";
import { UserModel } from "app/shared/models/user/user.model";
import { RoleService } from "app/shared/services/auth/role.service";
import { UserDataService } from "app/shared/services/auth/user-data.service";
import { CompanyDataService } from "app/shared/services/company/company-data.service";
import { CompanyService } from "app/shared/services/company/company.service";
import { Subscription } from "rxjs";

@Component({
  selector: "navigation-company",
  templateUrl: "./navigation-company.component.html"
})
export class NavigationCompanyComponent implements OnInit, OnDestroy {
  @Input() isAuto: boolean = false;
  activeCompany: any;
  user: UserModel;
  companies: any = [];
  premissions = {
    isAdmin: [RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN],
    multipleCompanies: [RoleEnum.CONSULTANT_ADMIN],
    singleCompany: [RoleEnum.COMPANY_ADMIN, RoleEnum.CULTURE_LEAD]
  };
  photoUrl = ApiConstants.API_MEDIA_PREVIEW;
  isLoading = false;
  activeCompanyLogo;
  companyLogo;

  private _subscription: Subscription;
  constructor(
    private _companyService: CompanyService,
    private _companyDataService: CompanyDataService,
    public role: RoleService,
    private _user: UserDataService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.activeCompany = this._companyService.getActiveCompany();

    if (this.activeCompany) {
      this._setLogo(this.activeCompany);
    }

    this._subscription = this._companyService.onCompanyChange().subscribe((company) => {
      this.activeCompany = company;
      this._setLogo(this.activeCompany);
    });

    this._user.userData$.subscribe((response) => {
      this.user = response;
      this.companies = this._companyDataService.getSavedCompanies();
      this.activeCompany = this._companyService.getActiveCompany();
      this.activeCompanyLogo = this.activeCompany?.logo ? this.photoUrl + "/" + this.activeCompany?.logo?.accessToken : "";
    });
  }

  private _setLogo(company) {
    if (company?.logo?.accessToken) {
      this.activeCompanyLogo = this.photoUrl + "/" + company?.logo?.accessToken;
    } else {
      this.activeCompanyLogo = undefined;
    }
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  onActiveCompanyChange(event: any, company: any, trigger: any): void {
    event.preventDefault();
    event.stopPropagation();
    trigger.click();

    if (company?.id === this.activeCompany?.id) return;

    // set active
    this._companyService.setActiveCompany(company);

    //navigate
    const baseRoute = this.role.getBaseUrl();

    console.log(baseRoute, this._router.url);

    this._router.navigate([baseRoute]);

    // refresh existing data
    this._companyService.triggerForceRefresh();
  }
}
