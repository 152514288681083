<ng-template #iconUp>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32">
    <path
      d="M15.114 11.057c0.521-0.521 1.365-0.521 1.886 0l8 8c0.521 0.521 0.521 1.365 0 1.886s-1.365 0.521-1.886 0l-7.057-7.057-7.057 7.057c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l8-8z"
      fill="currentColor"
    ></path>
  </svg>
</ng-template>

<ng-template #iconDown>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32">
    <path
      d="M7.114 11.057c0.521-0.521 1.365-0.521 1.886 0l7.057 7.057 7.057-7.057c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-8 8c-0.521 0.521-1.365 0.521-1.886 0l-8-8c-0.521-0.521-0.521-1.365 0-1.886z"
      fill="currentColor"
    ></path>
  </svg>
</ng-template>

<div class="kiss-datepicker__time__container" [formGroup]="$any(form)">
  <div class="kiss-datepicker__time__container__item" *ngFor="let group of form['controls']; let i = index">
    <!-- HOURS -->
    <div *ngIf="timeSettings.showHours" class="kiss-datepicker__time__item kiss-datepicker__time__hours">
      <button class="kiss-datepicker__btn kiss-datepicker__time__item--up" (click)="next(config.HOURS.id, i)">
        <ng-template *ngTemplateOutlet="iconUp"></ng-template>
      </button>
      <input
        type="text"
        maxlength="2"
        class="kiss-datepicker__time__item__input"
        [formControl]="$any(group).get(config.HOURS.id)"
        (input)="updateTimestamp()"
      />
      <button class="kiss-datepicker__btn kiss-datepicker__time__item--down" (click)="prev(config.HOURS.id, i)">
        <ng-template *ngTemplateOutlet="iconDown"></ng-template>
      </button>
    </div>
    <!-- !HOURS -->

    <span class="kiss-datepicker__time--inbetween" *ngIf="timeSettings.showMinutes">:</span>

    <!-- MINUTES -->
    <div *ngIf="timeSettings.showMinutes" class="kiss-datepicker__time__item kiss-datepicker__time__minutes">
      <button class="kiss-datepicker__btn kiss-datepicker__time__item--up" (click)="next(config.MINUTES.id, i)">
        <ng-template *ngTemplateOutlet="iconUp"></ng-template>
      </button>
      <input
        type="text"
        maxlength="2"
        class="kiss-datepicker__time__item__input"
        [formControl]="$any(group).get(config.MINUTES.id)"
        (input)="updateTimestamp()"
      />
      <button class="kiss-datepicker__btn kiss-datepicker__time__item--down" (click)="prev(config.MINUTES.id, i)">
        <ng-template *ngTemplateOutlet="iconDown"></ng-template>
      </button>
    </div>
    <!-- !MINUTES -->

    <span class="kiss-datepicker__time--inbetween" *ngIf="timeSettings.showSeconds">:</span>

    <!-- SECONDS -->
    <div *ngIf="timeSettings.showSeconds" class="kiss-datepicker__time__item kiss-datepicker__time__seconds">
      <button class="kiss-datepicker__btn kiss-datepicker__time__item--up" (click)="next(config.SECONDS.id, i)">
        <ng-template *ngTemplateOutlet="iconUp"></ng-template>
      </button>
      <input
        type="text"
        maxlength="2"
        class="kiss-datepicker__time__item__input"
        [formControl]="$any(group).get(config.SECONDS.id)"
        (input)="updateTimestamp()"
      />
      <button class="kiss-datepicker__btn kiss-datepicker__time__item--down" (click)="prev(config.SECONDS.id, i)">
        <ng-template *ngTemplateOutlet="iconDown"></ng-template>
      </button>
    </div>
    <!-- !SECONDS -->

    <span class="kiss-datepicker__time--inbetween" *ngIf="timeSettings.showMilliseconds">:</span>

    <!-- MILLISECONDS -->
    <div *ngIf="timeSettings.showMilliseconds" class="kiss-datepicker__time__item kiss-datepicker__time__milliseconds">
      <button class="kiss-datepicker__btn kiss-datepicker__time__item--up" (click)="next(config.MILLISECONDS.id, i)">
        <ng-template *ngTemplateOutlet="iconUp"></ng-template>
      </button>
      <input
        type="text"
        maxlength="3"
        class="kiss-datepicker__time__item__input"
        [formControl]="$any(group).get(config.MILLISECONDS.id)"
        (input)="updateTimestamp()"
      />
      <button class="kiss-datepicker__btn kiss-datepicker__time__item--down" (click)="prev(config.MILLISECONDS.id, i)">
        <ng-template *ngTemplateOutlet="iconDown"></ng-template>
      </button>
    </div>
    <!-- !MILLISECONDS -->
  </div>
</div>

<!-- SAVE -->
<!-- <button
  class="kiss-datepicker__btn"
  (click)="onSave($event)"
  [disabled]="form.invalid || form.pristine"
>
  SAVE
</button> -->
<!-- !SAVE -->
