import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => KissSearchComponent),
  multi: true,
};

@Component({
  selector: 'kiss-search',
  templateUrl: './kiss-search.component.html',
  host: {
    class: 'kiss-search',
    '(focus)': 'onFocus()',
  },
  providers: [VALUE_ACCESSOR],
})
export class KissSearchComponent implements ControlValueAccessor {
  // -----------------------------------------------------------------------------------------------------
  // @ BUILT IN
  // -----------------------------------------------------------------------------------------------------

  /**
   * Holds the current value of the control
   */
  @Input() value: string;

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {};

  /**
   * Invoked when the model is disabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._renderer.setProperty(this._elRef.nativeElement, 'disabled', isDisabled);
  }

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.onChange(this.value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: any): void {
    this.value = value;
    this.updateChanges();
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ CUSTOM
  // -----------------------------------------------------------------------------------------------------

  @ViewChild('input') input: ElementRef;

  /**
   * Adds a class and controls if input is disabled
   */
  @Input() @HostBinding('class.kiss-disabled') disabled: boolean = false;

  /**
   * Readonly value
   */
  @Input() readonly;

  /**
   * Input placeholder
   */
  @Input() placeholder = '';

  /**
   * Emits value on change event
   */
  @Output() onValueChange: EventEmitter<{ originalEvent: Event; value: string }> =
    new EventEmitter();

  /**
   * Emits a value on input event
   */
  @Output() onInput: EventEmitter<{ originalEvent: Event; value: string }> = new EventEmitter();

  constructor(private _renderer: Renderer2, private _elRef: ElementRef) {}

  valueInput(event) {
    this.onInput.emit({
      originalEvent: event,
      value: this.value,
    });
  }

  valueChange(event) {
    this.onValueChange.emit({
      originalEvent: event,
      value: this.value,
    });
  }

  onClose(event) {
    event.stopPropagation();
    this.value = '';
    this.onValueChange.emit({
      originalEvent: null,
      value: this.value,
    });
    this.onInput.emit({
      originalEvent: null,
      value: this.value,
    });
  }

  onFocus() {
    if (this.input) {
      this.input.nativeElement.focus();
    }
  }
}
