import { gql } from "apollo-angular";
import { LOGO_FRAGMENT } from "./logo.fragment";
export const COMPANY_FRAGMENT = gql`
  ${LOGO_FRAGMENT}
  fragment CompanyFragment on Company {
    id
    name
    companyColor
    kickOffVisibleToCultureLead
    isOnboardingFinished
    groupCount
    isActive
    isDeleted
    createdAt
    logoId
    logo {
      ...LogoFragment
    }
    docsContainer {
      id
    }
  }
`;
