import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { kissAnimations } from "@kiss/animations";
import { fromEvent, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { KissOverlayRef } from "../kiss-overlay/kiss-overlay-ref";
import { KissOverlayService } from "../kiss-overlay/kiss-overlay.service";
import { KissMenuToggle } from "./types/kiss-menu-toggle-event.type";

/**
 * USAGE
 *
 * `#menu='kissMenu'` returns `KissMenuComponent` reference that has `closeMenu()` and `openMenu()` methods on it
 *
 * @example
 *
 * <button #menuTrigger></button>
 *
 * <kiss-menu [menuTrigger]="menuTrigger" #menu="kissMenu">
 *    <button kiss-menu-item>
 *    </button>
 *</kiss-menu>
 *
 */
@Component({
  selector: "kiss-menu",
  exportAs: "kissMenu",
  templateUrl: "./kiss-menu.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: kissAnimations
})
export class KissMenuComponent implements OnInit, OnDestroy {
  @Input() @HostBinding("class.kiss-menu-open") open: boolean;

  @ViewChild("menu") menu: TemplateRef<any>;
  @ViewChild("content") content: ElementRef;

  /**
   * @param {ElementRef} `ElementRef` - element that triggers the menu on click
   */
  @Input() menuTrigger: HTMLElement;

  /**
   * menu x position
   * @param {('auto' | 'left' | 'right')}
   */
  @Input() xPosition: "auto" | "left" | "right";

  /**
   * menu y position
   * @param {('auto' | 'top' | 'bottom')}
   */
  @Input() yPosition: "auto" | "top" | "bottom";

  /**
   * Enables/Disables checking if the menu overflows the screen
   *
   * By default it is `true`
   * @param {boolean}
   */
  @Input() handleOverflow: boolean = true;

  /**
   * Set a custom class on the menu-container
   */
  @Input() contentClass: string;

  @Output() onMenuToggle = new EventEmitter<KissMenuToggle>();

  private _rendererTriggerClick: any;

  //close
  private _overlayRef: KissOverlayRef;
  private _overlaySub: Subscription;
  private _destroy: Subject<void>;
  private _keydownSub: Subscription;

  constructor(
    private _renderer: Renderer2,
    private _cdr: ChangeDetectorRef,
    private _kissOverlayService: KissOverlayService
  ) {
    this._destroy = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._setupTrigger(this.menuTrigger);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    if (this._rendererTriggerClick) this._rendererTriggerClick();

    this._toggleClosed();
    this._destroy.next();
    this._destroy.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setup initial values and listen for trigger click
   * @param trigger
   * @returns void
   */
  private _setupTrigger(trigger: HTMLElement): void {
    if (!trigger) return;
    this._renderer.addClass(trigger, "kiss-menu-trigger");

    this._rendererTriggerClick = this._renderer.listen(trigger, "click", (event) => {
      this.open = !this.open;
      this.open ? this.openMenu(event) : this.closeMenu(event);
    });
  }

  /**
   * Open menu
   *
   * Does not fire change detection

   */
  private _toggleOpen(event?: any): void {
    if (!this.menuTrigger) return;
    this.open = true;
    this._renderer.addClass(this.menuTrigger, "kiss-menu-trigger-open");
    this._setFocusOnChild();

    const eventData = {
      isOpen: this.open,
      menuTrigger: this.menuTrigger,
      originalEvent: event
    };

    this.onMenuToggle.next(eventData);
  }

  /**
   * Listens for keydown event
   */
  private _listenForCloseEvents(): void {
    if (!this.open && this._keydownSub) return;

    this._keydownSub = fromEvent(document, "keydown")
      .pipe(takeUntil(this._destroy))
      .subscribe((event: KeyboardEvent) => {
        const content = this.content?.nativeElement;
        const contains = content && (content === document.activeElement || content.contains(document.activeElement));
        const tabTrap = content?.lastChild;
        const trigger: any = this.menuTrigger;
        const containsTrigger = trigger && trigger.contains(event.target);

        if (event.key === "Escape" && (contains || containsTrigger)) {
          this.closeMenu(event);
          return;
        }

        if (event.key === "Tab" && !contains) {
          this.closeMenu(event);
          return;
        }

        if (tabTrap === document.activeElement) {
          /* Check for focus and refocus on menu trigger */
          this.closeMenu(event);

          if (trigger) trigger.focus();
          return;
        }
      });
  }

  /**
   * Close menu
   *
   * Does not fire change detection
   */
  private _toggleClosed(event?: any): void {
    this.open = false;

    if (this.menuTrigger) {
      this._renderer.removeClass(this.menuTrigger, "kiss-menu-trigger-open");
    }

    if (this._keydownSub) this._keydownSub.unsubscribe();

    this._removeOverlay();

    const eventData = {
      isOpen: this.open,
      menuTrigger: this.menuTrigger,
      originalEvent: event
    };

    this.onMenuToggle.next(eventData);
  }

  /**
   * remove the overlay
   */
  private _removeOverlay(): void {
    if (this._overlaySub) this._overlaySub.unsubscribe();
    if (this._overlayRef) this._kissOverlayService.removeOverlay(this._overlayRef);
  }

  private _setFocusOnChild() {
    setTimeout(() => {
      // this will make the execution after the above boolean has changed
      const child = this.content?.nativeElement?.firstChild;

      if (child?.focus) {
        child.focus();
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Close the menu and trigger change detection
   */
  public closeMenu = (event?: any) => {
    this._toggleClosed(event);

    this._cdr.markForCheck();
  };

  /**
   * Open menu, listen for close events and trigger change detection
   */
  public openMenu = (event?: any) => {
    this._toggleOpen(event);
    this._overlayRef = this._kissOverlayService.createOverlay(this.menu);

    this._overlaySub = this._overlayRef.onContainerClick.pipe(takeUntil(this._destroy)).subscribe((data) => {
      this._toggleClosed(event);
    });

    this._listenForCloseEvents();

    this._cdr.markForCheck();
  };
}
