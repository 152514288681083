import { gql } from "apollo-angular";

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    name
    role
    id
    email
    createdAt
    isSponsored
  }
`;
