<div class="company-onboarding-sidebar">
  <p class="company-onboarding-sidebar__title">Quick & Easy Setup</p>
  <ul class="company-onboarding-sidebar__steps">
    @for (step of steps; track step) {
      <li
        class="company-onboarding-sidebar__steps-step"
        [ngClass]="{
          active: step.id === activeStep
        }"
        (click)="onNavigateToSection(step)"
      >
        {{ step.name }}
      </li>
    }
  </ul>
</div>
