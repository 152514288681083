import { gql } from "apollo-angular";

export const LOGO_FRAGMENT = gql`
  fragment LogoFragment on Media {
    id
    fileName
    fileType
    fileSize
    imgHeight
    imgWidth
    createdAt
    updatedAt
    caption
    relativePath
    accessToken
  }
`;
