<div class="promt-dialog-inner" [ngClass]="[data?.promptType || '']">
  <div class="promt-dialog-inner__icon">
    <i class="fas fa-exclamation"></i>
  </div>
  <div class="promt-dialog-inner__title" *ngIf="data?.title">
    {{ data.title }}
  </div>
  <div class="promt-dialog-inner__title" *ngIf="data?.titleHtml" [innerHTML]="data.titleHtml"></div>
  <div class="promt-dialog-inner__message" [innerHTML]="data.message"></div>
  <div class="promt-dialog-inner__actions">
    <button (click)="onReject()" class="cc-btn cc-btn--lg cc-btn__stroked">
      {{ data.rejectBtn }}
    </button>
    <button
      (click)="onConfirm()"
      class="cc-btn cc-btn--lg"
      [ngClass]="data?.promptType === 'delete-dialog' ? 'cc-btn__error' : 'cc-btn__primary'"
    >
      {{ data.confirmBtn }}
    </button>
  </div>
</div>
