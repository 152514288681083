import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { KissToolbar } from "./types/kiss-toolbar-config.types";
import { DOCUMENT } from "@angular/common";
import { AuthService } from "app/shared/services/auth/auth.service";
import { KissDialogRef, KissLoadingBarService } from "@kiss/components/common";
import { RoutesConstants } from "app/shared/constants/routes.constrants";
import { UserDataService } from "app/shared/services/auth/user-data.service";
@Component({
  selector: "kiss-toolbar",
  templateUrl: "./kiss-toolbar.component.html",
  host: {
    class: "kiss-toolbar"
  }
})
export class KissToolbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() settings: KissToolbar;
  switch: boolean = false;
  user: any;
  notificationCopy: any;

  private _unsubscribeAll: Subject<void>;

  private dialogRef: KissDialogRef;

  constructor(
    private _router: Router,
    private _renderer: Renderer2,
    private _elRef: ElementRef,
    private _auth: AuthService,
    private _user: UserDataService,
    private _loadingBar: KissLoadingBarService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /*
   * On Init
   */

  ngOnInit(): void {
    this._user.userData$.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      this.user = data;
    });
  }

  ngAfterViewInit(): void {
    this._setToolbarHeightVar();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onLogout() {
    this._loadingBar.show();
    this._auth
      .logout()
      .subscribe(() => {
        this._router.navigate([RoutesConstants.BASE_AUTH_REDIRECT]);
      })
      .add(() => {
        this._loadingBar.hide();
      });
  }

  onEditUser() {
    this._router.navigate(["/users/profile"]);
  }

  stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }

  private _setToolbarHeightVar() {
    if (!this._elRef?.nativeElement?.offsetHeight || !this._document?.documentElement) return;

    const height = this._elRef.nativeElement.offsetHeight + "px";

    this._renderer.setProperty(this._document.documentElement, "style", `--kiss-toolbar-height:${height}`);
  }
}
