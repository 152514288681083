import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ControlContainer, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { KissLoadingBarService } from "@kiss/components/common";
import { FileStateService } from "./files-state.service";

@Component({
  selector: "app-company-onboarding-documents",
  templateUrl: "./company-onboarding-documents.component.html",
  styleUrls: ["./company-onboarding-documents.component.scss"]
})
export class CompanyOnboardingDocumentsComponent implements OnInit {
  form: FormGroup;
  isDragOver = false;
  files: { name: string; type: string; size: number; sizeReadable: string; preview: string | null }[] = [];
  @Output() onSkip = new EventEmitter();
  @Output() onNext = new EventEmitter();
  @Output() onPrev = new EventEmitter();

  displayColumns = ["fileType", "name", "size", "action"];
  dataSource;

  charCount: number = 0;

  constructor(
    private controlContainer: ControlContainer,
    private _fb: FormBuilder,
    private _toastr: ToastrService,
    private http: HttpClient,
    private loadingBar: KissLoadingBarService,
    private fileStateService: FileStateService
  ) {}

  ngOnInit(): void {
    this.form = <FormGroup>this.controlContainer.control;
  }

  resetForm() {
    this.files = [];

    // Update the service with the new state
    this.fileStateService.setFiles(this.files);

    if (this.form.get("media.type")?.value === "folder") {
      this.form.get("media.name").setValidators(Validators.required);
    }
  }

  updateCharCount() {
    const descriptionControl = this.form.get("media.description");
    this.charCount = descriptionControl ? descriptionControl.value.length : 0;
  }

  onClickSkip() {
    this.onSkip.next(true);
  }

  onClickNext() {
    // Save the current state of files to the service before proceeding
    this.fileStateService.setFiles(this.files);
    this.onNext.next(true);
  }

  onClickPrev() {
    this.onPrev.next(true);
  }
}
