export enum UserStatusEnum {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  IN_ACTIVE = "IN_ACTIVE"
}

export const UserStatusEnumValues: {
  [key in UserStatusEnum]: string;
} = {
  [UserStatusEnum.ACTIVE]: "Active",
  [UserStatusEnum.IN_ACTIVE]: "Inactive",
  [UserStatusEnum.PENDING]: "Pending"
};
