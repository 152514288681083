export type KissDatepickerDayOptions = {
  isCurrentDay: boolean;
  isCurrentMonth: boolean;
  isSelected: boolean;
  isDisabledDate: boolean;
  isBetweenRange?: boolean;
  isStartRangeDate?: boolean;
  isEndRangeDate?: boolean;
  isBetweenRangeHover?: boolean;
};

export class KissDatepickerDay {
  date: Date;
  value: number;
  isCurrentDay: boolean;
  isCurrentMonth: boolean;
  isSelected: boolean;
  isDisabledDate: boolean;
  isBetweenRange?: boolean;
  isStartRangeDate?: boolean;
  isEndRangeDate?: boolean;
  isBetweenRangeHover?: boolean;

  constructor(day: Date, options: KissDatepickerDayOptions) {
    this.date = new Date(day.getTime());
    this.value = this.date.getDate();
    this.isCurrentDay = !!options?.isCurrentDay;
    this.isCurrentMonth = !!options?.isCurrentMonth;
    this.isSelected = !!options?.isSelected;
    this.isDisabledDate = !!options?.isDisabledDate;
    this.isBetweenRange = !!options?.isBetweenRange;
    this.isStartRangeDate = !!options?.isStartRangeDate;
    this.isEndRangeDate = !!options?.isEndRangeDate;
    this.isBetweenRangeHover = !!options?.isBetweenRangeHover;
  }
}
