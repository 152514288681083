export interface KissNavigationItem {
  type: "item" | "group" | "list";
  navigateOnOpen?: string;
  title?: string;
  translation?: string;
  url?: string;
  children?: KissNavigationItem[];
  exactMatch?: boolean;
  excludeUrls?: string[];
  classes?: string;
  seperator?: boolean;
  hidden?: boolean;
  id?: any;
  function?: () => void;
  externalUrl?: boolean;
  openInNewTab?: "_blank" | "_self";
  params?: any;
  hideActive?: boolean;
  allowedRoles?: any[];
  activeStateAvoid?: string[];
  icon?: {
    fontAwesomeClass?: string | string[];
    imgSrc?: string;
    imgSrcActive?: string;
    svg?: string;
    iconClass?: string;
  };
}
