import { ChangeDetectionStrategy, Component, ElementRef } from "@angular/core";

@Component({
  selector: "kiss-table-body",
  template: "<ng-content></ng-content>",
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: "kiss-table-body",
    role: "table-body"
  }
})
export class KissTableBodyComponent {
  constructor(public elementRef: ElementRef) {}
}
