import { NgModule } from "@angular/core";
import { KissDialogModule } from "@kiss/components/common";

import { PromptDialogService } from "./prompt-dialog.service";
import { CommonModule } from "@angular/common";
import { PromptDialogComponent } from "./prompt-dialog.component";

@NgModule({
  declarations: [PromptDialogComponent],
  imports: [CommonModule, KissDialogModule],
  providers: [PromptDialogService]
})
export class PromptDialogModule {}
