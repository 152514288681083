import { Directive, ElementRef, Renderer2 } from "@angular/core";
import { KissTableDefaults } from "../helpers/kiss-table-defaults";
import { KissColumnDefDirective } from "../kiss-column/kiss-column.directive";

@Directive()
export class BaseKissCellComponent {
  set className(value: string) {
    this._className = value;
    this._addColumnClass(this._className);
  }

  private _className: string = "";
  get className() {
    return this._className;
  }

  set resizable(value: boolean) {
    this._resizable = value;

    this._toggleResizableClass(this._resizable);
  }

  private _resizable: boolean = false;
  get resizable() {
    return this._resizable;
  }
  constructor(protected column: KissColumnDefDirective, protected elementRef: ElementRef, protected _renderer: Renderer2) {
    this.className = column.className;
    this.resizable = column.resizable;
  }

  private _addColumnClass(name: string) {
    const col = KissTableDefaults.styling.colName(name);
    this._renderer.addClass(this.elementRef.nativeElement, col);
    this._renderer.setAttribute(this.elementRef.nativeElement, KissTableDefaults.styling.colAttr, name);
  }

  private _toggleResizableClass(resizable: boolean) {
    const col = KissTableDefaults.styling.resizable;
    if (resizable) {
      this._renderer.addClass(this.elementRef.nativeElement, col);
    } else {
      this._renderer.removeClass(this.elementRef.nativeElement, col);
    }
  }
}
