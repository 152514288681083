import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, ControlContainer, FormBuilder, FormArray, Validators } from "@angular/forms";

@Component({
  selector: "app-company-onboarding-strategies",
  templateUrl: "./company-onboarding-strategies.component.html",
  styleUrl: "./company-onboarding-strategies.component.scss"
})
export class CompanyOnboardingStrategiesComponent implements OnInit {
  form: FormGroup;
  @Output() onSkip = new EventEmitter();
  @Output() onNext = new EventEmitter();
  @Output() onPrev = new EventEmitter();
  @Input() company: any;
  constructor(
    private controlContainer: ControlContainer,
    private _fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = <FormGroup>this.controlContainer.control;
  }

  addCompanyStrategy() {
    const companyStrategies = this.form.get("companyStrategies") as FormArray;
    const newCompanyStrategy = this._fb.group({
      name: [null, Validators.required],
      description: [null]
    });
    companyStrategies.push(newCompanyStrategy);
  }

  removeCompanyStrategy(index: number) {
    const companystrategies = this.form.get("companyStrategies") as FormArray;

    companystrategies.removeAt(index);
  }

  onClickSkip() {
    this.onSkip.next(true);
  }

  onClickNext() {
    this.onNext.next(true);
  }

  onClickPrev() {
    this.onPrev.next(true);
  }
}
