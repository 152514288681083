import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { StorageConstants } from "app/shared/constants/storage.constants";
import { GET_ME } from "app/shared/gql/operations/profile/profile-settings.operations";
import { UserModel } from "app/shared/models/user/user.model";
import { BehaviorSubject, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class UserDataService {
  userData = new BehaviorSubject<UserModel>(null);
  userData$ = this.userData.asObservable();

  constructor(private apollo: Apollo) {}

  getUserData(): UserModel {
    const value = localStorage.getItem(StorageConstants.STORAGE_USER_DATA);
    return value ? JSON.parse(value) : undefined;
  }

  setUserData(user: UserModel): void {
    localStorage.setItem(StorageConstants.STORAGE_USER_DATA, JSON.stringify(user));
    this.userData.next(user);
  }

  clearUser(): void {
    localStorage.removeItem(StorageConstants.STORAGE_USER_DATA);
    this.userData.next(null);
  }

  getMe() {
    return this.apollo
      .query({
        query: GET_ME
      })
      .pipe(map((result: any) => result.data.getMe));
  }
}
