import { Component, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { KissLoadingBarService } from "@kiss/components/common";
import { RoutesConstants } from "app/shared/constants/routes.constrants";
import { RoleEnum } from "app/shared/enums/role.enum";
import { Settings } from "app/shared/models/settings/settings";
import { AuthService } from "app/shared/services/auth/auth.service";
import { RoleService } from "app/shared/services/auth/role.service";
import { UserDataService } from "app/shared/services/auth/user-data.service";
import { CompanyDataService } from "app/shared/services/company/company-data.service";
import { CompanyService } from "app/shared/services/company/company.service";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html"
})
export class LoginComponent implements OnDestroy {
  loginForm: FormGroup;
  error: string;
  isShown = false;
  requestPending = false;
  settings: Settings;

  private _unsubscribeAll: Subject<void>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private _toastr: ToastrService,
    private _loadingBar: KissLoadingBarService,
    private _companyDataService: CompanyDataService,
    private _userService: UserDataService,
    private _roleService: RoleService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.initialise();
  }

  initialise() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(10)])
    });
  }

  //Remove renderer
  onTogglePassword() {
    this.isShown = !this.isShown;
  }

  onInputChange() {
    this.error = null;
  }

  onSubmit() {
    if (!this.loginForm.valid) return;
    this.requestPending = true;
    this.error = null;
    this._loadingBar.show();
    const { email, password } = this.loginForm.value;
    const userCredentials = { email, password };

    this.authService
      .login(userCredentials)
      .subscribe({
        next: () => {
          this.requestPending = true;
          this._companyDataService
            .getCompaniesAfterLogin()
            .subscribe(() => {
              this._onSuccessfullLogin();
            })
            .add(() => {
              this.requestPending = false;
            });
        },
        error: (err) => {
          this.error = err;
        }
      })
      .add(() => {
        this._loadingBar.hide();
        this.requestPending = false;
      });
  }

  private _onSuccessfullLogin() {
    const userData = this._userService.getUserData();
    this._toastr.success(`Welcome, ${userData.name}`);
    const baseRoute = this._roleService.getBaseUrlOnLogin();
    this.router.navigate([baseRoute]);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
