import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { KissModule } from "@kiss";
import { LOCALE_ID } from "@angular/core";
import { KissSvgIconModule } from "@kiss/components/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "environments/environment";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { TokenInterceptor } from "./shared/interceptors/token.interceptor";
import { ToastrModule } from "ngx-toastr";
import { ErrorInterceptor } from "./shared/interceptors/error.interceptor";
import { UnAuthGuard } from "./shared/guards/unauth.guard";
import { AuthGuard } from "./shared/guards/auth.guard";
import { RoleGuard } from "./shared/guards/role.guard";
import { CustomToastModule } from "./shared/components/custom-toast/custom-toast.module";
import { DatePipe } from "@angular/common";
import { GraphQLModule } from "./graphql.module";
import { DEFAULT_TOASTR_CONFIG } from "./shared/constants/custom-toast-config";
import { CompanyGuard } from "./shared/guards/company.guard";
import { CompanyResolver } from "./shared/services/company/company.resolver";
import { initializeApp } from "./shared/initializer/app-initializer";
import { AuthService } from "./shared/services/auth/auth.service";
import { CompanyService } from "./shared/services/company/company.service";
import { CompanyDataService } from "./shared/services/company/company-data.service";
import { StorageControlService } from "./shared/services/storage/storage-control.service";
import { InitialLoadHandlerModule } from "./shared/components/initial-load-handler/initial-load-handler.module";


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KissModule,
    BrowserAnimationsModule,
    CustomToastModule,
    KissSvgIconModule.forRoot({
      spriteUrl: environment.sprite_url
    }),
    ToastrModule.forRoot(DEFAULT_TOASTR_CONFIG),
    GraphQLModule,
    InitialLoadHandlerModule
  ],
  providers: [
    AuthGuard,
    UnAuthGuard,
    DatePipe,
    RoleGuard,
    CompanyGuard,
    DatePipe,
    CompanyResolver,
    CompanyService,
    provideHttpClient(withInterceptorsFromDi()),
    { provide: LOCALE_ID, useValue: "en-GB" },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [StorageControlService, AuthService, CompanyDataService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
