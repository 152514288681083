import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InitialLoadHandlerComponent } from "./initial-load-handler.component";
import { InitialLoadHandlerService } from "./initial-load-handler.service";

@NgModule({
  declarations: [InitialLoadHandlerComponent],
  imports: [CommonModule],
  exports: [InitialLoadHandlerComponent],
  providers: [InitialLoadHandlerService]
})
export class InitialLoadHandlerModule {}
