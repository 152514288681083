import { Directive, InjectionToken, TemplateRef } from '@angular/core';

export const KISS_OPTION_HEADER_TEMPLATE_DIRECTIVE_TOKEN =
  new InjectionToken<KissOptionHeaderTemplateDirective>(
    'kiss-option-header-template-directive-token'
  );

@Directive({
  selector: '[kissOptionHeaderTemplate]',
  providers: [
    {
      provide: KISS_OPTION_HEADER_TEMPLATE_DIRECTIVE_TOKEN,
      useExisting: KissOptionHeaderTemplateDirective,
    },
  ],
})
export class KissOptionHeaderTemplateDirective {
  constructor(
    public templateRef: TemplateRef<KissOptionHeaderTemplateDirective>
  ) {}
}
