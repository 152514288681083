<!-- image-upload.component.html -->
<div class="image-upload" [ngClass]="[size || '']">
  <label class="image-upload__button">
    <input type="file" (change)="onFileSelected($event)" accept="image/png, image/jpeg, image/jpg"/>
    <div class="icon">
      <i class="fa-light fa-image"></i>
    </div>

    <div class="cc-btn cc-btn__icon cc-btn__hover__color--secondary edit-icon">
      <i class="fa-light fa-pen-to-square"></i>
    </div>

    <app-company-image [companyName]="data?.name" class="image" [image]="imagePreview" *ngIf="imagePreview"></app-company-image>
  </label>
</div>
