import { Injectable, OnDestroy } from "@angular/core";
import { KissDialogService } from "@kiss/components/common";
import { KissDialogRef } from "@kiss/components/common/kiss-dialog/helpers/kiss-dialog-ref";
import { PromptDialogComponent } from "./prompt-dialog.component";

@Injectable()
export class PromptDialogService implements OnDestroy {
  confirmationDialogRef: KissDialogRef;

  constructor(private _kissDialogService: KissDialogService) {}

  open(config: {
    message: string;
    title?: string;
    titleHtml?: string;
    rejectBtn: string;
    confirmBtn: string;
    dialogClass?: string;
    promptType?: string;
  }): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.confirmationDialogRef = this._kissDialogService.open(PromptDialogComponent, {
        data: {
          config,
          onAction: (value: boolean) => {
            resolve(value);
          }
        },
        dialogClass: config?.dialogClass ? config?.dialogClass : "confirmation-dialog",
        exitOnEsc: true,
        exitOnClickOutside: true
      });
    });
  }

  ngOnDestroy(): void {
    if (this.confirmationDialogRef) this.confirmationDialogRef.destroy();
    this.confirmationDialogRef = null;
  }
}
