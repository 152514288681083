import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { CompanyColorService } from "./company-color.service";
import { CompanyService } from "../company/company.service";
import { UserDataService } from "../auth/user-data.service";
import { RoleEnum } from "app/shared/enums/role.enum";
import { RoleService } from "../auth/role.service";

@Injectable({
  providedIn: "root"
})
export class CompanyColorHandlerService {
  private _renderer: Renderer2;
  private _defaultColorClass = "use-default-color";
  private _companyColorClass = "use-company-color";
  private _destroy: Subject<void>;
  constructor(
    private _companyColorService: CompanyColorService,
    private _companyService: CompanyService,
    private _userDataService: UserDataService,
    private _roleService: RoleService,
    private _rendererFactory: RendererFactory2
  ) {
    this._renderer = this._rendererFactory.createRenderer(null, null);
    this._destroy = new Subject();
  }

  init() {
    this._userDataService.userData$.pipe(takeUntil(this._destroy)).subscribe(() => {
      const isAdmin = this._roleService.isAuthorized([RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN]);

      if (isAdmin) {
        this._renderer.addClass(document.documentElement, this._defaultColorClass);
        this._renderer.removeClass(document.documentElement, this._companyColorClass);
      } else {
        this._renderer.removeClass(document.documentElement, this._defaultColorClass);
        this._renderer.addClass(document.documentElement, this._companyColorClass);
      }
    });

    this._companyService
      .onCompanyChange()
      .pipe(takeUntil(this._destroy))
      .subscribe((company) => {
        if (company) {
          const color = company?.companyColor || null;
          this._companyColorService.setColor(color);
        } else {
          this._companyColorService.resetColor();
        }
      });
  }

  destroy() {
    this._destroy.next();
    this._destroy.complete();
  }
}
