import { ElementRef, NgZone, Renderer2 } from '@angular/core';
import { KissTableDefaults } from './kiss-table-defaults';
import { KissTableSettings } from './kiss-table-settings';

/**
 * Should be moved to a directive
 * same as sort
 */
export class KissTableResizeHandler {
  private _settings: KissTableSettings;

  set settings(settings: KissTableSettings) {
    this._settings = settings;
  }

  get settings() {
    return this._settings;
  }

  private _resizedCells = {};
  private _resizeListeners: Map<string, any> = new Map();
  constructor(private _renderer: Renderer2, private _elRef: ElementRef, private _ngZone: NgZone) {}

  listenForResize() {
    this._ngZone.runOutsideAngular(() => {
      this._getResizeSaveData();
      this._listenForResizeEvents();
    });
  }

  destroyListeners() {
    this._resizeListeners.forEach((listenerFn, key) => {
      listenerFn();
    });

    this._resizeListeners.clear();
    this._resizedCells = null;
  }

  /**
   * Sets up listeners for resizable cells
   * @returns
   */
  private _listenForResizeEvents(): void {
    const handleName = KissTableDefaults.styling.resizeHandle;
    const headerQuery = `.kiss-header-cell.${KissTableDefaults.styling.resizable}`;
    const headerNodes = this._elRef.nativeElement.querySelectorAll(headerQuery);
    if (!headerNodes?.length) return;

    let isClicked = false;
    let header: HTMLElement;

    /**
     * On mousedown we check if the clicked element is the resize handle.
     * From that element we get the parent header cell element and confirm it has been clicked.
     */

    const mousedownListener = this._renderer.listen(
      this._elRef.nativeElement,
      'mousedown',
      (event) => {
        if (!event.target.classList.contains(handleName)) return;
        header = event.target.parentElement;
        this._renderer.addClass(header, KissTableDefaults.styling.showResizeHandle);
        isClicked = true;
      }
    );

    /**
     * Disable the click and remove the header element
     */
    const mouseleaveListener = this._renderer.listen(
      this._elRef.nativeElement,
      'mouseleave',
      () => {
        if (!isClicked) return;
        isClicked = false;
        this._renderer.removeClass(header, KissTableDefaults.styling.showResizeHandle);
        header = undefined;
      }
    );

    /**
     * Disable the click and remove the header element
     */
    const mouseupListener = this._renderer.listen(this._elRef.nativeElement, 'mouseup', () => {
      if (!isClicked) return;
      isClicked = false;
      this._renderer.removeClass(header, KissTableDefaults.styling.showResizeHandle);
      header = undefined;
    });

    /**
     * While pressed we calculate the new cell sizes
     */
    const mousemoveListener = this._renderer.listen(
      this._elRef.nativeElement,
      'mousemove',
      (event) => {
        if (!isClicked) return;
        this._calcNewCellSize(header, event);
      }
    );

    this._resizeListeners.set('mousedown', mousedownListener);
    this._resizeListeners.set('mouseleave', mouseleaveListener);
    this._resizeListeners.set('mouseup', mouseupListener);
    this._resizeListeners.set('mousemove', mousemoveListener);
  }

  /**
   * Used to update all resized cells when content has been changed
   */
  private _updateCellSizes() {
    for (const name in this._resizedCells) {
      this._setCellSize(name);
    }
  }

  /**
   *  If the setting value `saveResize`  has been enabled we check if it exists inside localStorage and update the cells
   * @returns
   */
  private _getResizeSaveData(): void {
    if (
      !this.settings?.saveResize ||
      !this.settings?.name ||
      !localStorage.getItem(this.settings?.name)
    ) {
      return;
    }

    const data = JSON.parse(localStorage.getItem(this.settings.name));
    this._resizedCells = data;
    this._updateCellSizes();
  }

  /**
   * Save the table data if the `saveResize` and `name` settings options exist
   * @returns
   */
  private _setResizeSaveData() {
    if (!this.settings?.saveResize || !this.settings?.name) return;
    const data = JSON.stringify(this._resizedCells);
    localStorage.setItem(this.settings.name, data);
  }

  /**
   * Calculate new cell sizes and save it inside the component by using the column name
   * @param header
   * @param event
   * @returns
   */
  private _calcNewCellSize(header: HTMLElement, event: any) {
    const left = header.getBoundingClientRect().left;
    const newWidth = Math.round(event.clientX - left);
    if (newWidth < 40) return;

    const name = header.dataset.columnName;
    this._resizedCells[name] = newWidth;
    this._setResizeSaveData();

    this._setCellSize(name);
  }

  /**
   * Set cell sizes for every cell with the matching column name.
   * @param name
   * @returns
   */
  private _setCellSize(name: string) {
    const width = this._resizedCells[name];
    if (!width) return;

    const cells = this._getCellsByColName(name);
    if (!cells?.length) return;
    cells.forEach((cell) => {
      this._renderer.setStyle(cell, 'min-width', width + 'px');
    });
  }

  /**
   * Get all cells (header and standard) by column name.
   * @param name
   * @returns
   */
  private _getCellsByColName(name: string) {
    return this._elRef.nativeElement.querySelectorAll(
      `[${KissTableDefaults.styling.colAttr}='${name}']`
    );
  }
}
