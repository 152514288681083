import { Injectable } from "@angular/core";
import { KissLoadingBarService, KissNavigationItem, KissNavigationService } from "@kiss/components/common";
import { AuthService } from "./auth/auth.service";
import { UserModel } from "../models/user/user.model";
import { getMainNavigation } from "../../navigation/navigation";
import { RoutesConstants } from "../constants/routes.constrants";
import { Router } from "@angular/router";
import { RoleService } from "./auth/role.service";
import { UserDataService } from "./auth/user-data.service";
import { CompanyModel } from "../models/company/company.model";
import { CompanyService } from "./company/company.service";

@Injectable({ providedIn: "root" })
export class MainNavigationService {
  user: UserModel;
  activeCompany: CompanyModel;
  navigation: KissNavigationItem[] = [];
  isFirstTime: boolean = true;

  constructor(
    private _navigationService: KissNavigationService,
    private _auth: AuthService,
    private _user: UserDataService,
    private _roleService: RoleService,
    private _loadingBar: KissLoadingBarService,
    private _router: Router,
    private _companyService: CompanyService
  ) {}

  init() {
    this._user.userData$.subscribe((response) => {
      this.user = response;
      this._setNavigation();
    });

    this._companyService.onCompanyChange().subscribe((response) => {
      this.activeCompany = response;
      this._setNavigation();
    });
  }

  private _setNavigation() {
    const navigation = getMainNavigation({
      triggerLogout: this.triggerLogout.bind(this),
      companyId: this.activeCompany?.id
    });

    this.navigation = this.renderNavigation(navigation);

    if (!this.isFirstTime) {
      this._navigationService.unregister("main");
    }
    this._navigationService.register("main", this.navigation);
    this._navigationService.setCurrentNavigation("main");
    this.isFirstTime = false;
  }

  triggerLogout() {
    this._loadingBar.show();
    this._auth
      .logout()
      .subscribe({
        next: () => {
          this._router.navigate([RoutesConstants.BASE_AUTH_REDIRECT]);
          this.user = null;
        }
      })
      .add(() => {
        this._loadingBar.hide();
      });
  }

  renderNavigation(nav: KissNavigationItem[]): KissNavigationItem[] {
    return nav.map((element) => {
      if (element.allowedRoles && !element.hidden) {
        element.hidden = !this._roleService.isAuthorized(element.allowedRoles);
      }

      if (element.children) {
        element.children = this.renderNavigation(element.children);
      }

      return element;
    });
  }
}
