import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ImageUploadService {
  private previews: Map<string, string> = new Map();

  savePreview(fileName: string, preview: string) {
    this.previews.set(fileName, preview);
  }

  getPreview(fileName: string): string | null {
    return this.previews.get(fileName) || null;
  }

  removePreview(fileName: string) {
    this.previews.delete(fileName);
  }

  clearPreviews() {
    this.previews.clear();
  }

  setPreviewFromUrl(fileName: string, url: string) {
    this.savePreview(fileName, url);
  }

  async addFileWithPreview(file: File): Promise<void> {
    if (!this.previews.has(file.name)) {
      const preview = await this.generatePreview(file);
      this.savePreview(file.name, preview);
    }
  }

  private generatePreview(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }
}
