import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthenticationModule } from "./main/auth/authentication.module";
import { AuthGuard } from "./shared/guards/auth.guard";
import { RoleGuard } from "./shared/guards/role.guard";
import { RoleEnum } from "./shared/enums/role.enum";
import { CompanyOnboardingModule } from "./main/company-onboarding/company-onboarding.module";
import { ChooseCompanyModule } from "./main/choose-company/choose-company.module";
import { NoCompanyModule } from "./main/no-company/no-company.module";
import { CompanyGuard } from "./shared/guards/company.guard";
import { CompanyResolver } from "./shared/services/company/company.resolver";

const routes: Routes = [
  {
    path: "company-analytics",
    canActivate: [RoleGuard],
    data: {
      allowedRoles: [RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN],
      pageTitle: "Analytics"
    },
    loadChildren: () => import("./main/analytics/analytics.module").then((m) => m.AnalyticsModule)
  },
  {
    canMatch: [AuthGuard],
    canActivate: [RoleGuard],
    path: "users",
    data: {
      allowedRoles: [RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN],
      pageTitle: "Users"
    },
    loadChildren: () => import("./main/user/user.module").then((m) => m.UserModule)
  },
  {
    canMatch: [AuthGuard],
    canActivate: [RoleGuard],
    path: "companies",
    data: {
      allowedRoles: [RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN],
      pageTitle: "Company list"
    },
    loadChildren: () => import("./main/company-list/company-list.module").then((m) => m.CompanyListModule)
  },
  {
    canMatch: [AuthGuard],
    canActivate: [RoleGuard],
    path: "overview-dashboard",
    data: {
      allowedRoles: [RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN],
      pageTitle: "Dashboard"
    },
    loadChildren: () => import("./main/overview-dashboard/overview-dashboard.module").then((m) => m.OverviewDashboardModule)
  },
  {
    canMatch: [AuthGuard],
    path: "profile",
    loadChildren: () => import("./main/profile-settings/profile-settings.module").then((m) => m.ProfileSettingsModule),
    data: {
      pageTitle: "Profile"
    }
  },
  {
    canMatch: [AuthGuard],
    path: "company/:companyId",
    resolve: {
      companyData: CompanyResolver
    },
    data: {
      pageTitle: ({ snapshot }) => {
        return snapshot.data?.companyData?.name || "Company";
      }
    },
    canActivate: [CompanyGuard],
    children: [
      {
        canActivate: [RoleGuard],
        path: "users",
        data: {
          allowedRoles: [RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN, RoleEnum.COMPANY_ADMIN, RoleEnum.CONSULTANT_ADMIN],
          pageTitle: ({ snapshot }) => {
            const companyName = snapshot.data?.companyData?.name || "Company";
            return `Users | ${companyName}`;
          }
        },
        loadChildren: () => import("./main/company-users/company-users.module").then((m) => m.CompanyUsersModule)
      },
      {
        canActivate: [RoleGuard],
        path: "analytics-dashboard",
        data: {
          pageTitle: ({ snapshot }) => {
            const companyName = snapshot.data?.companyData?.name || "Company";
            return `${companyName}'s Overview`;
          }
        },
        loadChildren: () => import("./main/analytics-dashboard/analytics-dashboard.module").then((m) => m.AnalyticsDashboardModule)
      },
      {
        canActivate: [RoleGuard],
        path: "dashboard",
        data: {
          allowedRoles: [RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN, RoleEnum.COMPANY_ADMIN, RoleEnum.CONSULTANT_ADMIN],
          pageTitle: ({ snapshot }) => {
            return snapshot.data?.companyData?.name || "Company";
          }
        },
        loadChildren: () => import("./main/company-dashboard/company-dashboard.module").then((m) => m.CompanyDashboardModule)
      },
      {
        path: "events",
        loadChildren: () => import("./main/company-events/company-events.module").then((m) => m.CompanyEventsModule),
        data: {
          pageTitle: ({ snapshot }) => {
            const companyName = snapshot.data?.companyData?.name || "Company";
            return `Events | ${companyName}`;
          }
        }
      },
      {
        path: "supporting-documents",
        data: {
          pageTitle: ({ snapshot }) => {
            const companyName = snapshot.data?.companyData?.name || "Company";
            return `Supporting Documents | ${companyName}`;
          }
        },
        loadChildren: () => import("./main/company-documents/company-documents.module").then((m) => m.CompanyDocumentsModule)
      },
      {
        path: "themes-and-focus",
        data: {
          pageTitle: ({ snapshot }) => {
            const companyName = snapshot.data?.companyData?.name || "Company";
            return `Themes and Focus Areas | ${companyName}`;
          }
        },
        loadChildren: () => import("./main/company-theme-focus/company-theme-focus.module").then((m) => m.CompanyThemeFocusModule)
      }
    ]
  },
  {
    path: "**",
    redirectTo: "login"
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "enabled"
    }),
    AuthenticationModule,
    CompanyOnboardingModule,
    ChooseCompanyModule,
    NoCompanyModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
