import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'kiss-divider',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        padding: 16px 0;
        background: #d7d7d7;
      }
    `,
  ],
  host: {
    class: 'kiss-divider',
  },
})
export class KissDividerComponent {}
