<ul class="kiss-breadcrumbs__list">
  <li class="kiss-breadcrumbs__item kiss-breadcrumbs__id--{{ breadcrumb.id }}" *ngFor="let breadcrumb of breadcrumbs; let i = index">
    <a
      class="kiss-breadcrumbs__link"
      *ngIf="breadcrumb.isLink"
      [ngClass]="{ active: checkRouteActive(breadcrumb) }"
      (click)="onClick(breadcrumb)"
      >{{ breadcrumb.label }}</a
    >

    <div class="kiss-breadcrumbs__no-link" *ngIf="!breadcrumb.isLink" [ngClass]="{ active: checkRouteActive(breadcrumb) }">
      {{ breadcrumb.label }}
    </div>

    <ng-container *ngIf="i < breadcrumbs.length - 1" [ngSwitch]="settings?.icon?.type">
      <!-- DEFAULT -->
      <span class="kiss-breadcrumbs__icon" *ngIf="!settings?.icon">
        <svg width="8" height="12" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 13L7 7L1 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <!-- !DEFAULT -->

      <!-- KISS-SVG-ICON -->
      <span class="kiss-breadcrumbs__icon kiss-breadcrumbs__kiss-svg-icon" *ngSwitchCase="'kiss-svg-icon'">
        <kiss-svg-icon [icon]="settings.icon.data"></kiss-svg-icon>
      </span>
      <!-- !KISS-SVG-ICON -->

      <!-- HTML -->
      <span class="kiss-breadcrumbs__icon kiss-breadcrumbs__html" *ngSwitchCase="'html'" [innerHTML]="settings.icon.data | safeHtml"></span>
      <!-- !HTML -->

      <!-- TEXT -->
      <span class="kiss-breadcrumbs__icon kiss-breadcrumbs__text" *ngSwitchCase="'text'">{{ settings.icon.data }}</span>
      <!-- !TEXT-->

      <!-- LINK -->
      <span class="kiss-breadcrumbs__icon kiss-breadcrumbs__link" *ngSwitchCase="'link'">
        <img [src]="settings.icon.data" />
      </span>
      <!-- !LINK -->
    </ng-container>
  </li>
</ul>
