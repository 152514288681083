export enum CheckinStatusEnum {
  SCHEDULED = "SCHEDULED",
  COMPLETED = "COMPLETED",
  OVERDUE = "OVERDUE"
}

export const CheckInStatusEnumValues: {
  [key in CheckinStatusEnum]: string;
} = {
  [CheckinStatusEnum.COMPLETED]: "Completed",
  [CheckinStatusEnum.OVERDUE]: "Overdue",
  [CheckinStatusEnum.SCHEDULED]: "Scheduled"
};
