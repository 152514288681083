import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { catchError, lastValueFrom, Observable, ObservableInput, tap, throwError } from "rxjs";
import { RoutesConstants } from "../constants/routes.constrants";
import { AuthService } from "../services/auth/auth.service";
import { DISABLE_ERROR_HANDLING, HIDE_ERROR_POPUP } from "../constants/disable-error-handling";
import { TOAST_AUTH_POSITION_URLS, TOASTR_AUTH_CONFIG } from "../constants/custom-toast-config";

const RELOAD_STATUS = [401, 403];
const LOGOUT_ERROR_CODES = [401];
const RELOAD_EXCEPTION_URLS = ["/login", "/forgot-password", "/reset-password", "/set-password"];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _toastr: ToastrService,
    private _auth: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //IGNORE ERROR
    if (this._disableErrorHandling(req)) {
      return next.handle(req);
    }

    // CONFIG
    const canShowPopup = !this._hideErrorPopup(req);

    const config = {
      canShowPopup
    };

    return next.handle(req).pipe(this.handleGraphQLError(config), this.handleNetworkError(config));
  }

  handleNetworkError(config: { canShowPopup: boolean }) {
    return catchError((response: HttpErrorResponse): ObservableInput<any> => {
      if (RELOAD_STATUS.includes(response.status) && !this._includesUrl(this._router.url)) {
        this._removeUserInfo();
        window.location.reload();
      }

      if (config.canShowPopup) {
        const errorList = response.error.errors;

        if (errorList && errorList.length) {
          this._toastr.error(null, errorList[0]);
        } else {
          let errorContent = this._getApiErrorMessage(response);
          const formattedErrorMessage = this._stringifyErrorMessage(errorContent);

          this._toastr.error(null, formattedErrorMessage);
        }
      }

      return throwError(() => response.error);
    });
  }

  handleGraphQLError(config: { canShowPopup: boolean }) {
    return tap(async (req: any) => {
      const reqError = req?.body?.errors;
      if (reqError && reqError?.length) {
        const errorList = this._getExtensionMessage(reqError);
        const toastPosition = this._getToastPosition();

        for (const error of errorList) {
          if (LOGOUT_ERROR_CODES.includes(error.status)) {
            await this._removeUserInfo();
            this._router.navigate([RoutesConstants.BASE_AUTH_REDIRECT]);

            this._toastr.warning(
              "Your session has expired, please login again to continue using the application.",
              undefined,
              toastPosition
            );
          } else {
            if (config.canShowPopup) {
              this._toastr.error(error.message, undefined, toastPosition);
            }
          }
        }
      }
    });
  }

  private _getExtensionMessage(errors: any[]) {
    return errors.map((err: any) => {
      const original = err?.extensions?.originalError;

      if (original) {
        return {
          message: original?.message,
          status: original?.statusCode || null
        };
      }

      return {
        message: err?.message,
        status: null
      };
    });
  }

  private _getApiErrorMessage(response: any) {
    return response.error.error_description || response.error.error || response.error.message || response.error.detail || response.message;
  }

  private _stringifyErrorMessage(message: any) {
    if (message && typeof message !== "string") {
      return JSON.stringify(message);
    }

    return message;
  }

  private _getToastPosition() {
    const isAuthRoute = TOAST_AUTH_POSITION_URLS.some((url: string) => this._router.url.includes(url));

    if (isAuthRoute) {
      return TOASTR_AUTH_CONFIG;
    }
  }

  private async _removeUserInfo() {
    await lastValueFrom(this._auth.logout());
  }

  private _includesUrl(fullUrl) {
    return RELOAD_EXCEPTION_URLS.some((url) => fullUrl.includes(url));
  }

  private _disableErrorHandling(request: HttpRequest<any>) {
    return request.context.get(DISABLE_ERROR_HANDLING);
  }

  private _hideErrorPopup(request: HttpRequest<any>) {
    if (request?.body?.variables) {
      return request.body.variables[HIDE_ERROR_POPUP];
    }

    return false;
  }
}
