import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { KissSelectModule } from "@kiss/components/common";
import { CompanyTeamFormService } from "./company-team-form.service";
import { CompanyTeamFormComponent } from "./company-team-form.component";
import { PromptDialogModule } from "../prompt-dialog/prompt-dialog.module";
import { EditableTextControlModule } from "../editable-text-control/editable-text-control.module";
import { KissTooltipModule } from "@kiss/directives";

@NgModule({
  declarations: [CompanyTeamFormComponent],
  imports: [ReactiveFormsModule, KissSelectModule, CommonModule, PromptDialogModule, EditableTextControlModule, KissTooltipModule],
  providers: [CompanyTeamFormService],
  exports: [CompanyTeamFormComponent]
})
export class CompanyTeamFormModule {}
