import { KissLayoutConfig } from './types/kiss-layout-config';

export const KISS_LAYOUT_DEFAULT_CONFIG: KissLayoutConfig = {
  layoutType: 'vertical-classic',
  verticalClassicConfig: {
    navigationSidebar: {
      enable: true,
      position: 'left',
      expand: true,
    },
    toolbar: {
      enable: true,
      position: 'top',
    },
  },
};
