<div class="company-onboarding__wrapper company-onboarding-teams">
  <div class="company-onboarding__form-content">
    <div class="company-onboarding__step-info">
      <p class="company-onboarding__step-info__name">Step 5: Teams configuration</p>
      <p class="company-onboarding__step-info__title">Company Configuration</p>
      <p class="company-onboarding__step-info__description">Add Teams and Groups here.</p>
    </div>
    <div class="company-onboarding__form" [formGroup]="form">
      <p class="company-onboarding__form__title">Team Configuration</p>

      <ng-container formArrayName="teams">
        <ng-container *ngFor="let item of form?.get('teams')['controls']; let i = index" [formGroupName]="i" #teamItem>
          <app-company-team-form
            [form]="item"
            [companyLeads]="companyLeads"
            (onDelete)="onTeamDelete(i)"
            [isOnboarding]="true"
          ></app-company-team-form>
        </ng-container>
      </ng-container>
      <div class="company-onboarding__footer">
        <button class="cc-btn cc-btn--lg cc-btn__stroked add-btn" [disabled]="isLoading" (click)="onAddNewTeam()">
          <i class="fa-regular fa-plus"></i> Add new team
        </button>
      </div>
    </div>
  </div>
</div>
