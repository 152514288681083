import { Injectable } from "@angular/core";
import { StorageConstants } from "app/shared/constants/storage.constants";
import { environment } from "environments/environment";

@Injectable({ providedIn: "root" })
export class StorageControlService {
  private _getStorageVersion(): string {
    const value = localStorage.getItem(StorageConstants.STORAGE_VERSION_DATA);
    return value ? value : undefined;
  }

  private _setStorageVersion(value: string): void {
    localStorage.setItem(StorageConstants.STORAGE_VERSION_DATA, value);
  }

  initStorageVersion() {
    // compare
    const currentVersion = environment.storage_version;
    const savedVersion = this._getStorageVersion();

    if (currentVersion !== savedVersion) {
      localStorage.clear();
      this._setStorageVersion(currentVersion);
    }
  }
}
