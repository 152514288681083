import { Injectable } from "@angular/core";
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { filter, iif, map, of, switchMap, take, tap } from "rxjs";

/**
 * Service used to hide inital load on layouts where toolbars and sidebars are hidden during initialization
 */
@Injectable()
export class InitialLoadHandlerService {
  ACTIVE_ROUTE = ["/onboarding", "/choose-company", "/login", "/forgot-password", "/reset-password", "/set-password"];

  constructor(private _router: Router) {}

  onInitalLoadComplete() {
    let _isValidRoute = false;
    return this._router.events.pipe(
      tap((e) => {
        // check if valid during NavigationStart
        if (e instanceof NavigationStart) {
          _isValidRoute = this._checkIfValidRoute(e.url);
        }
      }),
      switchMap((e) => {
        // wait for Navigation End
        if (_isValidRoute) {
          return of(e).pipe(filter((e) => e instanceof NavigationEnd));
        }

        // return any event if not valid route
        return of(e);
      }),
      map(() => true),
      take(1)
    );
  }

  private _checkIfValidRoute(url: string) {
    return !!this.ACTIVE_ROUTE.find((route) => url.includes(route));
  }
}
