export enum CompanyStatusEnum {
  ACTIVE = "ACTIVE",
  IN_ACTIVE = "IN_ACTIVE"
}

export const CompanyStatusEnumValues: {
  [key in CompanyStatusEnum]: string;
} = {
  [CompanyStatusEnum.ACTIVE]: "Active",
  [CompanyStatusEnum.IN_ACTIVE]: "Inactive"
};
