import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KissIconPrePostModule } from '@kiss/directives/kiss-icon-pre-post';
import { KissPaginatorResultsDirective } from './directives/kiss-paginator-results.directive';
import { KissPaginatorComponent } from './kiss-paginator.component';
import { KissSvgIconModule } from "../kiss-svg-icon/kiss-svg-icon.module";

@NgModule({
  declarations: [KissPaginatorComponent, KissPaginatorResultsDirective],
  imports: [CommonModule, KissSvgIconModule],
  exports: [
    KissPaginatorComponent,
    KissIconPrePostModule,
    KissPaginatorResultsDirective,
  ],
})
export class KissPaginatorModule {}
