import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Injectable()
export class KissBackdropService {
  _renderer: Renderer2;
  private _backdrop: HTMLElement | null;
  private _player: AnimationPlayer;
  animation: string = '150ms ease';
  animationOptionsCreate = { opacity: 1 };
  animationOptionsRemove = { opacity: 0 };

  constructor(
    rendererFactory: RendererFactory2,
    private _animationBuilder: AnimationBuilder,
    private _elRef: ElementRef
  ) {
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Hide the backdrop
   *
   * @public
   */
  createBackdrop() {
    //create backdrop
    this._backdrop = this._renderer.createElement('div');

    //add class
    this._renderer.addClass(this._backdrop, 'kiss-backdrop');

    //insert into dom
    this._renderer.insertBefore(
      this._elRef.nativeElement.parentElement,
      this._backdrop,
      this._elRef.nativeElement
    );

    //ANIMATE BACKDROP
    this._player = this._animateBackdrop(this.animationOptionsCreate);

    // Play the animation
    this._player.play();
  }

  /**
   * Animate backdrop
   * @param {any} animationOptions
   * @private
   */
  private _animateBackdrop(options: any): AnimationPlayer {
    return this._animationBuilder
      .build([animate(this.animation, style(options))])
      .create(this._backdrop);
  }

  /**
   * Hide the backdrop
   *
   * @public
   */
  removeBackdrop(): void {
    if (!this._backdrop) {
      return;
    }

    // Create the leave animation and attach it to the player
    this._player = this._animateBackdrop(this.animationOptionsRemove);

    // Play the animation
    this._player.play();

    // Once the animation is done...
    this._player.onDone(() => {
      // If the backdrop still exists...

      // Remove the backdrop
      this._backdrop?.parentNode?.removeChild(this._backdrop);
      this._backdrop = null;
    });
  }

  /**
   * Emits an event when the backdrop is clicked
   *
   * @public
   */
  onBackdropClick(): Observable<Event> {
    return fromEvent(this._backdrop, 'click');
  }
}
