import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ResetPasswordComponent } from "./reset-password.component";
import { UnAuthGuard } from "app/shared/guards/unauth.guard";
import { KissTooltipModule } from "@kiss/directives";

const routes: Routes = [
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    canMatch: [UnAuthGuard],
    data: {
      layoutConfig: {
        layoutType: "vertical-classic",
        verticalClassicConfig: {
          navigationSidebar: {
            enabled: false
          },
          toolbar: {
            enabled: false
          }
        }
      },
      pageTitle: "Reset Password"
    }
  }
];

@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [CommonModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule, KissTooltipModule],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class ResetPasswordModule {}
