import { animate, state, style, transition, trigger } from "@angular/animations";
import { KissAnimationCurves, KissAnimationDurations } from "@kiss/animations/animation-collection/defaults";

// -----------------------------------------------------------------------------------------------------
// @ Expand / collapse
// -----------------------------------------------------------------------------------------------------
const expandCollapse = trigger("expandCollapse", [
  state(
    "void, collapsed",
    style({
      height: "0",
      opacity: 0,
      "padding-top": "0",
      "padding-bottom": "0"
    })
  ),

  state("*, expanded", style("*")),

  // Prevent the transition if the state is false
  transition("void <=> false, collapsed <=> false, expanded <=> false", []),

  // Transition
  transition("void <=> *, collapsed <=> expanded", animate("{{timings}}"), {
    params: {
      timings: `${KissAnimationDurations.entering} ${KissAnimationCurves.deceleration}`
    }
  })
]);

export { expandCollapse };
