import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";
import { ImageUploadComponent } from "./image-upload.component";
import { ImageUploadService } from "./image-upload.service";
import { CompanyImageComponent } from "../company-image/company-image.component";

@NgModule({
  declarations: [ImageUploadComponent],
  imports: [CommonModule, CompanyImageComponent],
  providers: [ImageUploadService],
  exports: [ImageUploadComponent]
})
export class ImageUploadModule {}
