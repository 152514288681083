import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { KissLoadingBarService } from './services/kiss-loading-bar.service';

@Component({
  selector: 'kiss-loading-bar',
  templateUrl: './kiss-loading-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'kiss-loading-bar',
  },
})
export class KissLoadingBarComponent implements OnInit, OnDestroy {
  visible = false;
  private sub: Subscription;
  constructor(
    private _kissLoadingBarService: KissLoadingBarService,
    private _renderer: Renderer2,
    private _elRef: ElementRef,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.sub = this._kissLoadingBarService._onVisibilityChange.subscribe((data) => {
      this.visible = data;

      this._toggleOpenClass();
      this._cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  private _toggleOpenClass() {
    //AVOIDING EXPRESSION CHANGED AFTER CHECKED WITH HOST BINDING
    if (this.visible) {
      this._renderer.addClass(this._elRef.nativeElement, 'kiss-loading-bar__open');
    } else {
      this._renderer.removeClass(this._elRef.nativeElement, 'kiss-loading-bar__open');
    }
  }
}
