import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RoleService } from "../services/auth/role.service";
import { RoleEnum } from "../enums/role.enum";
import { RoutesConstants } from "../constants/routes.constrants";
import { AuthService } from "../services/auth/auth.service";
import { CompanyDataService } from "../services/company/company-data.service";

@Injectable()
export class CompanyGuard {
  constructor(
    private _companyDataService: CompanyDataService,
    private _role: RoleService,
    private _router: Router,
    private _authService: AuthService
  ) {}

  canActivate() {
    if (this._role.isAuthorized([RoleEnum.CC_ADMIN, RoleEnum.SUPER_ADMIN])) {
      return true;
    }

    if (this._authService.isAuthenticated()) {
      const companies = this._companyDataService.getSavedCompanies();

      if (companies?.length) {
        return true;
      }

      return this._router.createUrlTree([RoutesConstants.NO_COMPANIES]);
    }
  }
}
