<div class="company-onboarding__wrapper company-kick-off">
  <div class="company-onboarding__form-content">
    <div class="company-kick-off__content-step-description">
      <p class="step-name">Step 4: kick - off sessions</p>
      <p class="step-title">Company Configuration</p>
      <p class="step-description">This is where you choose who will organise the Kick-off sessions</p>

      <div class="company-onboarding__form" [formGroup]="form">
        <div class="kick-off">
          <p class="company-onboarding__form__title">Kick - off Sessions</p>

          <!-- <kiss-select
          class="cc-select company-kick-off__select"
          dropdownClass="cc-select"
          formControlName="kickOffVisibleToCultureLead"
          placeholder="Choose kick-off organisation"
        >
          <kiss-select-option [value]="false">
            Company admin to organise
          </kiss-select-option>

          <kiss-select-option [value]="true">
          Team Facilitator to organise
          </kiss-select-option>
        </kiss-select> -->

          <div class="kick-off__options">
            <div class="option">
              <div class="form-item-container">
                <label class="cc-radio">
                  <input
                    type="radio"
                    class="cc-radio__input"
                    name="kickOffVisibleToCultureLead"
                    value="false"
                    formControlName="kickOffVisibleToCultureLead"
                  />
                  <span class="cc-radio__checkmark">
                    <span class="cc-radio__checkmark__inner"></span>
                  </span>
                  <span class="cc-radio__text">Company admin to organise</span>
                </label>
              </div>
            </div>
            <div class="option">
              <div class="form-item-container">
                <label class="cc-radio">
                  <input
                    type="radio"
                    class="cc-radio__input"
                    name="kickOffVisibleToCultureLead"
                    value="true"
                    formControlName="kickOffVisibleToCultureLead"
                  />
                  <span class="cc-radio__checkmark">
                    <span class="cc-radio__checkmark__inner"></span>
                  </span>
                  <span class="cc-radio__text">Team Facilitator to organise</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
