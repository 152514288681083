import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KissOverlayModule } from '@kiss/components/common/kiss-overlay/kiss-overlay.module';
import { KissDialogContentDirective } from './helpers/kiss-dialog-content.directive';
import { KissDialogComponent } from './kiss-dialog.component';
import { KissDialogService } from './kiss-dialog.service';
import { KissDialogContentComponent } from './kiss-dialog-content/kiss-dialog-content.component';

@NgModule({
  declarations: [KissDialogComponent, KissDialogContentDirective, KissDialogContentComponent],
  imports: [CommonModule, KissOverlayModule],
  providers: [KissDialogService],
})
export class KissDialogModule {}
