import { Injectable, TemplateRef, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { KissOverlay } from './kiss-overlay';

import { KissOverlayInfo } from './kiss-overlay-info';
import { KissOverlayRef } from './kiss-overlay-ref';

/**
 * Service that allows you to insert overlays over `TemplateRef` with settings provided in `KissOverlayInfo`.
 * You could also use kissOverlay directive for easier usage, see `kiss-overlay.directive.ts` for usage
 *
 * `onOverlayCreated` - Observable that fires when an overlay is created ( when `createOverlay` is used)
 *
 *  @returns {KissOverlayRef} KissOverlay
 *
 * `onOverlayRemoved` - Observable that fires when an overlay is removed ( when `removeOverlay` is used)
 *
 *  @returns {KissOverlayRef} KissOverlay
 *
 *@example
 *   const overlayRef = this._kissOverlayService.createOverlay(TemplateRef, {
 *    hasBackdrop: true,
 *    backdropClass: 'example',
 *  });
 *
 *  this._kissOverlayService.removeOverlay(overlayRef);
 *
 * // used in case you need to do something when this overlay is removed
 *  const overlaySubscription = overlayRef.onOverlayRemoved.subscribe(() => {
 *      this._toggleClosed(); // random function that executes when closed
 *  });
 *
 *  // used in case you need to do something when overlay container is clicked
 *  const overlaySubscription = overlayRef.onContainerClick.subscribe(() => {
 *      this._toggleClosed(); // random function that executes when closed
 *  });
 *
 * // used in case you need to do something when overlay backdrop is clicked
 *  const overlaySubscription = overlayRef.onBackdropClick.subscribe(() => {
 *      this._toggleClosed(); // random function that executes when closed
 *  });
 */
@Injectable({
  providedIn: 'root',
})
export class KissOverlayService {
  /**
   * Observable that fires when overlay is created
   */
  onOverlayCreated: Subject<KissOverlay>;
  /**
   * Observable that fires when overlay is removed with removeOverlay function
   */
  onOverlayRemoved: Subject<KissOverlayRef>;

  constructor() {
    this._init();
  }

  //-------------------------------------
  // PRIVATE METHODS
  //-------------------------------------

  private _init() {
    this.onOverlayCreated = new Subject();
    this.onOverlayRemoved = new Subject();
  }

  //-------------------------------------
  // PUBLIC METHODS
  //-------------------------------------

  /**
   * Creates an overlay
   *
   * @param template
   * @param options
   * @returns {KissOverlayRef} KissOverlay
   */
  createOverlay(
    template: TemplateRef<any> | Type<any>,
    options?: KissOverlayInfo
  ): KissOverlayRef {
    if (!template) return;

    const overlayInfo = new KissOverlayInfo(options);
    const overlayRef = new KissOverlayRef(overlayInfo);
    const overlay = new KissOverlay(template, overlayRef);

    this.onOverlayCreated.next(overlay);

    return overlayRef;
  }

  /**
   * Removes an overlay
   * @param overlay
   * @returns {void}
   */
  removeOverlay(overlay: KissOverlayRef): void {
    this.onOverlayRemoved.next(overlay);
  }
}
