import { Injectable } from "@angular/core";
import { RoleEnum } from "app/shared/enums/role.enum";
import { GET_ME } from "app/shared/gql/operations/profile/profile-settings.operations";
import { CompanyModel } from "app/shared/models/company/company.model";
import { map, tap, of, forkJoin } from "rxjs";
import { RoleService } from "../auth/role.service";
import { Apollo } from "apollo-angular";
import { AuthService } from "../auth/auth.service";
import { CompanyService } from "./company.service";

@Injectable({
  providedIn: "root"
})
export class CompanyDataService {
  private _companies: CompanyModel[] = [];

  constructor(
    private _apollo: Apollo,
    private _roleService: RoleService,
    private _auth: AuthService,
    private _companyService: CompanyService
  ) {}

  getMyCompanies() {
    return this._apollo
      .query({
        query: GET_ME
      })
      .pipe(
        map((response: any) => {
          const role = response?.data?.getMe?.role;
          if (role === RoleEnum.CONSULTANT_ADMIN) {
            return response?.data?.getMe?.consultantAdminCompany?.map((item: any) => item?.company);
          }

          if (role === RoleEnum.CULTURE_LEAD) {
            return response?.data?.getMe?.companyCultureLeads?.map((item: any) => item?.company);
          }

          if (role === RoleEnum.COMPANY_ADMIN) {
            return response?.data?.getMe?.companyAdminOf;
          }
        }),
        tap((response) => {
          this._setCompanies(response);
        })
      );
  }

  /**
   * Returns and sets active acompany after login
   * And then navigates according to the right role
   * @returns
   */
  getCompaniesAfterLogin() {
    if (!this._auth.isAuthenticated()) {
      return of(null);
    }

    return this.getMyCompanies().pipe(
      tap(() => {
        this._setActiveCompanyAfterLogin();
      })
    );
  }

  /**
   * Returns and sets active company when the app is initalized
   * If user is not authenticated it returns nothing
   * @returns
   */
  getInitialCompanyData() {
    if (!this._auth.isAuthenticated()) {
      return of(null);
    }

    const request = [this.getMyCompanies()];
    const companyId = this._companyService.getActiveCompanyIdFromStorage();

    if (companyId) {
      request.push(
        this._companyService.getActiveCompanyApi({ id: companyId }).pipe(
          tap((company: CompanyModel) => {
            this._companyService.setActiveCompany(company);
          })
        )
      );
    }

    return forkJoin(request).pipe(
      tap(() => {
        this._setActiveCompanyOnInit();
      })
    );
  }

  /**
   * Set the active company on init / reload
   */
  private _setActiveCompanyOnInit() {
    const canHaveOne = this._roleService.isAuthorized([RoleEnum.CONSULTANT_ADMIN, RoleEnum.CULTURE_LEAD, RoleEnum.COMPANY_ADMIN]);
    const isAdmin = this._roleService.isAuthorized([RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN]);

    if (canHaveOne) {
      // should already exist from init if logged in
      const activeCompany = this._companyService.getActiveCompany();

      if (this._companies?.length && !activeCompany) {
        this._companyService.setActiveCompany(this._companies[0]);
      } else if (!activeCompany) {
        this._companyService.clearActiveCompanyFromStorage();
      }
    }

    if (isAdmin) {
      this._companyService.clearActiveCompanyFromStorage();
    }
  }

  /**
   * Set the active company after login
   * Consultant needs to select the active company
   */
  private _setActiveCompanyAfterLogin() {
    const canHaveMultiple = this._roleService.isAuthorized([RoleEnum.CONSULTANT_ADMIN]);
    const canHaveOne = this._roleService.isAuthorized([RoleEnum.CULTURE_LEAD, RoleEnum.COMPANY_ADMIN]);
    const isAdmin = this._roleService.isAuthorized([RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN]);

    if (canHaveOne) {
      // should already exist from init if logged in
      const activeCompany = this._companyService.getActiveCompany();

      /**
       * Should be refactored. onCompanyChange is not called without this if activeCompany check.
       * onCompanyChange should be called inside setActiveCompany. But does not render color for Culture Lead
       */
      if (activeCompany) {
        this._companyService.setActiveCompany(activeCompany);
      } else if (this._companies?.length && !activeCompany) {
        this._companyService.setActiveCompany(this._companies[0]);
      } else if (!activeCompany) {
        this._companyService.clearActiveCompanyFromStorage();
      }
    }

    if (isAdmin && canHaveMultiple) {
      this._companyService.clearActiveCompanyFromStorage();
    }
  }

  getSavedCompanies() {
    return this._companies;
  }

  private _setCompanies(data: CompanyModel[] = []) {
    if (Array.isArray(data)) {
      this._companies = data || [];
    } else {
      this._companies = [data];
    }
  }
}
