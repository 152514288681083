import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { KissSetPositionModule } from "@kiss/directives";
import { KissIconPrePostModule } from "@kiss/directives/kiss-icon-pre-post";
import { KissCheckboxModule } from "../kiss-checkbox/kiss-checkbox.module";
import { KissOverlayModule } from "../kiss-overlay/kiss-overlay.module";
import { KissSearchModule } from "../kiss-search/kiss-search.module";
import { KissSelectOptionComponent } from "./kiss-select-option/kiss-select-option.component";
import { KissSelectComponent } from "./kiss-select.component";
import { KissOptionHeaderTemplateDirective } from "./directives/kiss-select-option-header-content.directive";

@NgModule({
  declarations: [KissSelectOptionComponent, KissSelectComponent, KissOptionHeaderTemplateDirective],
  imports: [CommonModule, KissSetPositionModule, KissSearchModule, KissCheckboxModule, KissOverlayModule],
  exports: [KissSelectOptionComponent, KissSelectComponent, KissIconPrePostModule, KissOptionHeaderTemplateDirective]
})
export class KissSelectModule {}
