const DEFAULT_SETTINGS = {
  enabled: true,
  weekdays: [
    { id: 1, label: 'MON' },
    { id: 2, label: 'TUE' },
    { id: 3, label: 'WED' },
    { id: 4, label: 'THU' },
    { id: 5, label: 'FRI' },
    { id: 6, label: 'SAT' },
    { id: 0, label: 'SUN' },
  ],
  enabledDates: [],
  minDate: undefined,
  maxDate: undefined,
};

export class KissDatepickerDaySettings {
  enabled: boolean;
  weekdays: {
    id: number;
    label: string;
  }[];
  enabledDates: string[];
  minDate: Date;
  maxDate: Date;

  constructor(data: any) {
    data = data || {};

    this.enabled = data.enabled ?? DEFAULT_SETTINGS.enabled;
    this.weekdays = data.weekdays || DEFAULT_SETTINGS.weekdays;

    //check enable date validity
    const enabledDates = data.enabledDates || DEFAULT_SETTINGS.enabledDates;
    this.enabledDates = this._setEnabledDates(enabledDates);

    const minDate = data.minDate || DEFAULT_SETTINGS.minDate;
    if (minDate && this._isValidDate(minDate)) {
      this.minDate = new Date(minDate);
    }

    const maxDate = data.maxDate || DEFAULT_SETTINGS.maxDate;
    if (maxDate && this._isValidDate(maxDate)) {
      this.maxDate = new Date(maxDate);
    }
  }

  private _setEnabledDates(value: any) {
    if (value?.length) {
      return value
        .filter((item) => this._isValidDate(item))
        .map((item) => new Date(item).toDateString());
    } else {
      return [];
    }
  }

  /**
   * Parse the value passed and check if it's NaN
   * @param date
   * @returns boolean
   */
  private _isValidDate(date: any) {
    return !isNaN(Date.parse(date));
  }
}
