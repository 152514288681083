import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlContainer, FormGroup } from "@angular/forms";
import { KissLoadingBarService } from "@kiss/components/common";
import { environment } from "environments/environment";
import { ImageUploadService } from "./image-upload.service";
import { ApiConstants } from "app/shared/constants/api.constants";

@Component({
  selector: "app-image-upload",
  templateUrl: "./image-upload.component.html"
})
export class ImageUploadComponent implements OnInit {
  form: FormGroup;
  @Input() imagePreview: string | null = null;
  @Output() fileSelected = new EventEmitter<File>();
  @Output() logoUploaded = new EventEmitter<void>();
  @Input() size: string;
  @Input() data: any;

  constructor(
    private kissLoader: KissLoadingBarService,
    private http: HttpClient,
    private controlContainer: ControlContainer,
    private imageUploadService: ImageUploadService
  ) {}

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }

  async onFileSelected(event: Event): Promise<void> {
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput?.files ? fileInput.files[0] : null;

    if (file) {
      await this.imageUploadService.addFileWithPreview(file);
      this.imagePreview = this.imageUploadService.getPreview(file.name);

      this.fileSelected.emit(file);

      this.uploadLogo(file);
    }
  }

  uploadLogo(file: File): void {
    const formData = new FormData();
    formData.append("files", file);

    this.kissLoader.show();
    this.http.post<any[]>(ApiConstants.API_MEDIA_UPLOAD, formData).subscribe({
      next: (res) => {
        if (res && res.length > 0) {
          this.form.get("logoId").setValue(res[0].id);
          this.logoUploaded.emit();
        }
        this.kissLoader.hide();
      },
      error: (err) => {
        this.kissLoader.hide();
        console.error("File upload failed", err);
      }
    });
  }
}
