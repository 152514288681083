import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NoCompanyComponent } from "./no-company.component";
import { RouterModule, Routes } from "@angular/router";
import { RoleEnum } from "app/shared/enums/role.enum";
import { AuthGuard } from "app/shared/guards/auth.guard";
import { RoleGuard } from "app/shared/guards/role.guard";
import { SharedPipesModule } from "app/shared/pipes/shared.pipes.module";

const routes: Routes = [
  {
    path: "no-companies",
    component: NoCompanyComponent,
    canMatch: [AuthGuard],
    canActivate: [RoleGuard],
    data: {
      allowedRoles: [RoleEnum.CONSULTANT_ADMIN, RoleEnum.CULTURE_LEAD, RoleEnum.COMPANY_ADMIN],
      layoutConfig: {
        layoutType: "vertical-classic",
        verticalClassicConfig: {
          navigationSidebar: {
            enabled: false
          },
          toolbar: {
            enabled: false
          }
        }
      }
    }
  }
];

@NgModule({
  declarations: [NoCompanyComponent],
  imports: [CommonModule, RouterModule.forChild(routes), SharedPipesModule]
})
export class NoCompanyModule {}
