import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Input,
  OnDestroy,
  Type,
  ViewChild,
} from '@angular/core';
import { KissDialogContent } from '../helpers/kiss-dialog-content';
import { KissDialogContentDirective } from '../helpers/kiss-dialog-content.directive';

@Component({
  selector: 'kiss-dialog-content',
  templateUrl: './kiss-dialog-content.component.html',
  host: {
    class: 'kiss-dialog__content',
    '[attr.tabindex]': '0',
  },
})
export class KissDialogContentComponent implements AfterViewInit, OnDestroy {
  componentRef: ComponentRef<any>;

  @Input() content: KissDialogContent;

  @ViewChild(KissDialogContentDirective)
  insertionPoint: KissDialogContentDirective;

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _cdr: ChangeDetectorRef,
    private _elRef: ElementRef
  ) {}

  //-------------------------------------
  // Lifecycle hooks
  //-------------------------------------

  /**
   * AfterViewInit
   */
  ngAfterViewInit(): void {
    this._loadComponent(this.content?.template);
    this._focus();
  }

  /**
   * OnDestroy
   */
  ngOnDestroy(): void {
    if (this.componentRef) this.componentRef.destroy();
  }

  //-------------------------------------
  // Private methods
  //-------------------------------------

  /**
   * Loads the dynamically created component inside `viewContainerRef`
   * @param componentType
   */
  private _loadComponent(componentType: Type<any>): void {
    if (!componentType) return;
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);

    this._cdr.detectChanges();
  }

  private _focus() {
    //FOCUS ON
    setTimeout(() => {
      if (this._elRef?.nativeElement) {
        this._elRef.nativeElement.focus();
      }
    });
  }
}
