import { gql } from "apollo-angular";
import { COMPANY_FRAGMENT } from "../../fragments/company.fragment";

const GET_MY_COMPANIES = gql`
  ${COMPANY_FRAGMENT}
  query GetMyCompanies {
    myCompanies {
      company {
        ...CompanyFragment
      }
    }
  }
`;

export { GET_MY_COMPANIES };
