<div class="company-onboarding__wrapper company-kick-off-docs">
  <div class="company-onboarding__form-content" [formGroup]="form">
    <div class="company-onboarding__step-info">
      <p class="company-onboarding__step-info__name">Step 5: adding supporting documents</p>
      <p class="company-onboarding__step-info__title">Company Configuration</p>
      <p class="company-onboarding__step-info__description">You can upload folders and files and share them with employees</p>
    </div>

    <div class="company-onboarding__form" formGroupName="media">
      <p class="company-onboarding__form__title">Choose what you want to upload</p>

      <div class="form-item-container">
        <label for="mediaType" class="form-label"
          >Document Type<i
            class="fal fa-info-circle kiss-tooltip__icon kiss-tooltip__icon--sm"
            kissTooltip="Any files uploaded at this stage will be visible to all users. If you want to hold files within a specific folder then you must create the folder and then upload the files into the relevant folder."
            kissTooltipType="html"
            kissTooltipTimeout="100"
          ></i
        ></label>

        <kiss-select class="cc-select" dropdownClass="cc-select" placeholder="Choose" formControlName="type" (onSelectChange)="resetForm()">
          <kiss-select-option value="files">Files</kiss-select-option>
          <kiss-select-option value="folder">Folder</kiss-select-option>
        </kiss-select>
      </div>

      <!-- Folder-specific fields -->
      <div class="file-upload" *ngIf="form.get('media.type')?.value === 'folder'">
        <div class="form-item-container">
          <label for="folderTitle" class="form-label">Folder Title*</label>
          <div class="cc-input-container">
            <input class="cc-input" type="text" placeholder="Enter Folder Title" formControlName="name" />
          </div>
        </div>

        <div class="form-item-container">
          <div class="cc-input-container textarea-container">
            <textarea
              maxlength="300"
              class="cc-input textarea"
              formControlName="description"
              placeholder="Folder Description"
              (input)="updateCharCount()"
            ></textarea>
            <i class="icon-placeholder fa-light fa-pen-to-square" *ngIf="!form.get('media.description').value"></i>
            <span class="char-counter">{{ charCount }}/300</span>
          </div>
        </div>
      </div>
      <app-files-upload [formGroup]="form" *ngIf="form.get('media.type')?.value"></app-files-upload>
    </div>
  </div>
</div>
