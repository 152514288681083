import { NgModule } from "@angular/core";
import { ConfirmAccountModule } from "./confirm-account/confirm-account.module";
import { ForgotPasswordModule } from "./forgot-password/forgot-password.module";
import { LoginModule } from "./login/login.module";
import { ResetPasswordModule } from "./reset-password/reset-password.module";

@NgModule({
  imports: [LoginModule, ForgotPasswordModule, ResetPasswordModule, ConfirmAccountModule]
})
export class AuthenticationModule {}
