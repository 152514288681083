import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { KissSvgIconSettings } from './kiss-svg-icon-settings.type';
import { KissSvgIconComponent } from './kiss-svg-icon.component';
import { KissSvgIconService } from './kiss-svg-icon.service';

@NgModule({
  declarations: [KissSvgIconComponent],
  imports: [CommonModule],
  exports: [KissSvgIconComponent],
})
export class KissSvgIconModule {
  static forRoot(settings: KissSvgIconSettings): ModuleWithProviders<KissSvgIconModule> {
    return {
      ngModule: KissSvgIconModule,
      providers: [KissSvgIconService, { provide: 'kissSvgIconSettings', useValue: settings }],
    };
  }
}
