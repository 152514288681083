import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Apollo } from "apollo-angular";
import { GET_COMPANY_BY_ID, UPDATE_COMPANY } from "app/shared/gql/operations/company/company.operations";
import { CompanyModel } from "app/shared/models/company/company.model";
import { map, Observable } from "rxjs";

@Injectable()
export class CompanyOnboardingService implements Resolve<any> {
  id: number;
  constructor(private _apollo: Apollo) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const companyId = +route.params.id;
    this.id = companyId;
    return this.getCompany({ id: companyId });
  }

  getCompany({ id }): Observable<CompanyModel> {
    return this._apollo
      .query({
        query: GET_COMPANY_BY_ID,
        variables: {
          companyId: id
        }
      })
      .pipe(map((response: any) => response?.data?.company));
  }

  updateCompany(data) {
    return this._apollo.client.mutate({
      mutation: UPDATE_COMPANY,
      variables: {
        companyId: this.id,
        updateCompanyInput: data
      }
    });
  }
}
