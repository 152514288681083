import { Component, ElementRef, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-editable-text-control",
  templateUrl: "./editable-text-control.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditableTextControlComponent)
    }
  ]
})
export class EditableTextControlComponent implements ControlValueAccessor {
  isEdit: boolean = false;

  public control = new FormControl("");
  onTouched = () => {};
  onChange: (_: string) => {};

  @Input()
  public placeholder: string;

  @Input()
  public readonly: boolean;

  public change() {
    this.onChange(this.control.value);
    this.onTouched();
  }

  constructor(private _el: ElementRef) {}

  writeValue(value: any): void {
    this.control.setValue(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.control.disable({ emitEvent: false }) : this.control.enable({ emitEvent: false });
  }

  onNameChange(input: any) {
    if (input.contentEditable && input.contentEditable != "inherit" && input.contentEditable != "false") {
      //Yes, this is a content editable element!
      this.control.setValue(input.textContent);
      this.control.markAsDirty();
      this.isEdit = false;
      this.change();
    }
  }

  setParentEditable({ triggerFocus }) {
    this.isEdit = true;

    if (triggerFocus) {
      this.triggerCursorMove();
    }
  }

  toggleIconEditable() {
    this.isEdit = !this.isEdit;

    if (this.isEdit) {
      this.triggerCursorMove();
    }
  }

  triggerCursorMove() {
    setTimeout(() => {
      const el: any = this._el.nativeElement.querySelector(".editable-text-control__text");
      this.moveCursorToEnd(el);
    }, 0);
  }

  moveCursorToEnd = (el: any) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.setStart(el, el.childNodes.length);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  };
}
