import { Directive, ViewContainerRef } from "@angular/core";
import { BaseKissCellOutletDirective } from "../base/base-kiss-cell-outlet.directive";

@Directive({
  selector: "[kissHeaderCellOutlet]"
})
export class KissHeaderCellOutletDirective extends BaseKissCellOutletDirective {
  constructor(public override _viewContainer: ViewContainerRef) {
    super(_viewContainer);
  }
}
