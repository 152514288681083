export enum MediaTypeEnum {
  FOLDER = "folder",
  MEDIA = "media"
}

export const MediaTypeEnumValues: {
  [key in MediaTypeEnum]: string;
} = {
  [MediaTypeEnum.FOLDER]: "Folder",
  [MediaTypeEnum.MEDIA]: "File"
};
