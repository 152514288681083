import { Component } from "@angular/core";
import { InitialLoadHandlerService } from "./initial-load-handler.service";

@Component({
  selector: "app-initial-load-handler",
  templateUrl: "./initial-load-handler.component.html"
})
export class InitialLoadHandlerComponent {
  isLoading = false;
  constructor(private _initialLoadHandlerService: InitialLoadHandlerService) {
    this.isLoading = true;
    this._initialLoadHandlerService.onInitalLoadComplete().subscribe(() => {
      this.isLoading = false;
    });
  }
}
