import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CompanyOnboardingKickOffComponent } from "./company-onboarding-kick-off/company-onboarding-kick-off.component";
import { CompanyOnboardingLogoColorsComponent } from "./company-onboarding-logo-colors/company-onboarding-logo-colors.component";
import { CompanyOnboardingStrategiesComponent } from "./company-onboarding-strategies/company-onboarding-strategies.component";
import { CompanyOnboardingValuesComponent } from "./company-onboarding-values/company-onboarding-values.component";
import { CompanyOnboardingComponent } from "./company-onboarding.component";
import { CompanyOnboardingSidebarComponent } from "./company-onboarding-sidebar/company-onboarding-sidebar.component";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "app/shared/guards/auth.guard";
import { RoleGuard } from "app/shared/guards/role.guard";
import { RoleEnum } from "app/shared/enums/role.enum";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CompanyOnboardingTeamsComponent } from "./company-onboarding-teams/company-onboarding-teams.component";
import { CompanyOnboardingDocumentsComponent } from "./company-onboarding-documents/company-onboarding-documents.component";
import { KissSelectModule, KissTableModule } from "@kiss/components/common";
import { SharedDirectivesModule } from "app/shared/directives/shared-directives.module";
import { CompanyOnboardingSuccessComponent } from "./company-onboarding-success/company-onboarding-success.component";
import { CompanyOnboardingService } from "./company-onboarding.service";
import { CompanyTeamFormModule } from "app/shared/components/company-team-form/company-team-form.module";
import { ImageUploadModule } from "app/shared/components/image-upload/image-upload.module";
import { PromptDialogModule } from "app/shared/components/prompt-dialog/prompt-dialog.module";
import { CompanyGuard } from "app/shared/guards/company.guard";
import { FilesUploadModule } from "../../shared/components/files-upload/files-upload.module";
import { KissTooltipModule } from "@kiss/directives";
import { CanDeactivateGuard } from "./can-deactivate.guard";

const routes: Routes = [
  {
    path: "onboarding/:id",
    component: CompanyOnboardingComponent,
    canMatch: [AuthGuard],
    canActivate: [RoleGuard, CompanyGuard],
    canDeactivate: [CanDeactivateGuard],
    resolve: {
      data: CompanyOnboardingService
    },
    data: {
      allowedRoles: [RoleEnum.CONSULTANT_ADMIN],
      layoutConfig: {
        layoutType: "vertical-classic",
        verticalClassicConfig: {
          navigationSidebar: {
            enabled: false
          },
          toolbar: {
            enabled: false
          }
        }
      },
      pageTitle: ({ snapshot }) => {
        const companyName = snapshot.data?.data?.name || "Company";
        return `Onboarding | ${companyName}`;
      }
    }
  }
];

@NgModule({
  declarations: [
    CompanyOnboardingComponent,
    CompanyOnboardingValuesComponent,
    CompanyOnboardingStrategiesComponent,
    CompanyOnboardingLogoColorsComponent,
    CompanyOnboardingKickOffComponent,
    CompanyOnboardingSidebarComponent,
    CompanyOnboardingTeamsComponent,
    CompanyOnboardingDocumentsComponent,
    CompanyOnboardingSuccessComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CompanyTeamFormModule,
    KissSelectModule,
    SharedDirectivesModule,
    KissTableModule,
    ImageUploadModule,
    PromptDialogModule,
    FilesUploadModule,
    KissTooltipModule
  ],
  providers: [CompanyOnboardingService]
})
export class CompanyOnboardingModule {}
