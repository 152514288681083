export enum DocumentIconColorEnum {
  // Images
  JPG = "#de5996", // Pinkish for creativity
  JPEG = "#de5996",
  PNG = "#de5996",
  SVG = "#de5996",
  GIF = "#de5996",
  EPS = "#de5996",
  CAD = "#de5996",
  BMP = "#de5996",
  TIFF = "#de5996",
  WEBP = "#de5996",

  // Video
  MP4 = "#ec9fc2", // Light purple for media
  MOV = "#ec9fc2",
  AVI = "#ec9fc2",
  MKV = "#ec9fc2",
  FLV = "#ec9fc2",
  WMV = "#ec9fc2",

  // Audio
  MP3 = "#e8a530", // Orange for sound waves
  WAV = "#e8a530",
  FLAC = "#e8a530",
  AAC = "#e8a530",
  OGG = "#e8a530",

  // Text
  DOC = "#0066cc", // Blue for Microsoft Word
  DOCX = "#0066cc",
  PDF = "#FF0000", // Red for Adobe PDF
  XML = "#ff9900", // Orange for markup
  CSV = "#33cc33", // Green for data
  TXT = "#808080", // Grey for plain text
  RTF = "#4a4091", // Purple for rich text
  MD = "#000000", // Black for markdown

  // Tables
  XLS = "#207245", // Green for Excel
  XLSX = "#207245",
  ODS = "#73b234", // Light green for open document

  // Presentations
  PPT = "#d24726", // Orange for PowerPoint
  PPTX = "#d24726",
  ODP = "#c91d25", // Red for open document presentations

  // Other
  ZIP = "#6960a4", // Purple for compression
  RAR = "#6960a4",
  TAR = "#6960a4",
  GZ = "#6960a4",
  EXE = "#000000", // Black for executables
  ISO = "#6960a4" // Purple for disk images
}
