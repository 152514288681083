import { NgModule } from "@angular/core";
import { DndDirective } from "./dragDrop.directive";
import { IconFallbackDirective } from "./iconFallback.directive";
import { MultiClickDirective } from "./multi-click.directive";

@NgModule({
  declarations: [DndDirective, IconFallbackDirective, MultiClickDirective],
  imports: [],
  exports: [IconFallbackDirective, MultiClickDirective]
})
export class SharedDirectivesModule {}
