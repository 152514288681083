import { Component, Input, Output } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-company-onboarding-sidebar",
  templateUrl: "./company-onboarding-sidebar.component.html",
  styleUrl: "./company-onboarding-sidebar.component.scss"
})
export class CompanyOnboardingSidebarComponent {
  @Input() steps = [];
  @Input() activeStep = 0;
  @Output() onStepClick = new Subject<number>();

  onNavigateToSection(step: any): void {
    this.onStepClick.next(step.id);
  }
}
