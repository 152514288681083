import { ChangeDetectionStrategy, Component, Input, TemplateRef, Type, ViewEncapsulation } from "@angular/core";
import { KissOverlay } from "../kiss-overlay";
import { KissOverlayRef } from "../kiss-overlay-ref";

@Component({
  selector: "kiss-overlay-item",
  templateUrl: "./kiss-overlay-item.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "kiss-overlay__item"
  }
})
export class KissOverlayItemComponent {
  overlayRef: KissOverlayRef;
  template: any;
  @Input() set overlayData(value: KissOverlay) {
    this.overlayRef = value?.ref;
    this.template = value?.template;
  }
}
