import { gql } from "apollo-angular";
import { USER_FRAGMENT } from "../../fragments/user.fragment";
import { COMPANY_FRAGMENT } from "../../fragments/company.fragment";
import { TEAM_FRAGMENT } from "../../fragments/team.fragment";
import { GROUP_FRAGMENT } from "../../fragments/group.fragment";

export const GET_ME = gql`
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  ${TEAM_FRAGMENT}
  ${GROUP_FRAGMENT}
  query GetMeAll {
    getMe {
      ...UserFragment
      companyAdminOf {
        ...CompanyFragment
      }
      companyAdminOfCompanyId
      consultantAdminCompany {
        company {
          ...CompanyFragment
        }
      }
      groupCultureLeads {
        groupId
        userId
        group {
          ...GroupFragment
        }
      }
      teamCultureLeads {
        teamId
        team {
          ...TeamFragment
        }
      }
      companyCultureLeads {
        companyId
        company {
          ...CompanyFragment
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($userId: Float!, $updateUserInput: UpdateUserInput!) {
    updateUser(userId: $userId, updateUserInput: $updateUserInput) {
      id
      email
      name
      role
      status
      consultantAdminCompany {
        company {
          name
          id
        }
        companyId
      }
    }
  }
`;
