import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Apollo } from "apollo-angular";
import { REMOVE_TEAM_GROUP } from "app/shared/gql/operations/company/company-team.operations";
import { TeamModel } from "app/shared/models/company/team.model";
import { UserModel } from "app/shared/models/user/user.model";
import { PromptDialogService } from "../prompt-dialog/prompt-dialog.service";

@Injectable()
export class CompanyTeamFormService {
  constructor(
    private _fb: FormBuilder,
    private _apollo: Apollo,
    private _promptService: PromptDialogService
  ) {}

  createForm({ data, companyLeads, companyId }: { companyLeads: UserModel[]; companyId: string; data?: TeamModel }) {
    const cultureLeadValues = this._getFilteredLeads(companyLeads, data?.cultureLeads);

    return this._fb.group({
      id: [data?.id || null],
      companyId: [companyId || null],
      cultureLeadIds: [cultureLeadValues || []],
      name: [data?.name || null, [Validators.required]],
      groups: this._fb.array(data?.groups?.map((item: any) => this.onAddGroup({ data: item, companyLeads: companyLeads })) || [])
    });
  }

  onAddGroup({ data, companyLeads }: { companyLeads: UserModel[]; data?: TeamModel["groups"][0] }) {
    const cultureLeadValues = this._getFilteredLeads(companyLeads, data?.groupCultureLeads);

    return this._fb.group({
      id: [data?.id || null],
      groupCultureLeadIds: [cultureLeadValues || []],
      name: [data?.name || null]
    });
  }

  onRemoveGroup(body: any) {
    const id = +body.id;

    return this._apollo.mutate({
      mutation: REMOVE_TEAM_GROUP,
      variables: {
        groupId: id
      }
    });
  }

  promptGroupDelete(data) {
    return this._promptService.open({
      title: "Delete Group",
      message: `Do you want to delete the <span class="text-bold">${data.group.name}</span> in <span class="text-bold">${data.teamName}</span>. If you do this, it will be deleted from the system.`,
      confirmBtn: "Delete",
      rejectBtn: "Cancel",
      promptType: "delete-dialog"
    });
  }

  private _getFilteredLeads(companyLeads: UserModel[], list: any[]) {
    const filteredCultureLeads = list?.map((item: any) => item?.user?.id) || [];
    return companyLeads.filter((item: UserModel) => filteredCultureLeads.includes(item.id));
  }

  formatTeamResponse(team: any, settings?: { isEdit: boolean }): any {
    if (settings?.isEdit) {
      team.id = +team.id;
      delete team.companyId;
    } else {
      delete team.id;
      team.companyId = +team.companyId || null;
    }

    team.cultureLeadIds = team.cultureLeadIds.map((item: UserModel) => +item.id) || [];

    team.groups = team.groups.map((group) => {
      if (settings?.isEdit) {
        group.id = +group.id;
      } else {
        delete group.id;
      }
      group.groupCultureLeadIds = group.groupCultureLeadIds.map((item: UserModel) => +item.id) || [];
      return group;
    });

    return team;
  }
}
