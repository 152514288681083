import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavigationCompanyComponent } from "./navigation-company.component";
import { KissMenuModule } from "@kiss/components/common";
import { CompanyService } from "app/shared/services/company/company.service";
import { CompanyImageComponent } from "../company-image/company-image.component";

@NgModule({
  declarations: [NavigationCompanyComponent],
  imports: [CommonModule, KissMenuModule, CompanyImageComponent],
  exports: [NavigationCompanyComponent],
  providers: [CompanyService]
})
export class NavigationCompanyModule {}
