<div class="custom-toast-content" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="custom-toast-content__header">
    <div class="custom-toast-content__icon">
      <i class="fas fa-check" *ngIf="isSuccess"></i>
      <i class="fas fa-exclamation" *ngIf="isWarning"></i>
      <i class="fas fa-info" *ngIf="isInfo"></i>
      <i class="fas fa-times" *ngIf="isError"></i>
    </div>

    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title" class="title">
      {{ title }}
    </div>

    <div *ngIf="!title" [class]="options.titleClass" [attr.aria-label]="title" class="title">
      <ng-container *ngIf="isSuccess">Success</ng-container>
      <ng-container *ngIf="isInfo">Info</ng-container>
      <ng-container *ngIf="isError">Error</ng-container>
      <ng-container *ngIf="isWarning">Warning</ng-container>
    </div>

    <button type="button" class="close-btn" (click)="remove()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="custom-toast-content__body">
    <ng-container *ngIf="message">
      <div *ngIf="options.enableHtml" role="alert" [class]="options.messageClass" [innerHTML]="message"></div>
      <div *ngIf="!options.enableHtml" role="alert" [class]="options.messageClass" [attr.aria-label]="message" class="message">
        {{ message }}
      </div>
    </ng-container>
  </div>
  <div class="custom-toast-content__footer" *ngIf="actionBtn?.enabled">
    <button type="button" class="cc-btn cc-btn--sm--wide custom-toast-content__footer__btn" (click)="actionBtn?.callback($event)">
      {{ actionBtn?.text }}
    </button>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
