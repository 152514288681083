export type KissDatepickerTimestamp = {
  hours: number | string;
  minutes: number | string;
  seconds: number | string;
  milliseconds: number | string;
};

export enum KissDatepickerTimestampEnum {
  HOURS = "hours",
  MINUTES = "minutes",
  SECONDS = "seconds",
  MILLISECONDS = "milliseconds"
}
