import { Injectable } from "@angular/core";
import { AuthResponseModel } from "app/shared/models/auth/auth-response.model";
import { from, Observable, of, tap } from "rxjs";
import { Apollo } from "apollo-angular";
import {
  LOGIN,
  SIGN_OUT,
  FORGOT_PASSWORD,
  CONFIRM_PASSWORD,
  TEMP_LOGIN,
  REFRESH_TOKEN
} from "app/shared/gql/operations/auth/auth.operations";
import { UserDataService } from "./user-data.service";
import { StorageConstants } from "app/shared/constants/storage.constants";
import { CompanyService } from "../company/company.service";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private tokenExpirationTimer: any;

  constructor(
    private apollo: Apollo,
    private _user: UserDataService,
    private _companyService: CompanyService
  ) {}

  /**
   * Initalize  user data and refresh token
   */
  public initAuth() {
    if (this.isAuthenticated()) {
      this._user.userData.next(this._user.getUserData());
      this.initRefreshToken();
    }
  }

  public initRefreshToken() {
    const FIVE_MIN = 5 * 60000;
    const expirationDate = this.getAccessTokenExpiration();
    const refreshTokenDate = new Date(expirationDate - FIVE_MIN);
    const refreshTokenDuration = refreshTokenDate.getTime() - new Date().getTime();

    if (this.tokenExpirationTimer) clearTimeout(this.tokenExpirationTimer);
    this.tokenExpirationTimer = setTimeout(() => {
      this.refreshToken().subscribe();
    }, refreshTokenDuration);
  }

  setAuthData(data: AuthResponseModel) {
    localStorage.setItem(StorageConstants.STORAGE_ACCESS_TOKEN, JSON.stringify(data.accessToken));
    localStorage.setItem(StorageConstants.STORAGE_REFRESH_TOKEN, JSON.stringify(data.refreshToken));

    this._user.setUserData(data.user);
    this.initRefreshToken();
  }

  async clearAuthData() {
    localStorage.removeItem(StorageConstants.STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(StorageConstants.STORAGE_REFRESH_TOKEN);

    this._user.clearUser();
    await this.apollo.client.clearStore();
    this._companyService.clearActiveCompany();

    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }

    this.tokenExpirationTimer = null;
  }

  login(userCredentials: { email: string; password: string }) {
    return this.apollo
      .mutate({
        mutation: LOGIN,
        variables: {
          authLoginUserInput: {
            email: userCredentials.email,
            password: userCredentials.password
          }
        }
      })
      .pipe(
        tap((response: any) => {
          this.setAuthData(response?.data?.login);
        })
      );
  }

  logout(): Observable<any> {
    //logged in
    const userData = this._user.getUserData();

    if (this.isAuthenticated() && userData) {
      return this.apollo
        .mutate({
          mutation: SIGN_OUT,
          variables: {
            authSignOutUserInput: {
              email: userData.email
            }
          }
        })
        .pipe(
          tap(() => {
            this.clearAuthData();
          })
        );
    } else {
      //not logged in
      this.clearAuthData();
      return of(true);
    }
  }

  refreshToken() {
    const userData = this._user.getUserData();
    const refreshToken = this.getRefreshToken();

    return this.apollo
      .mutate({
        mutation: REFRESH_TOKEN,
        variables: {
          authRefreshTokenInput: {
            email: userData.email,
            refreshToken: refreshToken
          }
        }
      })
      .pipe(
        tap((res: any) => {
          this.setAuthData(res.data.refresh);
        })
      );
  }

  forgotPassword(email: string) {
    return this.apollo.mutate({
      mutation: FORGOT_PASSWORD,
      variables: {
        authForgotPasswordUserInput: {
          email
        }
      }
    });
  }

  confirmPassword(data: any) {
    return this.apollo.mutate({
      mutation: CONFIRM_PASSWORD,
      variables: {
        authConfirmPasswordUserDto: {
          email: data.email,
          confirmationCode: data.confirmationCode,
          newPassword: data.newPassword,
          repeatNewPassword: data.passwordConfirm
        }
      }
    });
  }

  tempLogin(data: any) {
    return this.apollo.mutate({
      mutation: TEMP_LOGIN,
      variables: {
        authTempLoginUserInput: {
          email: data.email,
          password: data.newPassword,
          passwordConfirm: data.passwordConfirm,
          tempPassword: data.tempPassword
        }
      }
    });
  }

  isAuthenticated(): boolean {
    return this.isValidToken();
  }

  isValidToken(): boolean {
    const expirationDate = this.getAccessTokenExpiration();

    if (!expirationDate) {
      return false;
    }

    const currentDate = new Date().getTime();

    return expirationDate > currentDate;
  }

  getRefreshToken(): string {
    const value = localStorage.getItem(StorageConstants.STORAGE_REFRESH_TOKEN);

    return value ? JSON.parse(value) : undefined;
  }

  getAccessTokenExpiration(): number {
    const token = this.getAccessTokenToken();

    if (!token) {
      return undefined;
    }

    const decode = JSON.parse(atob(token.split(".")[1]));
    const expirationDate = decode.exp * 1000;

    return expirationDate;
  }

  getAccessTokenToken(): string {
    const value = localStorage.getItem(StorageConstants.STORAGE_ACCESS_TOKEN);

    return value ? JSON.parse(value) : undefined;
  }
}
