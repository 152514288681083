import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-company-image",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./company-image.component.html"
})
export class CompanyImageComponent {
  @Input() image: string;
  @Input() companyName: string;
}
