import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KissBackdropService } from '../../../services/kiss-backdrop/kiss-backdrop.service';
import { KissSidebarService } from './services/kiss-sidebar.service';

@Component({
  selector: 'kiss-sidebar',
  template: '<ng-content></ng-content>',
  providers: [KissBackdropService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'kiss-sidebar',
  },
})
export class KissSidebarComponent implements OnInit, OnDestroy {
  //INPUTS

  // Name
  @Input()
  name: string;

  // backdrop
  @Input()
  backdrop: boolean;

  // Position
  @Input()
  position: 'left' | 'right' = 'left';

  // Locked open

  @Input()
  @HostBinding('class.locked-open')
  lockedOpen: boolean;

  // Open
  @Input()
  @HostBinding('class.open')
  set open(value) {
    this._open = value;
    this._createBackdrop();
  }

  get open() {
    return this._open;
  }

  // Folded
  @Input()
  @HostBinding('class.folded')
  set folded(value) {
    this._setFolded(value);
  }

  get folded() {
    return this._folded;
  }

  @Input()
  foldedWidth: number = 4;
  //EVENTS

  @Input()
  unit: string = 'rem';

  @Input()
  openWidth: number;

  // Folded changed
  @Output()
  foldedChanged: EventEmitter<boolean>;

  // Opened changed
  @Output()
  openedChanged: EventEmitter<boolean>;

  //PRIVATE
  private _unsubscribeAll: Subject<void>;
  private _folded: boolean;
  private _open: boolean;

  constructor(
    private _renderer: Renderer2,
    private _elRef: ElementRef,
    private _kissSidebarService: KissSidebarService,
    private _kissBackdropService: KissBackdropService,
    private _cdr: ChangeDetectorRef
  ) {
    //SET DEFAULTS
    this._unsubscribeAll = new Subject();

    this.foldedChanged = new EventEmitter();
    this.openedChanged = new EventEmitter();
    this._folded = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._kissSidebarService.register(this.name, this);

    this._setupPosition();
    this._initOpenWidth();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.folded = false;

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    // Unregister the sidebar
    this._kissSidebarService.unregister(this.name);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setup the sidebar position
   *
   * @private
   */
  private _setupPosition(): void {
    // Add the correct class name to the sidebar
    // element depending on the position attribute
    if (this.position === 'left' || !this.position) {
      this._renderer.addClass(this._elRef.nativeElement, 'left-positioned');
    }

    if (this.position === 'right') {
      this._renderer.addClass(this._elRef.nativeElement, 'right-positioned');
    }

    this._cdr.markForCheck();
  }

  private _createBackdrop() {
    if (!this._open || !this.backdrop) {
      return;
    }

    this._kissBackdropService.createBackdrop();

    // Add an event listener to the overlay
    this._kissBackdropService
      .onBackdropClick()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.closeSidebar();
      });

    this._cdr.markForCheck();
  }

  /**
   * Hide the backdrop
   *
   * @private
   */
  private _removeBackdrop(): void {
    if (!this.backdrop) {
      return;
    }

    this._kissBackdropService.removeBackdrop();
  }

  private _setFolded(value) {
    this._folded = value;

    // Return if the sidebar is closed
    if (!this._open) {
      return;
    }

    // Programmatically add/remove padding to the element
    // that comes after or before based on the position

    // If folded...
    if (this._folded) {
      // Set the folded width
      const styleValue = this.foldedWidth + this.unit;
      this._renderer.setStyle(this._elRef.nativeElement, 'width', styleValue);
      this._renderer.setStyle(this._elRef.nativeElement, 'min-width', styleValue);
      this._renderer.setStyle(this._elRef.nativeElement, 'max-width', styleValue);
    } else {
      // Remove the folded width
      this._renderer.removeStyle(this._elRef.nativeElement, 'width');
      this._renderer.removeStyle(this._elRef.nativeElement, 'min-width');
      this._renderer.removeStyle(this._elRef.nativeElement, 'max-width');
    }

    this._cdr.markForCheck();

    // Emit the 'foldedChanged' event
    this.foldedChanged.emit(this.folded);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Close the sidebar
   */
  closeSidebar(): void {
    if (!this._open || this.lockedOpen) {
      return;
    }

    // Hide the backdrop
    this._removeBackdrop();

    // Set the opened status
    this.open = false;

    // Emit the 'openedChanged' event
    this.openedChanged.emit(this._open);

    // Mark for check
    this._cdr.markForCheck();
  }

  /**
   * Open the sidebar
   */
  openSidebar(): void {
    if (this._open || this.lockedOpen) {
      return;
    }

    // Show the backdrop
    this._createBackdrop();

    // Set the opened status
    this.open = true;

    // Emit the 'openedChanged' event
    this.openedChanged.emit(this._open);

    // Mark for check
    this._cdr.markForCheck();
  }

  /**
   * Toggle open/close the sidebar
   */
  toggleOpen(): void {
    this._open ? this.closeSidebar() : this.openSidebar();
  }

  /**
   * Fold the sidebar permanently
   */
  fold(): void {
    // Only work if the sidebar is not folded
    if (this.folded) {
      return;
    }

    // Fold
    this.folded = true;

    // Mark for check
    this._cdr.markForCheck();
  }

  /**
   * Unfold the sidebar permanently
   */
  unfold(): void {
    // Only work if the sidebar is folded
    if (!this.folded) {
      return;
    }

    // Unfold
    this.folded = false;

    // Mark for check
    this._cdr.markForCheck();
  }

  /**
   * Toggle the sidebar fold/unfold permanently
   */
  toggleFold(): void {
    this.folded ? this.unfold() : this.fold();
  }

  private _initOpenWidth() {
    this._disableAnimation();

    // If open
    if (this.openWidth) {
      const styleValue = this.openWidth + this.unit;
      this._renderer.setStyle(this._elRef.nativeElement, 'width', '100%');
      this._renderer.setStyle(this._elRef.nativeElement, 'max-width', styleValue);
    }

    setTimeout(() => {
      this._enableAnimation();
    });
  }

  private _enableAnimation() {
    this._renderer.addClass(this._elRef.nativeElement, 'animate');
  }

  private _disableAnimation() {
    this._renderer.removeClass(this._elRef.nativeElement, 'animate');
  }
}
