import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavigationUserComponent } from "./navigation-user.component";
import { KissUserAvatarModule } from "@kiss/components/common";

@NgModule({
  declarations: [NavigationUserComponent],
  imports: [CommonModule, KissUserAvatarModule],
  exports: [NavigationUserComponent]
})
export class NavigationUserModule {}
