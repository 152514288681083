import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { KissContentModule } from "../common";
import { KissLayoutComponent } from "./kiss-layout.component";
import { LayoutsModule } from "./layouts/layouts.module";
import { KissLayoutConfigService } from "./services/kiss-layout-config.service";

@NgModule({
  declarations: [KissLayoutComponent],
  imports: [CommonModule, LayoutsModule, KissContentModule],
  exports: [KissLayoutComponent],
  providers: [KissLayoutConfigService]
})
export class KissLayoutModule {}
