import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { KissLoadingBarService } from "@kiss/components/common";
import { TOASTR_AUTH_CONFIG } from "app/shared/constants/custom-toast-config";
import { RoutesConstants } from "app/shared/constants/routes.constrants";
import { AuthService } from "app/shared/services/auth/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-confirm-account",
  templateUrl: "./confirm-account.component.html"
})
export class ConfirmAccountComponent {
  confirmAccountForm: FormGroup;
  error: string;
  showPass = false;
  showConfirmPass = false;
  requestPending = false;

  queryParams: Params;

  //success page
  requestSubmitted = false;
  requestSubmitMsg = "";

  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private _loadingBar: KissLoadingBarService,
    private _toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initialise();
  }

  initialise() {
    this.queryParams = Object.assign({}, this.activatedRoute.snapshot.queryParams);

    if (!this.queryParams.code || !this.queryParams.email) {
      this.router.navigate([RoutesConstants.BASE_AUTH_REDIRECT]);
    }

    this.confirmAccountForm = new FormGroup({
      email: new FormControl(this.queryParams.email, [Validators.required, Validators.email]),
      tempPassword: new FormControl(this.queryParams.code, [Validators.required]),
      newPassword: new FormControl(null, [Validators.required, Validators.minLength(10)]),
      passwordConfirm: new FormControl(null, [Validators.required, Validators.minLength(10), this.confirmPassword.bind(this)])
    });
  }

  onTogglePassword() {
    this.showPass = !this.showPass;
  }

  onToggleConfirmPassword() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  confirmPassword(control: FormControl): { [s: string]: boolean } {
    if (this.confirmAccountForm && this.confirmAccountForm.value.newPassword !== control.value) {
      return { passwordMismatch: true };
    }
    return null;
  }

  onInputChange() {
    this.confirmAccountForm.controls["newPassword"].updateValueAndValidity();
    this.confirmAccountForm.controls["passwordConfirm"].updateValueAndValidity();
    this.error = null;
  }

  onSubmit() {
    if (!this.confirmAccountForm.valid) return;
    this.error = null;
    this._loadingBar.show();
    this.authService
      .tempLogin(this.confirmAccountForm.value)
      .subscribe({
        next: (res: any) => {
          if (res?.data.tempLogin.status === "success") {
            this.confirmAccountForm.reset();
            // this.authService.attachAuth(res);
            this.router.navigate(["/login"]);
            this._toastr.success(
              "Congratulations! Your account has been successfully confirmed. You can now log in using your email and password.",
              undefined,
              TOASTR_AUTH_CONFIG
            );
          }
        },
        error: (err) => {
          this.error = err;
          if (err.message === "Verification link may have expired. Please try again.") {
            this.requestSubmitted = true;
            this.requestSubmitMsg = err.message;
          } else {
            this._toastr.error(err.message, undefined, TOASTR_AUTH_CONFIG);
          }
        }
      })
      .add(() => {
        this._loadingBar.hide();
      });
  }
}
