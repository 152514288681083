import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { KissSetPositionModule } from "@kiss/directives";
import { KissOverlayModule } from "../public-api";
import { KissDatepickerComponent } from "./kiss-datepicker.component";
import { KissDatepickerDayComponent } from "./kiss-datepicker-day/kiss-datepicker-day.component";
import { KissDatepickerMonthComponent } from "./kiss-datepicker-month/kiss-datepicker-month.component";
import { KissDatepickerYearComponent } from "./kiss-datepicker-year/kiss-datepicker-year.component";
import { KissIconPrePostModule } from "@kiss/directives/kiss-icon-pre-post";
import { KissDatepickerTimeComponent } from "./kiss-datepicker-time/kiss-datepicker-time.component";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    KissDatepickerComponent,
    KissDatepickerDayComponent,
    KissDatepickerMonthComponent,
    KissDatepickerYearComponent,
    KissDatepickerTimeComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, KissOverlayModule, KissSetPositionModule, ReactiveFormsModule],
  exports: [KissDatepickerComponent, KissIconPrePostModule],
  providers: [DatePipe]
})
export class KissDatepickerModule {}
