<div class="company-team-form" [formGroup]="form" *ngIf="form">
  <div class="company-team-form__header">
    <div class="company-team-form__title">General Team Settings</div>
    <button class="cc-btn company-team-form__delete-action" (click)="onClickDelete($event)" *ngIf="!hideDelete">
      <i class="fa-regular fa-trash"></i>
      Delete Team
    </button>
  </div>
  <div class="company-team-form__body">
    <div class="company-team-form__row">
      <div class="form-item-container">
        <div class="form-label">Team Name *</div>
        <div class="form-field-wrapper">
          <div class="cc-input-container">
            <input type="text" class="cc-input" placeholder="Choose a Team Name" formControlName="name" />
          </div>
        </div>
      </div>

      <div class="form-item-container" *ngIf="!isOnboarding">
        <div class="form-label">Dept/Team sponsor(s)</div>
        <div class="form-field-wrapper">
          <kiss-select
            class="cc-select"
            dropdownClass="cc-select add-company__cc-select"
            placeholder="Choose the Dept/Team sponsor(s)"
            multiple
            formControlName="cultureLeadIds"
            [toggleAll]="false"
            emptySelectText="There are no active Team facilitators created yet. Go to Company users to add team facilitators"
          >
            <kiss-select-option class="cc-option" *ngFor="let item of companyLeads" [value]="item">
              <div class="cc-option__content">{{ item.name }}</div>
            </kiss-select-option>
          </kiss-select>
        </div>
      </div>
    </div>
    <div class="company-team-form__group-title">
      <div class="company-team-form__title company-team-form__group-title__text">Add Groups</div>
      <div class="content-hover-info" *ngIf="showInfoText">
        <i
          class="fa-circle-info fa-regular content-hover-info__icon content-hover-info__icon--solid-on-hover"
          kissTooltip="Add Groups if you are planning to split your Team up into sub-groups e.g. to run the workshops. You can add this information later if this is not yet decided."
          kissTooltipType="html"
          kissTooltipTimeout="100"
        ></i>
      </div>
    </div>
    <div class="company-team-form__group-wrapper">
      <ng-container formArrayName="groups">
        <ng-container *ngFor="let item of form?.get('groups')['controls']; let i = index" [formGroupName]="i">
          <div class="card-panel">
            <div class="card-panel__header company-team-form__group">
              <app-editable-text-control [formControl]="item.get('name')" placeholder="Choose Group Name"></app-editable-text-control>

              <button class="cc-btn cc-btn__icon company-team-form__group__delete" (click)="onRemoveGroup(i)">
                <i class="fa-light fa-trash"></i>
              </button>
            </div>
            <div class="card-panel__body" *ngIf="!isOnboarding">
              <div class="company-team-form__group__list">
                <div class="company-team-form__group__list__title">
                  <div class="company-team-form__group__list__title__text">Team Facilitator(s):</div>
                  <div class="content-hover-info" *ngIf="showInfoText">
                    <i
                      class="fa-circle-info fa-regular content-hover-info__icon content-hover-info__icon--solid-on-hover"
                      kissTooltip="This is the person who will be facilitating the workshop and Focus areas for this Group. You can add this information later if this is not yet decided."
                      kissTooltipType="html"
                      kissTooltipTimeout="100"
                    ></i>
                  </div>
                </div>
                <div class="company-team-form__group__list__content">
                  <div class="company-team-form__group__list__item" *ngIf="!item.get('groupCultureLeadIds')?.value?.length">N/A</div>
                  <div class="company-team-form__group__list__item" *ngFor="let lead of item.get('groupCultureLeadIds')?.value">
                    {{ lead.name }}
                    <button class="cc-btn cc-btn__icon" (click)="onRemoveLead(item, lead.id)">
                      <i class="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
              </div>
              <kiss-select
                class="cc-select"
                dropdownClass="cc-select"
                placeholder="Add the Team Facilitator(s) for this group"
                formControlName="groupCultureLeadIds"
                multiple
                [toggleAll]="false"
              >
                <kiss-select-option class="cc-option" *ngFor="let item of companyLeads" [value]="item">
                  {{ item.name }}
                </kiss-select-option>
              </kiss-select>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="company-team-form__footer">
    <button class="cc-btn cc-btn__icon add-group" (click)="onAddNew()"><i class="fa-regular fa-plus"></i> add new group</button>
  </div>
</div>
