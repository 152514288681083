import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "id"
})
export class IdPipe implements PipeTransform {
  /**
   *
   * @param value string
   * @returns
   */
  transform(value: number): string {
    let temp = (+value).toString();

    while (temp.length < 4) {
      temp = "0" + temp;
    }

    return temp;
  }
}
