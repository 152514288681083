import { NgModule } from '@angular/core';
import { KissTrapScrollModule } from '@kiss/directives/trap-scroll/trap-scroll.module';
import { KissSetPositionModule } from '@kiss/directives/kiss-set-position/kiss-set-position.module';
import { KissAutofocusModule } from '@kiss/directives/kiss-autofocus/kiss-autofocus.module';
import { KissLazyImgModule } from './kiss-lazy-img';
import { KissElementOverflowModule } from './kiss-element-overflow/kiss-element-overflow.module';
import { KissIconPrePostModule } from './kiss-icon-pre-post';
import { KissTooltipModule } from './kiss-tooltip/kiss-tooltip.module';
import { KissNoScrollModule } from './kiss-no-scroll';

@NgModule({
  imports: [
    KissTrapScrollModule,
    KissSetPositionModule,
    KissAutofocusModule,
    KissLazyImgModule,
    KissElementOverflowModule,
    KissIconPrePostModule,
    KissTooltipModule,
    KissNoScrollModule,
  ],
  exports: [
    KissTrapScrollModule,
    KissSetPositionModule,
    KissAutofocusModule,
    KissLazyImgModule,
    KissElementOverflowModule,
    KissIconPrePostModule,
    KissTooltipModule,
    KissNoScrollModule,
  ],
})
export class KissDirectivesModule {}
