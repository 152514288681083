import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { KissDatepickerMonthSettings } from '../kiss-datepicker-settings/kiss-datepicker-month-settings';

@Component({
  selector: 'kiss-datepicker-month',
  templateUrl: './kiss-datepicker-month.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'kiss-datepicker__month',
  },
})
export class KissDatepickerMonthComponent {
  monthValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  months: Date[] = [];
  currentMonth: number;

  @Input('kissDatepickerMonthSettings') monthSettings: KissDatepickerMonthSettings;

  private _viewDate: Date;
  @Input() set viewDate(value: any) {
    this._viewDate = value;
    this.currentMonth = this._viewDate.getMonth();
    this._generateMonths();
  }

  get viewDate() {
    return this._viewDate;
  }

  @Output() onChange = new EventEmitter();

  private _generateMonths() {
    this.months = this.monthValues.map((month) => {
      const tmpViewDate = new Date(this.viewDate.getTime()).setDate(1);
      const tmpViewHours = new Date(tmpViewDate).setHours(0, 0, 0, 0);
      const tmpDate = new Date(tmpViewHours);
      const newMonth = tmpDate.setMonth(month);

      return new Date(newMonth);
    });
  }

  onMonthChange(event, month: Date) {
    event.stopPropagation();
    this.onChange.next(month);
  }
}
