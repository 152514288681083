import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ConfirmAccountComponent } from "./confirm-account.component";
import { UnAuthGuard } from "app/shared/guards/unauth.guard";
import { KissLoaderModule } from "@kiss/components/common/kiss-loader/kiss-loader.module";
import { KissTooltipModule } from "@kiss/directives";

const routes: Routes = [
  {
    path: "set-password",
    component: ConfirmAccountComponent,
    canMatch: [UnAuthGuard],
    data: {
      layoutConfig: {
        layoutType: "vertical-classic",
        verticalClassicConfig: {
          navigationSidebar: {
            enabled: false
          },
          toolbar: {
            enabled: false
          }
        }
      },
      pageTitle: "Set New Password"
    }
  }
];

@NgModule({
  declarations: [ConfirmAccountComponent],
  imports: [CommonModule, FormsModule, RouterModule.forChild(routes), ReactiveFormsModule, KissLoaderModule, KissTooltipModule],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class ConfirmAccountModule {}
