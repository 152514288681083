import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "fileSize"
})
export class FileSizePipe implements PipeTransform {
  private _units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  /**
   *
   * @param value string
   * @param settings EnumToValueType
   * @returns
   */
  transform(value: string): string {
    return this._convertBytes(value);
  }

  private _convertBytes(x: any): string {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + this._units[l];
  }
}
