import { Component, InjectionToken, Input, OnInit, Optional, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { KissColumnDefDirective } from '../kiss-table/kiss-column/kiss-column.directive';

export const KISS_SORT_TAG = new InjectionToken<KissSortTagComponent>('kiss-table-sort-tag');

@Component({
  selector: 'kiss-sort-tag',
  template: `
    <i
      class="kiss-sort-tag__arrow kiss-sort-tag__arrow--asc"
      [ngClass]="{
        'kiss-sort-tag__arrow--asc--active': activeDirection === 'asc'
      }"
      (click)="onChange('asc')"
    ></i>
    <i
      class="kiss-sort-tag__arrow kiss-sort-tag__arrow--desc"
      [ngClass]="{
        'kiss-sort-tag__arrow--desc--active': activeDirection === 'desc'
      }"
      (click)="onChange('desc')"
    ></i>
  `,
  host: {
    class: 'kiss-sort-tag',
  },
  providers: [
    {
      provide: KISS_SORT_TAG,
      useExisting: KissSortTagComponent,
    },
  ],
})
export class KissSortTagComponent implements OnInit {
  @Input() id: string;
  @Output() onStateChange = new Subject<KissSortTagComponent>();

  activeDirection: 'asc' | 'desc' | undefined;
  constructor(@Optional() private _columnDef: KissColumnDefDirective) {}

  ngOnInit(): void {
    if (!this.id && this._columnDef) {
      this.id = this._columnDef.name;
    }
  }

  onChange(value: 'asc' | 'desc') {
    if (this.activeDirection === value) {
      this.activeDirection = undefined;
    } else {
      this.activeDirection = value;
    }

    this.onStateChange.next(this);
  }
}
