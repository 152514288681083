<!-- HEADER -->
<div #header class="kiss-datepicker-container" (click)="onDatepickerClick()">
  <div class="kiss-datepicker__icon kiss-datepicker__pre-icon" *ngIf="preIconDirective">
    <ng-template [ngTemplateOutlet]="preIconDirective.templateRef" [ngTemplateOutletContext]="{ open, disabled }"></ng-template>
  </div>
  <div class="kiss-datepicker__label-container">
    <div class="kiss-datepicker__placeholder" *ngIf="placeholder && !label">
      {{ placeholder }}
    </div>
    <!-- LABEL -->
    <span class="kiss-datepicker__label" *ngIf="label">
      {{ label }}
    </span>
    <!-- !LABEL -->
  </div>
  <div class="kiss-datepicker__icon kiss-datepicker__post-icon" *ngIf="postIconDirective">
    <ng-template [ngTemplateOutlet]="postIconDirective.templateRef" [ngTemplateOutletContext]="{ open, disabled }"></ng-template>
  </div>
</div>
<!-- !HEADER -->

<!-- DROPDOWN -->
<ng-template kissOverlay [kissOverlayOpen]="open" (onContainerClick)="onContainerClick()">
  <div
    class="kiss-datepicker__dropdown-container"
    kissSetPosition
    [kissSetPositionParent]="header"
    [handleOverflow]="true"
    [xPosition]="'right'"
    [ngClass]="[dropdownClass || '']"
    #container
  >
    <div class="kiss-datepicker__dropdown" tabindex="0">
      <!-- DROPDOWN HEADER -->
      <div class="kiss-datepicker__dropdown-header">
        <div class="kiss-datepicker__view-info-date">
          <button
            class="kiss-datepicker__btn kiss-datepicker__view-info-date__item kiss-datepicker__view-info-date__month"
            [ngClass]="{ 'kiss-datepicker__view--active': view === viewEnums.MONTH }"
            *ngIf="datepickerSettings.header?.showMonth"
            (click)="onMonthViewClick()"
          >
            <span>{{ viewInfoDate | date: "MMMM" }},</span>
          </button>
          <button
            class="kiss-datepicker__btn kiss-datepicker__view-info-date__item kiss-datepicker__view-info-date__year"
            [ngClass]="{ 'kiss-datepicker__view--active': view === viewEnums.YEAR }"
            *ngIf="datepickerSettings.header?.showYear"
            (click)="onYearViewClick()"
          >
            <span>{{ viewInfoDate | date: "yyyy" }}</span>
          </button>
        </div>
        <div class="kiss-datepicker__actions" *ngIf="view === viewEnums.DAY || view === viewEnums.YEAR">
          <button class="kiss-datepicker__btn kiss-datepicker__actions__prev" (click)="onClickPrev()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 18L8 12L14 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
          <button class="kiss-datepicker__btn kiss-datepicker__actions__next" (click)="onClickNext()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18L15 12L9 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </div>

      <button
        class="kiss-datepicker__btn kiss-datepicker__view-info-date__item kiss-datepicker__view-info-date__time"
        [ngClass]="{ 'kiss-datepicker__view--active': view === viewEnums.TIME }"
        *ngIf="datepickerSettings.header?.showTime"
        (click)="onTimeViewClick()"
      >
        <span *ngIf="selectionMode == selectionModeEnums.DATEPICKER">
          {{ timeSettings.timeLabel[0] | date: datepickerSettings.labelFormats.time }}
        </span>
        <span *ngIf="selectionMode == selectionModeEnums.RANGE">
          {{ timeSettings.timeLabel[0] | date: datepickerSettings.labelFormats.time }} -
          {{ timeSettings.timeLabel[1] | date: datepickerSettings.labelFormats.time }}
        </span>
      </button>
      <!-- !DROPDOWN HEADER -->

      <!--VIEWS -->
      <div class="kiss-datepicker__dropdown-body">
        <div class="kiss-datepicker__view-container" #viewContainerRef>
          <kiss-datepicker-month
            *ngIf="view === viewEnums.MONTH && datepickerSettings.month?.enabled"
            [kissDatepickerMonthSettings]="datepickerSettings.month"
            [viewDate]="viewDate"
            (onChange)="onMonthValueChange($event)"
          ></kiss-datepicker-month>
          <kiss-datepicker-year
            *ngIf="view === viewEnums.YEAR && datepickerSettings.year?.enabled"
            [kissDatepickerYearSettings]="datepickerSettings.year"
            [viewDate]="viewDate"
            [viewInfoDate]="viewInfoDate"
            (onChange)="onYearValueChange($event)"
          ></kiss-datepicker-year>
          <kiss-datepicker-day
            *ngIf="view === viewEnums.DAY && datepickerSettings.day?.enabled"
            [selectionMode]="selectionMode"
            [kissDatepickerDaySettings]="datepickerSettings.day"
            [viewDate]="viewDate"
            [selected]="selected"
            (onChange)="onDateValueChange($event)"
          ></kiss-datepicker-day>
          <kiss-datepicker-time
            *ngIf="view === viewEnums.TIME && datepickerSettings.time?.enabled"
            [kissDatepickerTimeSettings]="datepickerSettings.time"
            [settings]="timeSettings"
            (onChange)="onTimeValueChange($event)"
          ></kiss-datepicker-time>
        </div>
      </div>

      <div class="kiss-datepicker__action-container">
        <!-- Cancel -->
        <!-- <button
          class="kiss-datepicker__btn kiss-datepicker__btn--cancel"
          *ngIf="datepickerSettings.footer?.showCancel"
          (click)="onReset()"
        >
         Reset
        </button> -->
        <!-- !Cancel -->

        <!-- SAVE -->
        <button class="kiss-datepicker__btn kiss-datepicker__btn--save" *ngIf="datepickerSettings.time?.enabled" (click)="onSave()">
          OK
        </button>
        <!-- !SAVE -->
      </div>

      <!-- -VIEWS -->
    </div>
    <div class="kiss-datepicker__tab-trap" tabindex="0" #tabTrap></div>
  </div>
</ng-template>
<!-- !DROPDOWN -->
