<div class="kiss-paginator__results" *ngIf="showResults">
  <ng-container *ngIf="!resultsDirective">
    <span>{{ currentSize }} from {{ total }}</span>
    <select
      class="kiss-paginator__page-size-select"
      *ngIf="pageSizes.length"
      #pageSizeSelect
      (change)="onPageSizeSelect(pageSizeSelect.value)"
    >
      <option *ngFor="let pageOption of pageSizes" [value]="pageOption" [selected]="pageOption === pageSize">
        {{ pageOption }}
      </option>
    </select>
  </ng-container>

  <ng-container
    *ngIf="resultsDirective"
    [ngTemplateOutlet]="resultsDirective.templateRef"
    [ngTemplateOutletContext]="{
      $implicit: currentSize,
      currentSize: currentSize,
      total: total,
      pageSize: pageSize
    }"
  >
  </ng-container>
</div>
<div class="kiss-paginator__counter-container">
  <button class="kiss-paginator__item kiss-paginator__arrow-left" (click)="onFirstClick()" [disabled]="currentPage <= 1">
    <i class="fa fa-chevron-left" *ngIf="!preIconDirective" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"></i>

    <ng-template
      *ngIf="preIconDirective"
      [ngTemplateOutlet]="preIconDirective.templateRef"
      [ngTemplateOutletContext]="{ disabled }"
    ></ng-template>
  </button>

  <button
    *ngFor="let page of visiblePages"
    class="kiss-paginator__item kiss-paginator__number"
    [ngClass]="{ 'kiss-paginator__active': currentPage === page }"
    (click)="onPageClick(page)"
  >
    {{ page }}
  </button>

  <button class="kiss-paginator__item kiss-paginator__arrow-right" (click)="onLastClick()" [disabled]="currentPage >= totalPages">
    <i class="fa fa-chevron-right" *ngIf="!preIconDirective" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"></i>

    <ng-template
      *ngIf="postIconDirective"
      [ngTemplateOutlet]="postIconDirective.templateRef"
      [ngTemplateOutletContext]="{ disabled }"
    ></ng-template>
  </button>
</div>
