import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from "@angular/core";
import { BaseKissCellComponent } from "../base/base-kiss-cell.component";
import { KissTableDefaults } from "../helpers/kiss-table-defaults";
import { KissColumnDefDirective } from "../kiss-column/kiss-column.directive";

@Component({
  selector: "kiss-header-cell",
  template: `
    <ng-content></ng-content>
    <span [class]="resizeHandleClass" *ngIf="resizable"></span>
  `,
  host: {
    class: "kiss-header-cell",
    role: "header-cell"
  },
  changeDetection: ChangeDetectionStrategy.Default
})
export class KissHeaderCellComponent extends BaseKissCellComponent {
  resizeHandleClass = KissTableDefaults.styling.resizeHandle;

  constructor(
    protected override column: KissColumnDefDirective,
    protected override elementRef: ElementRef,
    protected override _renderer: Renderer2
  ) {
    super(column, elementRef, _renderer);
  }
}
