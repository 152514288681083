export enum CompanyColorEnum {
  DEFAULT = "DEFAULT",
  BLACK = "BLACK",
  CHARCOAL = "CHARCOAL",
  ZAFFRE = "ZAFFRE",
  TEAL_GREEN = "TEAL_GREEN",
  MAGENTA = "MAGENTA",
  RUSSET = "RUSSET",
  RED_WINE = "RED_WINE"
}
