<div class="auth-page login-page">
  <div class="auth-form">
    <div class="auth-form__header">
      <img src="assets/company-logo.svg" />
    </div>
    <form class="form-element" name="loginForm" [formGroup]="forgotPassForm" novalidate (ngSubmit)="onSubmit()">
      <div class="form-text">
        <h2>Welcome to</h2>
        <h1>Culture Consultancy</h1>
        <p>Don’t worry! Fill in your e-mail and we’ll send you a reset link.</p>
      </div>
      <div class="inputs-wrapper">
        <div class="form-item-container">
          <label for="email" class="form-label">Email</label>

          <div
            class="cc-input-container"
            [ngClass]="{
              invalid:
                (!forgotPassForm.get('email').valid &&
                  forgotPassForm.get('email').touched &&
                  !forgotPassForm.get('email').hasError('email')) ||
                error
            }"
          >
            <input
              type="email"
              name="email"
              id="email"
              (change)="onInputChange()"
              class="cc-input"
              placeholder="Enter your email for password login"
              formControlName="email"
              required
            />
          </div>
          <div class="error-placeholder">
            <div class="form-info-text error">
              <span
                class="error"
                *ngIf="
                  !forgotPassForm.get('email').valid &&
                  forgotPassForm.get('email').touched &&
                  !forgotPassForm.get('email').hasError('email') &&
                  !error
                "
              >
                The email field is mandatory.
              </span>
              <span
                class="error"
                *ngIf="!forgotPassForm.get('email').hasError('required') && forgotPassForm.get('email').hasError('email') && !error"
              >
                Please enter a valid email address.
              </span>
            </div>
            <div class="error-wrap" *ngIf="error">
              <span class="error">{{ error }}</span>
            </div>
          </div>
        </div>
        <div class="action-btn-wrap">
          <button class="cc-btn cc-btn__error" color="error" aria-label="SEND LINK" [disabled]="forgotPassForm.invalid || requestPending">
            Send link
          </button>
        </div>
        <div class="cc-link">
          <a routerLink="/login">Go back to login</a>
        </div>
      </div>
    </form>
  </div>
  <div class="auth-image"></div>
</div>
