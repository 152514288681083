import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { SharedDirectivesModule } from "app/shared/directives/shared-directives.module";
import { DocumentIconColorEnum } from "app/shared/enums/document-icon-color.enum";
import { MediaTypeEnum } from "app/shared/enums/media-type.enum";

@Component({
  selector: "app-document-icons",
  standalone: true,
  imports: [CommonModule, SharedDirectivesModule],
  templateUrl: "./document-icons.component.html",
  host: {
    class: "document-icons"
  }
})
export class DocumentIconsComponent {
  typeEnums = MediaTypeEnum;
  documentIconColorEnum = DocumentIconColorEnum;

  @Input() type: MediaTypeEnum | string = MediaTypeEnum.MEDIA;

  private _fileExtension: string;

  private iconMap: { [key: string]: string } = {
    jpg: "fa-file-image",
    jpeg: "fa-file-image",
    png: "fa-file-image",
    svg: "fa-file-image",
    gif: "fa-file-image",
    eps: "fa-file-image",
    cad: "fa-file-image",
    bmp: "fa-file-image",
    tiff: "fa-file-image",
    webp: "fa-file-image",
    mp4: "fa-file-video",
    mov: "fa-file-video",
    avi: "fa-file-video",
    mkv: "fa-file-video",
    flv: "fa-file-video",
    wmv: "fa-file-video",
    mp3: "fa-file-audio",
    wav: "fa-file-audio",
    flac: "fa-file-audio",
    aac: "fa-file-audio",
    ogg: "fa-file-audio",
    doc: "fa-file-word",
    docx: "fa-file-word",
    pdf: "fa-file-pdf",
    xml: "fa-file-code",
    csv: "fa-file-csv",
    txt: "fa-file-alt",
    rtf: "fa-file-alt",
    md: "fa-file-alt",
    xls: "fa-file-excel",
    xlsx: "fa-file-excel",
    ods: "fa-file-excel",
    ppt: "fa-file-powerpoint",
    pptx: "fa-file-powerpoint",
    odp: "fa-file-powerpoint",
    zip: "fa-file-archive",
    rar: "fa-file-archive",
    tar: "fa-file-archive",
    gz: "fa-file-archive",
    exe: "fa-file",
    iso: "fa-file-archive"
  };

  @Input()
  set fileExtension(value: string) {
    this._fileExtension = value ? value.toLowerCase() : "";
  }

  get fileExtension(): string {
    return this._fileExtension;
  }

  get fileIcon(): string {
    return this.iconMap[this._fileExtension] || "fa-file"; // Default icon
  }
}
