import { Observable, Subject } from 'rxjs';

export class KissDialogRef {
  private readonly _onClose = new Subject<any>();
  onClose: Observable<any> = this._onClose.asObservable();

  private readonly _onDestroy = new Subject<any>();
  onDestroy: Observable<any> = this._onDestroy.asObservable();

  public close(result?: any) {
    this._onClose.next(result);
  }

  public destroy() {
    this._onDestroy.next(null);
  }
}
