<label class="kiss-toggle-switch__container">
  <input
    class="kiss-toggle-switch__checkbox"
    type="checkbox"
    tabindex="-1"
    type="checkbox"
    [ngModel]="checked"
    [disabled]="disabled"
    (ngModelChange)="onModelChange($event)"
  />
  <span class="kiss-toggle-switch__slider"></span>
</label>
