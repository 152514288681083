import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  KissIconPostDirective,
  KissIconPreDirective,
} from '@kiss/directives/kiss-icon-pre-post';
import { KissPaginatorResultsDirective } from './directives/kiss-paginator-results.directive';

@Component({
  selector: 'kiss-paginator',
  templateUrl: './kiss-paginator.component.html',
  styleUrls: ['./kiss-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'kiss-paginator',
  },
})
export class KissPaginatorComponent implements OnChanges {
  pages = [];
  visiblePages = [];
  currentSize = 0;
  totalPages = 0;
  currentPage = 0;

  /**
   * Show paginator results
   */
  @Input() showResults = true;

  /**
   * Page Size / Per Page
   * @param {number} number
   */
  @Input() pageSize: number = 10;

  /**
   * Current page
   * @param {number} number
   */
  @Input() set page(val: number) {
    if (val > 0) {
      this.currentPage = val;
    } else {
      this.currentPage = 1;
    }
  }

  get page() {
    return this.currentPage;
  }

  /**
   * `total` amount
   * @param {number} number
   */
  @Input() total: number = 0;

  /**
   * Enable selecting custom pageSize. Pass number array.
   *
   * @param {number[]} number[]
   */
  @Input() pageSizes: number[] = [];

  /**
   * `showPages` allows you limit the amount of paginator numbers.
   *
   *  Default value is 3
   *
   * @param {number} number
   */
  @Input() showPages: number = 3;

  /**
   * Event emitter that emits the page and pageSize
   */
  @Output() onChange = new EventEmitter();

  /**
   * Adds a class and controls if paginator is disabled
   */
  @Input() @HostBinding('class.kiss-disabled') set disabled(value: any) {
    this._disabled = value || value === '';
  }

  _disabled: boolean = false;
  get disabled() {
    return this._disabled;
  }

  /**
   * Allow using custom icons
   */
  @ContentChild(KissIconPreDirective) preIconDirective: KissIconPreDirective;
  @ContentChild(KissIconPostDirective) postIconDirective: KissIconPostDirective;
  @ContentChild(KissPaginatorResultsDirective)
  resultsDirective: KissPaginatorResultsDirective;

  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['page'] || changes['pageSize'] || changes['total']) {
      this._calcCurrentSize();

      if (this.total && this.pageSize) {
        this.totalPages = Math.ceil(this.total / this.pageSize);
      } else {
        this.totalPages = 0;
      }

      this._generateVisblePages();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private _generateVisblePages() {
    this.visiblePages = [];

    if (!this.showPages) {
      for (let i = 1; i <= this.totalPages; i++) {
        this.visiblePages.push(i);
      }
      return;
    }

    let startFrom = this.currentPage + 1 - Math.round(this.showPages / 2);

    if (startFrom + this.showPages > this.totalPages) {
      startFrom = this.totalPages + 1 - this.showPages;
    }

    if (startFrom <= 1) {
      startFrom = 1;
    }

    const length =
      this.showPages > this.totalPages ? this.totalPages : this.showPages;
    for (let i = 0; i < length; i++) {
      const page = startFrom + i;

      this.visiblePages.push(page);
    }
  }

  private _calcCurrentSize() {
    const size = this.currentPage * this.pageSize;
    this.currentSize = size < this.total ? size : this.total;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onPageClick(page) {
    this.currentPage = page;
    this._calcCurrentSize();

    if (this.showPages) {
      this._generateVisblePages();
    }

    this.onChange.emit({
      page: this.currentPage,
      pageSize: this.pageSize,
    });
  }

  onLastClick() {
    this.currentPage = this.totalPages;
    this._calcCurrentSize();

    if (this.showPages) {
      this._generateVisblePages();
    }

    this.onChange.emit({
      page: this.currentPage,
      pageSize: this.pageSize,
    });
  }

  onFirstClick() {
    if (this.currentPage !== 1) {
      this.currentPage = 1;
      this._calcCurrentSize();
    }

    if (this.showPages) {
      this._generateVisblePages();
    }

    this.onChange.emit({
      page: this.currentPage,
      pageSize: this.pageSize,
    });
  }

  onPageSizeSelect(value) {
    this.pageSize = +value;

    this.onChange.emit({
      page: this.currentPage,
      pageSize: this.pageSize,
    });
  }
}
