import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment, Route, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { RoleService } from "../services/auth/role.service";

/**
 * Role guard needs to be activated after Auth since we check if user exists
 */
@Injectable()
export class RoleGuard {
  private _toastrMsg = "You don't have permission to view this page. Please contact the administrator for further assistance.";
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _roleService: RoleService,
    private _toastr: ToastrService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const allowedRoles = route.data?.allowedRoles;

    if (!allowedRoles) {
      return true;
    }

    if (this._roleService.isAuthorized(allowedRoles)) {
      return true;
    } else {
      this._toastr.warning(this._toastrMsg);
      if (this._route.snapshot.children.length === 0) {
        const baseRoute = this._roleService.getBaseUrl();
        return this._router.createUrlTree([baseRoute]);
      }
      return false;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const allowedRoles = route.data?.allowedRoles;

    if (!allowedRoles) {
      return true;
    }

    if (this._roleService.isAuthorized(allowedRoles)) {
      return true;
    } else {
      this._toastr.warning(this._toastrMsg);
      if (this._route.snapshot.children.length === 0) {
        const baseRoute = this._roleService.getBaseUrl();
        return this._router.createUrlTree([baseRoute]);
      }
      return false;
    }
  }
}
