import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
  name: "time"
})
export class TimePipe implements PipeTransform {
  /**
   *
   * Converted and formatted to support both Date and HH:mm formats
   *
   * @param value string
   * @returns
   */
  transform(value: number, format: string = "HH:mm"): string {
    if (value) {
      return moment(value, "HH:mm").format(format);
    }

    return null;
  }
}
