export class KissOverlayInfo {
  containerClass?: string;
  fixedContainer?: boolean;
  useComponentOutlet?: boolean;

  constructor(options: {
    containerClass?: string;
    useComponentOutlet?: boolean;
    fixedContainer?: boolean;
  }) {
    options = options || {};
    this.useComponentOutlet = options?.useComponentOutlet;
    this.fixedContainer = options?.fixedContainer ?? true;
    this.containerClass = options?.containerClass || '';
  }
}
