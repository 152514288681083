export enum FocusStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
  COMPLETED = "COMPLETED",
  OVERDUE = "OVERDUE",
  SCHEDULE_LATER = "SCHEDULE_LATER",
  CLOSED = "CLOSED"
}

export const FocusStatusEnumValues: {
  [key in FocusStatusEnum]: string;
} = {
  [FocusStatusEnum.COMPLETED]: "Completed",
  [FocusStatusEnum.OVERDUE]: "Overdue",
  [FocusStatusEnum.IN_PROGRESS]: "In Progress",
  [FocusStatusEnum.NOT_STARTED]: "Not Started",
  [FocusStatusEnum.SCHEDULE_LATER]: "Schedule Later",
  [FocusStatusEnum.CLOSED]: "Closed"
};
