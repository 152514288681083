<div class="company-onboarding__wrapper company-strategies">
  <div class="company-onboarding__form-content">
    <div class="company-onboarding__step-info">
      <p class="company-onboarding__step-info__name">Step 3: Set up strategic goals</p>
      <p class="company-onboarding__step-info__title">Company Configuration</p>
      <p class="company-onboarding__step-info__description">This is where you set up strategic goals for {{ company?.name }}</p>
    </div>
    <div class="company-onboarding__form" [formGroup]="form">
      <div class="strategies">
        <p class="company-onboarding__form__title">Add Company Strategic Goals</p>

        <div class="strategies__fields">
          <div class="strategy" *ngFor="let strategy of form.get('companyStrategies')['controls']; index as i" [formGroup]="strategy">
            <div class="strategy__title">
              <div class="form-item-container">
                <div class="cc-input-container">
                  <input class="strategy__input cc-input" type="text" formControlName="name" placeholder="Company Strategic Goal Title*" />
                </div>
                <i class="fa-regular fa-trash" (click)="removeCompanyStrategy(i)"></i>
              </div>
            </div>

            <div class="form-item-container">
              <div class="cc-input-container strategy__description">
                <textarea
                  class="strategy__textarea cc-input"
                  type="text"
                  formControlName="description"
                  placeholder="Company Strategic Goal Description"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="strategies__add-button">
          <button class="cc-btn cc-btn--lg cc-btn__stroked" (click)="addCompanyStrategy()">
            <i class="fa-regular fa-plus"></i> Add new goal
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
