import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hrefHttp' })
export class HrefHttpPipe implements PipeTransform {
  constructor() {}

  transform(value: string) {
    if (!value) return value;
    return value.startsWith('http://') || value.startsWith('https://') ? value : 'http://' + value;
  }
}
