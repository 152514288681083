import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Optional,
  Renderer2,
  SimpleChanges
} from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";

export enum KissSetPositionXPos {
  AUTO = "auto",
  LEFT = "left",
  RIGHT = "right"
}

export enum KissSetPositionYPos {
  AUTO = "auto",
  TOP = "top",
  BOTTOM = "bottom"
}

/**
 * USAGE
 *
 * @example
 *  <div
 *     class="kiss-menu-content"
 *     kissSetPosition
 *     [kissSetPositionParent]="menuTrigger"
 *     [xPosition]="xPosition"
 *     [yPosition]="yPosition"
 *     [handleOverflow]="true"
 *     [inheritWidth]="true"
 *     >
 *     <ng-content></ng-content>
 *  </div>
 */
@Directive({
  selector: "[kissSetPosition]",
  inputs: ["kissSetPositionParent", "xPosition", "yPosition", "handleOverflow", "inheritWidth"]
})
export class KissSetPositionDirective implements OnChanges, OnDestroy, AfterViewInit {
  /**
   * REQUIRED
   *
   * Set the parent element which will have it's movement tracked
   * @param {HTMLElement} HTMLElement
   */
  @Input("kissSetPositionParent") parent: HTMLElement;

  /**
   * OPTIONAL
   *
   * Set the y position of the host element
   * @param {('auto' | 'left' | 'right')} 'auto' | 'left' | 'right'
   */
  @Input() xPosition: `${KissSetPositionXPos}`;

  /**
   * OPTIONAL
   *
   * Set the x position of the host element
   * @param {('auto' | 'top' | 'bottom')} 'top' | 'bottom'
   */
  @Input() yPosition: `${KissSetPositionYPos}`;

  /**
   * OPTIONAL
   *
   * Enable overflow tracking
   * @param {boolean} boolean
   */
  @Input() handleOverflow: boolean = false;

  /**
   *  OPTIONAL
   *
   * `inheritWidth` adjusts xPosition left and right to the parent making it centered.
   *  It will ignore xPosition and yPosition options
   *
   * Example: select dropdown
   *
   * @param {boolean} boolean
   */
  @Input() inheritWidth: boolean = false;

  //PRIVATE
  private parentPosition: DOMRect;
  private bodyPosition: DOMRect; // using body because window.screen does not register desktop resize

  private _unsubscribeAll: Subject<void>;
  private _resizeObserver: ResizeObserver;
  private _mutationObserver: MutationObserver;

  constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    private _ngZone: NgZone,
    @Optional() @Inject(DOCUMENT) private _document: Document
  ) {
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * After view Init
   */
  ngAfterViewInit(): void {
    //we set the position in here because the element has not finished loading in ngOnInit
    this._renderer.setStyle(this._elRef.nativeElement, "position", "absolute");

    this._ngZone.runOutsideAngular(() => {
      this._handleDOMMutations();
      this._handleWindowResize();
      this._handleParentResize();
      this._handleScroll();
    });

    this._updateXYPositions();
  }

  /**
   * On init
   */
  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this._disconnectResize();
    this._disconnectMutation();
    this._removeScrollListener();
  }

  /**
   * On changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["parent"]) {
      this._updatePositions();
    }

    if ((changes["xPosition"] || changes["yPosition"]) && this.parentPosition && this.bodyPosition) {
      this._updateXYPositions();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private calcYPosition(y: `${KissSetPositionYPos}`) {
    // top should start from top of parent and go up
    // bottom should start at bottom of parent and go down
    let pos = y;

    if (this.handleOverflow) {
      if (this._isOverflownY()) pos = KissSetPositionYPos.TOP;
    }

    if (pos === KissSetPositionYPos.TOP) {
      const calcPos = this.bodyPosition.height - this.parentPosition.top;
      this._setStylePosition("bottom", calcPos);
      this._removeStylePosition("top");
    } else {
      const calcPos = this.parentPosition.top + this.parentPosition.height;
      this._setStylePosition("top", calcPos);
      this._removeStylePosition("bottom");
    }
  }

  private calcXPosition(x: `${KissSetPositionXPos}`) {
    // left should start left from parent and go left
    // right should start left  from parent and go right
    let pos = x;

    if (this.handleOverflow) {
      if (this._isOverflownX()) {
        if (this.xPosition === KissSetPositionXPos.RIGHT) {
          pos = KissSetPositionXPos.LEFT;
        } else {
          pos = KissSetPositionXPos.RIGHT;
        }
      }
    }

    if (pos === KissSetPositionXPos.RIGHT) {
      const calcPos = this.parentPosition.left;
      this._setStylePosition("left", calcPos);
      this._removeStylePosition("right");
    } else {
      const calcPos = this.bodyPosition.width - this.parentPosition.right;
      this._setStylePosition("right", calcPos);
      this._removeStylePosition("left");
    }
  }

  private calcInheritedWidth() {
    const posLeft = this.parentPosition.left;
    const posRight = this.bodyPosition.width - this.parentPosition.right;

    this._setStylePosition("left", posLeft);
    this._setStylePosition("right", posRight);
  }

  private _getElPosition(el: HTMLElement) {
    return el?.getBoundingClientRect() || undefined;
  }

  private _setStylePosition(name: string, value: number) {
    this._renderer.setStyle(this._elRef.nativeElement, name, `${value}px`);
  }

  private _removeStylePosition(name: string) {
    this._renderer.removeStyle(this._elRef.nativeElement, name);
  }

  private _handleWindowResize() {
    fromEvent(window, "resize")
      .pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged())
      .subscribe(() => {
        this._checkForChangeAndUpdate();
      });
  }

  private _handleScroll() {
    //true tells event listener to listen for all scrolls (does not trigger preventDefault)
    this._document.addEventListener("scroll", this._updateOnEvent, true);
  }

  private _updateOnEvent = (() => {
    this._checkForChangeAndUpdate();
  }).bind(this);

  private _handleParentResize() {
    this._resizeObserver = new ResizeObserver(() => {
      this._checkForChangeAndUpdate();
    });

    this._resizeObserver.observe(this.parent);
  }

  private _handleDOMMutations() {
    this._disconnectMutation();
    this._mutationObserver = new MutationObserver(() => {
      this._checkForChangeAndUpdate();
    });

    //CROSS-BROWSER SUPPORT
    const container = this._document.documentElement || this._document.body;

    this._mutationObserver.observe(container, { attributes: true, childList: true, subtree: true });
  }

  private _checkForChangeAndUpdate() {
    this._updatePositions();

    if (!this.parentPosition || !this.bodyPosition) return;
    this._updateXYPositions();
  }

  private _isOverflownY = () => this._elRef.nativeElement.offsetHeight + this.parentPosition.bottom > this.bodyPosition.bottom;

  /*
   We need to track both left and right unlike top and bottom
    */
  private _isOverflownX = () => {
    if (this.xPosition === KissSetPositionXPos.RIGHT) {
      return this.parentPosition.left + this._elRef.nativeElement.offsetWidth > this.bodyPosition.right;
    } else {
      return this._elRef.nativeElement.offsetWidth - this.parentPosition.right > 0;
    }
  };

  private _updatePositions() {
    this.bodyPosition = this._getElPosition(document.body);
    this.parentPosition = this._getElPosition(this.parent);
  }

  /*
    Force position calculation even if y and x don't exist
    Ignore if auto
    */
  private _updateXYPositions() {
    if (this.inheritWidth) {
      if (this.yPosition !== KissSetPositionYPos.AUTO) {
        this.calcYPosition(this.yPosition);
      }

      this.calcInheritedWidth();
    } else {
      if (this.yPosition !== KissSetPositionYPos.AUTO) {
        this.calcYPosition(this.yPosition);
      }

      if (this.xPosition !== KissSetPositionXPos.AUTO) {
        this.calcXPosition(this.xPosition);
      }
    }
  }

  private _disconnectMutation() {
    if (this._mutationObserver) {
      this._mutationObserver.disconnect();
    }
  }

  private _disconnectResize() {
    if (this._resizeObserver) {
      this._resizeObserver.disconnect();
    }
  }

  private _removeScrollListener() {
    this._document.removeEventListener("scroll", this._updateOnEvent, true);
  }
}
