import { Directive, ViewContainerRef } from "@angular/core";
import { BaseKissCellOutletDirective } from "../base/base-kiss-cell-outlet.directive";

@Directive({
  selector: "[kissCellOutlet]"
})
export class KissCellOutletDirective extends BaseKissCellOutletDirective {
  constructor(public override _viewContainer: ViewContainerRef) {
    super(_viewContainer);
  }
}
