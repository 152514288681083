import { Inject, Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, CanMatch, Router } from "@angular/router";
import { AuthService } from "../services/auth/auth.service";
import { RoleService } from "../services/auth/role.service";

@Injectable()
export class UnAuthGuard implements CanActivate, CanMatch, CanActivateChild {
  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _role: RoleService
  ) {}

  canActivate() {
    if (this._auth.isAuthenticated()) {
      const baseRoute = this._role.getBaseUrl();
      return this._router.createUrlTree([baseRoute]);
    } else {
      return true;
    }
  }

  canActivateChild() {
    if (this._auth.isAuthenticated()) {
      const baseRoute = this._role.getBaseUrl();
      return this._router.createUrlTree([baseRoute]);
    } else {
      return true;
    }
  }

  canMatch() {
    if (this._auth.isAuthenticated()) {
      const baseRoute = this._role.getBaseUrl();
      return this._router.createUrlTree([baseRoute]);
    } else {
      return true;
    }
  }
}
