import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KissOverlayModule } from '../kiss-overlay/kiss-overlay.module';
import { KissLoadingBarComponent } from './kiss-loading-bar.component';
import { KissLoadingBarService } from './services/kiss-loading-bar.service';

@NgModule({
  declarations: [KissLoadingBarComponent],
  imports: [CommonModule, KissOverlayModule],
  exports: [KissLoadingBarComponent],
  providers: [KissLoadingBarService],
})
export class KissLoadingBarModule {}
