import { Injectable } from "@angular/core";
import { RoleEnum } from "app/shared/enums/role.enum";
import { AuthService } from "./auth.service";
import { isArray } from "@apollo/client/utilities";
import { UserModel } from "app/shared/models/user/user.model";
import { UserDataService } from "./user-data.service";
import { RoutesConstants } from "app/shared/constants/routes.constrants";
import { CompanyService } from "../company/company.service";

@Injectable({
  providedIn: "root"
})
export class RoleService {
  constructor(
    private _auth: AuthService,
    private _user: UserDataService,
    private _companyService: CompanyService
  ) {}

  public isAuthorized(roles: RoleEnum | RoleEnum[] = [], selectedUser?: UserModel): boolean {
    if (this._auth.isAuthenticated()) {
      let user: UserModel = undefined;

      if (selectedUser) {
        user = selectedUser;
      } else {
        user = this._user.getUserData();
      }

      if (isArray(roles)) {
        return !!roles.includes(user?.role);
      } else {
        return roles === user.role;
      }
    }

    return false;
  }

  /**
   * Get url based on user role
   * @returns
   */
  getBaseUrl(): string {
    const isOverviewDash = this.isAuthorized([RoleEnum.CC_ADMIN, RoleEnum.SUPER_ADMIN]);
    const isAnalyticsDashboard = this.isAuthorized([RoleEnum.CULTURE_LEAD, RoleEnum.CONSULTANT_ADMIN, RoleEnum.COMPANY_ADMIN]);

    if (isOverviewDash) {
      return RoutesConstants.OVERVIEW_DASHBOARD;
    }

    if (isAnalyticsDashboard) {
      const company = this._companyService.getActiveCompany();

      if (company) {
        return RoutesConstants.COMPANY_ANALYTICS_DASHBOARD(company.id);
      }

      return RoutesConstants.NO_COMPANIES;
    }
  }

  getBaseUrlOnLogin() {
    if (this.isAuthorized([RoleEnum.CONSULTANT_ADMIN])) {
      return RoutesConstants.CHOOSE_COMPANY;
    } else {
      return this.getBaseUrl();
    }
  }
}
