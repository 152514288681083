<div class="company-onboarding__wrapper company-values">
  <div class="company-onboarding__form-content">
    <div class="company-onboarding__step-info">
      <p class="company-onboarding__step-info__name">Step 2: Set up company values</p>
      <p class="company-onboarding__step-info__title">Company Configuration</p>
      <p class="company-onboarding__step-info__description">This is where you set up values for {{ company?.name }}</p>
    </div>
    <div class="company-onboarding__form" [formGroup]="form">
      <div class="values">
        <p class="company-onboarding__form__title">Add Company Values</p>

        <div class="values__fields">
          <div class="value" *ngFor="let value of form.get('companyValues')['controls']; index as i" [formGroup]="value">
            <div class="value__title">
              <div class="form-item-container">
                <div class="cc-input-container">
                  <input class="value__input cc-input" type="text" formControlName="name" placeholder="Company Value Title* " />
                </div>
                <i class="fa-regular fa-trash" (click)="removeCompanyValue(i)"></i>
              </div>
            </div>

            <div class="form-item-container">
              <div class="cc-input-container value__description">
                <textarea
                  class="value__textarea cc-input"
                  type="text"
                  formControlName="description"
                  placeholder="Company Value Description"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="values__add-button">
            <button class="cc-btn cc-btn--lg cc-btn__stroked" (click)="addCompanyValue()">
              <i class="fa-regular fa-plus"></i> Add new value
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
