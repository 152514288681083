import { Injectable } from "@angular/core";
import { COMPANY_COLOR_MAP } from "./company-color.map";
import { CompanyColorEnum } from "app/shared/enums/company-color.enum";

@Injectable({
  providedIn: "root"
})
export class CompanyColorService {
  private _prefix = "--clr-company-";
  private _map = COMPANY_COLOR_MAP;
  private _initialColor = CompanyColorEnum.DEFAULT;
  private _activeMap: any;

  constructor() {}

  setColor(color: string = this._initialColor): void {
    this._activeMap = this._map[color];
    this.setCssVariables(this._activeMap);
  }

  getActiveMap(): any {
    return this._activeMap;
  }

  resetColor(): void {
    this._activeMap = this._map[this._initialColor];
    this.setCssVariables(this._activeMap);
  }

  setCssVariables(map: any) {
    if (!map) return;

    Object.keys(map).forEach((key) => {
      document.documentElement.style.setProperty(this._prefix + key, map[key]);
    });
  }
}
