import { KissDatepickerSelectionMode, KissDatepickerSelectionModeEnums } from "../types/kiss-datepicker-selection-mode";
import { KissDatepickerTimestamp } from "../types/kiss-datepicker-timestamp.type";

export class KissDatepickerTime {
  selected: Date | Date[];
  viewInfoDate: Date;
  selectionMode: KissDatepickerSelectionMode;
  value: KissDatepickerTimestamp[] = [];
  timeLabel: Date[] = [];

  constructor({ selected, viewInfoDate, selectionMode }: any) {
    this.selected = selected;
    this.viewInfoDate = viewInfoDate;
    this.selectionMode = selectionMode;

    this._initValue();
    this._setTimeLabel();
  }

  /**
   * Create time settings value object
   * @param value
   * @returns
   */
  private _setValue(value: Date) {
    return {
      hours: value?.getHours(),
      minutes: value?.getMinutes(),
      seconds: value?.getSeconds(),
      milliseconds: value?.getMilliseconds()
    };
  }

  /**
   * Update the class value data and timeLabel
   * @param stamp
   */
  updateValueByTimestamp(stamp: KissDatepickerTimestamp[]) {
    this.value = [...stamp];
    this._updateTimeLabelByTimestamp();
  }

  /**
   * Create a date copy
   * @param date
   * @returns
   */
  private _clone(date: Date): Date {
    if (date instanceof Date) {
      return new Date(date.getTime());
    }

    if (this._isValidDate(date)) {
      return new Date(date);
    }

    return date;
  }

  /**
   * Parse the value passed and check if it's NaN
   * @param date
   * @returns boolean
   */
  private _isValidDate(date: any) {
    return !isNaN(Date.parse(date));
  }

  /**
   * Set the intial data of value
   */
  private _initValue() {
    switch (this.selectionMode) {
      case KissDatepickerSelectionModeEnums.RANGE:
        const newValue = (this.selected as Date[])?.length ? (this.selected as Date[]) : this._rangeViewinfo();

        this.value = this._formatValue(newValue);
        break;
      default:
        this.value = this._formatValue([(this.selected as Date) || this.viewInfoDate]);
    }
  }

  /**
   * Set the intial value of timelabel
   *
   * Show viewInfoDate if selected does not exist
   */
  private _setTimeLabel() {
    switch (this.selectionMode) {
      case KissDatepickerSelectionModeEnums.RANGE:
        this._setRangeTimelabel();
        break;
      default:
        this._setDatepickerTimelabel();
    }
  }

  private _setRangeTimelabel() {
    const tmpSelected = this.selected as Date[];

    this.timeLabel = tmpSelected?.length ? tmpSelected?.map((item) => this._clone(item)) : this._rangeViewinfo();
  }

  private _setDatepickerTimelabel() {
    this.timeLabel = [this._clone((this.selected as Date) || this.viewInfoDate)];
  }

  /**
   * Return an array of dates containing viewInfoDate to be used as the default time
   * @returns
   */
  private _rangeViewinfo = () => [this._clone(this.viewInfoDate), this._clone(this.viewInfoDate)];

  /**
   * Format selection array
   * @param selection
   * @returns
   */
  private _formatValue(selection: Date | Date[]) {
    return (
      (selection as Date[])?.map((item) => {
        return this._setValue(item);
      }) || []
    );
  }

  /**
   * Update timeLabel
   */
  private _updateTimeLabelByTimestamp() {
    this.timeLabel =
      this.timeLabel?.map((item, i) => {
        const newItem = this._clone(item);

        newItem.setHours(
          +this.value[i].hours || 0,
          +this.value[i].minutes || 0,
          +this.value[i].seconds || 0,
          +this.value[i].milliseconds || 0
        );

        return newItem;
      }) || [];
  }
}
