import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { KissNavigationService } from '../services/kiss-navigation.service';
import { KissNavigationItem } from '../types/kiss-navigation-item.types';

@Component({
  selector: 'kiss-navigation-item',
  templateUrl: './kiss-navigation-item.component.html',
  styles: [
    `
      :host .nav-icon img {
        display: none;
      }

      :host .nav-icon img.active-icon {
        display: block;
      }
    `,
  ],
  host: {
    class: 'kiss-navigation-item',
  },
})
export class NavigationListItemComponent implements OnInit, OnDestroy {
  @Input() item: KissNavigationItem;

  route: string = '';

  @HostBinding('class.active')
  public isActive = false;

  // Private
  private _unsubscribeAll: Subject<void>;

  constructor(
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _navigationService: KissNavigationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    _router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((e: NavigationEnd) => {
        this.checkActiveRoute(e.urlAfterRedirects);

        // Mark for check
        this._cdr.markForCheck();
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Programatically
    this.checkActiveRoute(this._router.url);

    // Subscribe to navigation item
    this._navigationService.onNavigationItemsUpdated
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Mark for check
        this._cdr.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  includesStates(state: any[] = []) {
    if (typeof state === 'string') {
      return this.route.includes(state);
    }
    return state.some((item) => {
      return this.route.includes(item);
    });
  }

  checkActiveRoute(route: string) {
    let active = false;

    if (this.item?.exactMatch) {
      active = route === this.item.url.split('?')[0];
    } else {
      if (this.item?.excludeUrls?.length) {
        active =
          route.includes(this.item.url) &&
          this.item.excludeUrls.every((url) => !route.includes(url));
      } else {
        active = route.includes(this.item.url);
      }
    }

    this.isActive = active;
    this.route = this.isActive ? route : '';
  }

  preloadIconImg(link: string): void {
    const image = new Image();
    image.src = link;
  }
}
