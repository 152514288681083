import { ToastrService, Toast, ToastPackage } from "ngx-toastr";
import { Component } from "@angular/core";

/**
 * actionBtn Usage:
 *   this.toastr.success("Lorem", undefined, {
 *    payload: {
 *      actionBtn: {
 *         enabled: true,
 *         text: "Lorem",
 *         callback: (event) => {
 *           console.log(event);
 *         }
 *       }
 *     }
 *   });
 *
 */
@Component({
  selector: "custom-toast",
  templateUrl: "./custom-toast.component.html",
  preserveWhitespaces: false
})
export class CustomToastComponent extends Toast {
  isSuccess: boolean = false;
  isInfo: boolean = false;
  isWarning: boolean = false;
  isError: boolean = false;
  actionBtn: {
    enabled?: boolean;
    callback?: Function;
    text?: string;
  } = {};

  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);

    this._setToastState();
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

  clear() {
    this.toastrService.clear();
  }

  private _setToastState() {
    this.isSuccess = this.toastClasses.includes("toast-success");
    this.isInfo = this.toastClasses.includes("toast-info");
    this.isWarning = this.toastClasses.includes("toast-warning");
    this.isError = this.toastClasses.includes("toast-error");

    this.actionBtn = this.toastPackage?.config?.payload?.actionBtn || {};
  }
}
