import { Pipe, PipeTransform } from "@angular/core";
import { UserStatusEnumValues } from "../enums/user-status.enum";
import { RoleEnumValues } from "../enums/role.enum";
import { CompanyStatusEnumValues } from "../enums/company-status.enum";
import { KickoffStatusEnumValues } from "../enums/kickoff-status.enum";
import { CheckInStatusEnumValues } from "../enums/checkin-status.enum";
import { KickoffNameEnumValues } from "../enums/kickoff-name.enum";
import { WorkshopStatusEnumValues } from "../enums/workshop-status.enum";
import { MediaTypeEnumValues } from "../enums/media-type.enum";
import { FocusStatusEnumValues } from "../enums/focus-status.enum";

export enum EnumToValueType {
  ROLE = "role",
  USER_STATUS = "userStatus",
  COMPANY_STATUS = "companyStatus",
  KICK_OFF_STATUS = "kickOffStatus",
  KICK_OFF_NAME = "kickOffName",
  CHECK_IN_STATUS = "checkInStatus",
  WORKSHOP_STATUS = "workshopStatus",
  MEDIA_TYPE = "mediaType",
  FOCUS_STATUS = "focusStatus"
}

@Pipe({
  name: "enumToValue"
})
export class EnumToValuePipe implements PipeTransform {
  private _enumToValueMap = {
    [EnumToValueType.USER_STATUS]: UserStatusEnumValues,
    [EnumToValueType.KICK_OFF_STATUS]: KickoffStatusEnumValues,
    [EnumToValueType.KICK_OFF_NAME]: KickoffNameEnumValues,
    [EnumToValueType.WORKSHOP_STATUS]: WorkshopStatusEnumValues,
    [EnumToValueType.COMPANY_STATUS]: CompanyStatusEnumValues,
    [EnumToValueType.ROLE]: RoleEnumValues,
    [EnumToValueType.CHECK_IN_STATUS]: CheckInStatusEnumValues,
    [EnumToValueType.MEDIA_TYPE]: MediaTypeEnumValues,
    [EnumToValueType.FOCUS_STATUS]: FocusStatusEnumValues
  };

  /**
   *
   * @param value string
   * @param settings EnumToValueType
   * @returns
   */
  transform(value: string, settings: { type: string }): string {
    const typeMap = this._enumToValueMap[settings.type] || {};
    return typeMap[value] || "";
  }
}
