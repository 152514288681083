import { Component } from "@angular/core";
import { KissDialogConfig, KissDialogRef } from "@kiss/components/common";

@Component({
  selector: "app-prompt-dialog",
  templateUrl: "./prompt-dialog.component.html"
})
export class PromptDialogComponent {
  data: { message: string; title: string; rejectBtn: string; confirmBtn: string; promptType?: string; titleHtml?: string };

  constructor(
    private ref: KissDialogRef,
    private config: KissDialogConfig
  ) {
    this.data = this.config.data.config;
  }

  onConfirm() {
    // this.config.data.config.onConfirm();
    this.config.data.onAction(true);
    this.ref.destroy();
  }

  onReject() {
    this.config.data.onAction(false);
    this.ref.destroy();
  }
}
