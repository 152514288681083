import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { KissLoadingBarService } from "@kiss/components/common";
import { RoutesConstants } from "app/shared/constants/routes.constrants";
import { UserModel } from "app/shared/models/user/user.model";
import { AuthService } from "app/shared/services/auth/auth.service";
import { UserDataService } from "app/shared/services/auth/user-data.service";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-no-company",
  templateUrl: "./no-company.component.html"
})
export class NoCompanyComponent implements OnInit, OnDestroy {
  user: UserModel;

  private _destroy = new Subject<void>();
  constructor(
    private _userDataService: UserDataService,
    private _router: Router,
    private _auth: AuthService,
    private _loadingBar: KissLoadingBarService
  ) {}

  ngOnInit(): void {
    this._userDataService.userData$.pipe(takeUntil(this._destroy)).subscribe((data) => {
      this.user = data;
    });
  }
  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onLogout() {
    this._loadingBar.show();
    this._auth
      .logout()
      .subscribe(() => {
        this._router.navigate([RoutesConstants.BASE_AUTH_REDIRECT]);
      })
      .add(() => {
        this._loadingBar.hide();
      });
  }
}
