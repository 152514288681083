import { Observable, Subject } from 'rxjs';
import { KissOverlayInfo } from './kiss-overlay-info';

/**
 * Creates an overlay with it's information and template
 *
 * `onOverlayRemoved` subject fires when this overlay is removed from `kiss-overlay` component
 *
 * USAGE
 * @example
 *
 *  const overlayRef = this._kissOverlayService.createOverlay(TemplateRef);
 *
 *  const overlaySubscription = overlayRef.onOverlayRemoved.subscribe(() => {
 *      this._toggleClosed(); // random function that executes when closed
 *      overlaySubscription = undefined;
 *  });
 *
 *  if (overlayRef) this._kissOverlayService.removeOverlay(overlayRef);
 */
export class KissOverlayRef {
  info: KissOverlayInfo;

  /**
   * Observable that listens when the overlay is removed
   */
  onOverlayRemoved: Observable<any>;
  private readonly _onOverlayRemoved: Subject<void>;

  /**
   * Observable that listens when the container is clicked
   */
  onContainerClick: Observable<any>;
  private readonly _onContainerClick: Subject<void>;

  constructor(info: KissOverlayInfo) {
    this.info = info || null;
    this._onOverlayRemoved = new Subject();
    this._onContainerClick = new Subject();

    this.onOverlayRemoved = this._onOverlayRemoved.asObservable();
    this.onContainerClick = this._onContainerClick.asObservable();
  }

  /**
   * Fires the `onOverlayRemoved` event
   */
  overlayRemoved() {
    this._onOverlayRemoved.next();
  }

  /**
   * Fires the `onContainerClick` event
   */
  containerClicked() {
    this._onContainerClick.next();
  }
}
