import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { KissBreadcrumb, KissBreadcrumbFnData } from "../kiss-breadcrumb";

/**
 * https://marco.dev/angular-breadcrumb
 *
 * https://github.com/ocanzillon/angular-breadcrumb
 *
 * @example
 * const routes: Routes = [
 * {
 *   path: 'users',
 *   component: UserListComponent,
 *   data: {
 *    breadcrumb: {
 *      label: string;
 *      redirectUrl?: string;
 *      queryParams?: any;
 *      exactMatch?: boolean;
 *      useRouteQueryParams?: boolean;
 *      isLink?:boolean;
 *      }
 *    }
 * },
 *   children: [
 *   {
 *    path: ':id',
 *    component: UserComponent,
 *    data: {
 *       breadcrumb: (route: ActivatedRouteSnapshot, parentUrl: string[]) => {
          return {
          label: `${route.params.id === "new" ? "Create new" : "Edit"} Example`,
          isLink: false
            };
          }
 *      },
 *    },
 *   ],
 *  }
 * ];
 */
@Injectable()
export class KissBreadcrumbService {
  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<KissBreadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router) {
    this.updateBreadcrumbs();

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.updateBreadcrumbs();
    });
  }

  private updateBreadcrumbs() {
    // Construct the breadcrumb hierarchy
    const root = this.router.routerState.snapshot.root;
    const breadcrumbs: KissBreadcrumb[] = [];
    this.addBreadcrumb(root, [], breadcrumbs);

    // Emit the new hierarchy
    this._breadcrumbs$.next(breadcrumbs);
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: KissBreadcrumb[]) {
    if (!route) return;

    // Construct the route URL
    const routeUrl = parentUrl.concat(route.url.map((url) => url.path));

    // Add an element for the current route part
    //route.routeConfig?.data?.breadcrumb added to help with lazy loading
    // if it causes issues switch to route.data.breadcrumb
    const breadcrumbData = this._setBreadcrumbData(route.routeConfig?.data?.breadcrumb, {
      route,
      parentUrl,
      breadcrumbs
    });

    const breadcrumbUrl = "/" + routeUrl.join("/");

    // const formattedLabel = this._getLabel(breadcrumbData?.label, route, parentUrl);

    if (breadcrumbData && !breadcrumbData?.skip) {
      const breadcrumb = new KissBreadcrumb({
        id: breadcrumbs.length + 1,
        label: breadcrumbData?.label,
        url: breadcrumbUrl,
        redirectUrl: breadcrumbData?.redirectUrl,
        queryParams: breadcrumbData?.queryParams,
        routeQueryParams: route.queryParams,
        exactMatch: breadcrumbData?.exactMatch,
        useRouteQueryParams: breadcrumbData?.useRouteParams,
        isLink: breadcrumbData?.isLink
      });

      breadcrumbs.push(breadcrumb);
    }
    // Add another element for the next route part

    this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
  }

  /**
   * Breadcrumb can be either a string,
   * a function that has the snapshot route and parentUrls
   *
   * @param label
   * @param route
   * @param parentUrl
   * @returns
   */
  private _setBreadcrumbData(breadcrumb: KissBreadcrumb | Function, data: KissBreadcrumbFnData) {
    if (typeof breadcrumb === "function") {
      return breadcrumb(data);
    } else {
      return breadcrumb;
    }
  }

  // /**
  //  * Label can be either a string,
  //  * a function that has the snapshot route and parentUrls
  //  *
  //  * @param label
  //  * @param route
  //  * @param parentUrl
  //  * @returns
  //  */
  // private _getLabel(label: any, route: ActivatedRouteSnapshot, parentUrl: string[]) {
  //   if (typeof label === "function") {
  //     return label(route, parentUrl);
  //   } else {
  //     return label;
  //   }
  // }
}
