<div class="form-item-container files-container">
  <label class="form-label">Files</label>
  <div
    class="cc-input-container custom-file-upload"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
  >
    <label for="file-upload">
      <input id="file-upload" type="file" name="file" (change)="onFileSelected($event)" multiple />
      <img src="assets/svg/upload-icon.svg" *ngIf="!dataSource?.length" />
    </label>

    <kiss-table class="cc-table" #allData [dataSource]="dataSource" [displayColumns]="displayColumns" *ngIf="dataSource?.length">
      <ng-container kissColumnDef="name">
        <kiss-header-cell *kissHeaderCellDef><div class="text-ellipsis">Name</div></kiss-header-cell>
        <kiss-cell *kissCellDef="let row">
          <div class="supporting-document__table__icon">
            <app-document-icons [type]="'media'" [fileExtension]="row.type"></app-document-icons>
          </div>
          <div class="text-ellipsis" [title]="row.name">
            {{ row.name }}
          </div>
        </kiss-cell>
      </ng-container>
      <ng-container kissColumnDef="size">
        <kiss-header-cell *kissHeaderCellDef><div class="text-ellipsis">Size</div></kiss-header-cell>
        <kiss-cell *kissCellDef="let row">
          <div class="text-ellipsis">
            {{ row.sizeReadable }}
          </div>
        </kiss-cell>
      </ng-container>
      <ng-container kissColumnDef="upload">
        <kiss-header-cell *kissHeaderCellDef><div class="text-ellipsis"></div></kiss-header-cell>
        <kiss-cell *kissCellDef="let row">
          <div class="custom-loading-bar" *ngIf="row.uploading">
            <div class="custom-loading-bar__progress" [ngStyle]="{ width: row.progress + '%' }"></div>
            <p>{{ row.progress }}%</p>
          </div>
        </kiss-cell>
      </ng-container>
      <ng-container kissColumnDef="action">
        <kiss-header-cell *kissHeaderCellDef><div class="text-ellipsis"></div></kiss-header-cell>
        <kiss-cell *kissCellDef="let row">
          <div class="text-ellipsis" (click)="removeFile(row); $event.stopPropagation()"><i class="fa-solid fa-trash"></i> Delete</div>
        </kiss-cell>
      </ng-container>

      <kiss-header-row *kissHeaderRowDef="displayColumns"></kiss-header-row>
      <kiss-row *kissRowDef="let row; columns: displayColumns"></kiss-row>
    </kiss-table>
    <p [ngStyle]="{ paddingTop: dataSource?.length ? '16px' : '0px' }">
      Drag and drop your files here or
      <label for="file-upload">browse</label>.
    </p>
  </div>
</div>
