import { gql } from "apollo-angular";

export const COMPANY_VALUE_FRAGMENT = gql`
  fragment CompanyValueFragment on CompanyValue {
    companyId
    createdAt
    description
    id
    name
    updatedAt
  }
`;
