import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlContainer, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CompanyTeamFormService } from "app/shared/components/company-team-form/company-team-form.service";
import { CompanyModel } from "app/shared/models/company/company.model";
import { TeamModel } from "app/shared/models/company/team.model";

@Component({
  selector: "app-company-onboarding-teams",
  templateUrl: "./company-onboarding-teams.component.html",
  styleUrl: "./company-onboarding-teams.component.scss"
})
export class CompanyOnboardingTeamsComponent implements OnInit {
  form: FormGroup;
  @Input() company: CompanyModel;
  @Input() isLoading: boolean = false;
  @Output() onSkip = new EventEmitter();
  @Output() onNext = new EventEmitter();
  @Output() onPrev = new EventEmitter();
  companyLeads = [];

  constructor(
    private controlContainer: ControlContainer,
    private _companyTeamFormService: CompanyTeamFormService,
    private _fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = <FormGroup>this.controlContainer.control;
    this.companyLeads = this.company?.companyCultureLead?.map((item) => item.user) || [];

    this.company?.team?.forEach((team) => {
      const control = this._companyTeamFormService.createForm({
        data: team,
        companyLeads: this.companyLeads,
        companyId: this.company.id
      });
      (this.form?.get("teams")["controls"] as FormArray).push(control);
    });
  }

  onAddNewTeam() {
    const control = this._companyTeamFormService.createForm({
      data: undefined,
      companyLeads: this.companyLeads,
      companyId: this.company.id
    });

    (this.form?.get("teams")["controls"] as FormArray).push(control);
  }

  getFormIsValid() {
    const controls = (this.form?.get("teams") as FormArray).controls;
    return controls.some((control) => control.invalid);
  }

  onClickSkip() {
    this.onSkip.next(true);
  }

  onClickNext() {
    this.onNext.next(true);
  }

  onClickPrev() {
    this.onPrev.next(true);
  }

  onTeamDelete(index: number) {
    (this.form?.get("teams") as FormArray).removeAt(index);
  }
}
