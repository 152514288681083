import { NgModule, Optional, SkipSelf } from "@angular/core";
import { KissLoadingBarModule, KissOverlayModule } from "./components/common";
import { KissLayoutModule } from "./components/kiss-layout";

@NgModule({
  imports: [KissLayoutModule, KissOverlayModule, KissLoadingBarModule],
  exports: [KissLayoutModule, KissOverlayModule, KissLoadingBarModule]
})
export class KissModule {
  /**
   * Constructor
   */
  constructor(@Optional() @SkipSelf() parentModule?: KissModule) {
    if (parentModule) {
      throw new Error("KissModule has already been loaded. Import this module in the AppModule only!");
    }
  }
}
