import { gql } from "apollo-angular";
import { USER_FRAGMENT } from "../../fragments/user.fragment";

const LOGIN = gql`
  ${USER_FRAGMENT}
  mutation Login($authLoginUserInput: AuthLoginUserInput!) {
    login(authLoginUserInput: $authLoginUserInput) {
      accessToken
      accessTokenExpiration
      refreshToken
      user {
        ...UserFragment
      }
    }
  }
`;
const SIGN_OUT = gql`
  mutation SignOut($authSignOutUserInput: AuthSignOutUserInput!) {
    signOut(authSignOutUserInput: $authSignOutUserInput) {
      status
    }
  }
`;
const CHANGE_PASSWORD = gql`
  mutation ChangePassword($authChangePasswordUserInput: AuthChangePasswordUserInput!) {
    changePassword(authChangePasswordUserInput: $authChangePasswordUserInput) {
      status
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation Register($authForgotPasswordUserInput: AuthForgotPasswordUserInput!) {
    forgotPassword(authForgotPasswordUserInput: $authForgotPasswordUserInput) {
      status
      message
    }
  }
`;

const CONFIRM_PASSWORD = gql`
  mutation Register($authConfirmPasswordUserDto: AuthConfirmPasswordUserInput!) {
    confirmPassword(authConfirmPasswordUserDto: $authConfirmPasswordUserDto) {
      status
    }
  }
`;

const TEMP_LOGIN = gql`
  mutation Mutation($authTempLoginUserInput: AuthTempUserLoginInput!) {
    tempLogin(authTempLoginUserInput: $authTempLoginUserInput) {
      status
    }
  }
`;

const REFRESH_TOKEN = gql`
  ${USER_FRAGMENT}
  mutation Refresh($authRefreshTokenInput: AuthRefreshTokenInput!) {
    refresh(authRefreshTokenInput: $authRefreshTokenInput) {
      user {
        ...UserFragment
      }
      accessTokenExpiration
      accessToken
      refreshToken
    }
  }
`;

const REGISTER = gql`
  mutation Register($createAuthInput: AuthRegisterUserInput!) {
    register(createAuthInput: $createAuthInput) {
      message
      status
    }
  }
`;

export { LOGIN, SIGN_OUT, CHANGE_PASSWORD, FORGOT_PASSWORD, CONFIRM_PASSWORD, TEMP_LOGIN, REFRESH_TOKEN, REGISTER };
