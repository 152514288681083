import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { KissDatepickerTime } from "./kiss-datepicker-time";
import { KissDatepickerSelectionMode } from "../types/kiss-datepicker-selection-mode";
import { KissDatepickerTimestamp, KissDatepickerTimestampEnum } from "../types/kiss-datepicker-timestamp.type";
import { KissDatepickerTimeSettings } from "../kiss-datepicker-settings/kiss-datepicker-time-settings";

const FEILD_CONFIG: { [key: string]: { id: KissDatepickerTimestampEnum; max: number; padding: string } } = {
  HOURS: {
    id: KissDatepickerTimestampEnum.HOURS,
    max: 23,
    padding: "00"
  },
  MINUTES: {
    id: KissDatepickerTimestampEnum.MINUTES,
    max: 59,
    padding: "00"
  },
  SECONDS: {
    id: KissDatepickerTimestampEnum.SECONDS,
    max: 59,
    padding: "00"
  },
  MILLISECONDS: {
    id: KissDatepickerTimestampEnum.MILLISECONDS,
    max: 999,
    padding: "000"
  }
};

enum TimeNavDirection {
  NEXT = "next",
  PREV = "prev"
}

@Component({
  selector: "kiss-datepicker-time",
  templateUrl: "./kiss-datepicker-time.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "kiss-datepicker__time"
  }
})
export class KissDatepickerTimeComponent {
  min = 0;
  form: FormArray;
  currentDate: Date;
  config = FEILD_CONFIG;
  defaultIncrementAmount = 1;
  value: KissDatepickerTimestamp[];
  selectionMode: KissDatepickerSelectionMode;

  private _fieldValidators = (min: number, max: number) => [
    Validators.required,
    Validators.pattern("^[0-9]*$"),
    Validators.min(min),
    Validators.max(max)
  ];

  @Input() set settings(settings: KissDatepickerTime) {
    this.selectionMode = settings.selectionMode;
    this.value = settings.value;

    this.form = this._createForm();
  }

  @Input("kissDatepickerTimeSettings") timeSettings: KissDatepickerTimeSettings;

  @Output() onChange = new EventEmitter();

  constructor(private _fb: FormBuilder) {}

  private _createForm() {
    return this._fb.array(this.value?.map((item) => this._createGroup(item)) || []);
  }

  private _createGroup(data: KissDatepickerTimestamp) {
    return this._fb.group({
      [KissDatepickerTimestampEnum.HOURS]: [
        this.timeSettings.showHours ? this.padLeft(+data.hours, FEILD_CONFIG.HOURS.padding) : FEILD_CONFIG.HOURS.padding,
        this._fieldValidators(this.min, FEILD_CONFIG.HOURS.max)
      ],
      [KissDatepickerTimestampEnum.MINUTES]: [
        this.timeSettings.showMinutes ? this.padLeft(+data.minutes, FEILD_CONFIG.MINUTES.padding) : FEILD_CONFIG.MINUTES.padding,
        this._fieldValidators(this.min, FEILD_CONFIG.MINUTES.max)
      ],
      [KissDatepickerTimestampEnum.SECONDS]: [
        this.timeSettings.showSeconds ? this.padLeft(+data.seconds, FEILD_CONFIG.SECONDS.padding) : FEILD_CONFIG.SECONDS.padding,
        this._fieldValidators(this.min, FEILD_CONFIG.SECONDS.max)
      ],
      [KissDatepickerTimestampEnum.MILLISECONDS]: [
        this.timeSettings.showMilliseconds
          ? this.padLeft(+data.milliseconds, FEILD_CONFIG.MILLISECONDS.padding)
          : FEILD_CONFIG.MILLISECONDS.padding,
        this._fieldValidators(this.min, FEILD_CONFIG.MILLISECONDS.max)
      ]
    });
  }

  /**
   * Increase form control value
   * @param controlName
   */
  next(controlName: `${KissDatepickerTimestampEnum}`, index: number) {
    this._setControlValue(controlName, index, TimeNavDirection.NEXT);
  }

  /**
   * Reduce form control value
   * @param controlName
   */
  prev(controlName: `${KissDatepickerTimestampEnum}`, index: number) {
    this._setControlValue(controlName, index, TimeNavDirection.PREV);
  }

  /**
   * Adjust form control value
   * @param controlName - Name of the control
   * @param index - Index of the control in form array
   * @param direction - Direction of adjustment: 'prev' or 'next'
   */
  private _setControlValue(controlName: `${KissDatepickerTimestampEnum}`, index: number, direction: TimeNavDirection) {
    const control = this.form["controls"][index].get(controlName);
    const currentValue = parseInt(control?.value) || 0;
    const increment = this.getIncrement(controlName);
    let newValue = 0;

    if (direction === TimeNavDirection.NEXT) {
      newValue = currentValue + increment;
    } else {
      newValue = currentValue - increment;
    }

    const valueAdjustedForIncrement = this._getValueAdjustedForIncrement(controlName, newValue, direction);

    this._updateControlValue(control, controlName, valueAdjustedForIncrement);
  }

  private _updateControlValue(control: any, name: `${KissDatepickerTimestampEnum}`, value: number) {
    const formattedValue = this.updateFieldFormat(name, value);
    control?.setValue(formattedValue);
    control?.markAsDirty();
    this.updateTimestamp();
  }

  updateTimestamp() {
    if (!this.form.valid) return;
    const postModel = this.form.value;
    this.onChange.next(postModel);
  }

  /**
   * Set field by name and reset it if it's over the threshold
   * @param name
   * @param value
   */
  updateFieldFormat(name: `${KissDatepickerTimestampEnum}`, value: number): string {
    let formattedValue: any = value || 0;
    const field = Object.values(FEILD_CONFIG).find((item) => item.id === name);

    if (formattedValue > field.max) {
      formattedValue = this.min;
    } else if (formattedValue < this.min) {
      // 59 + 1 + value
      // default increment: 60 - 1
      // custom increment: 60 - 15
      formattedValue = field.max + 1 + formattedValue;
    }

    return this.padLeft(formattedValue, field.padding);
  }

  padLeft(value: number, padding: string): string {
    return String(padding + value).slice(-padding.length);
  }

  getIncrement(controlName: `${KissDatepickerTimestampEnum}`) {
    if (this.timeSettings?.minuteIncrement && controlName === KissDatepickerTimestampEnum.MINUTES) {
      return this.timeSettings.minuteIncrement;
    }

    return this.defaultIncrementAmount;
  }

  /**
   * Adjust formatted value to match all divisible increment values
   * @param name
   * @param value
   * @returns
   */
  private _getValueAdjustedForIncrement(name: `${KissDatepickerTimestampEnum}`, value: number, direction: TimeNavDirection) {
    const increment = this.getIncrement(name);
    let adjustedValue = 0;

    if (direction === TimeNavDirection.NEXT) {
      adjustedValue = Math.floor(value / increment);
    } else {
      adjustedValue = Math.ceil(value / increment);
    }

    return adjustedValue * increment;
  }
}
