import { Injectable } from "@angular/core";
import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { AuthService } from "../services/auth/auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.authService.isAuthenticated()) {
      return next.handle(req);
    }

    const token = this.authService.getAccessTokenToken();

    if (token) {
      const header = "Bearer " + token;
      const headers = req.headers.set("Authorization", header);
      req = req.clone({ headers });
    }

    return next.handle(req);
  }
}
