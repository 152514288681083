import { KissNavigationItem } from "@kiss/components/common";
import { navigationIcons } from "./navigation-icons";
import { RoleEnum } from "app/shared/enums/role.enum";
import { RoutesConstants } from "app/shared/constants/routes.constrants";

export function getMainNavigation(settings: { triggerLogout: Function; companyId: string }): KissNavigationItem[] {
  return [
    {
      type: "group",
      title: "MAIN",
      seperator: true,
      children: [
        {
          type: "item",
          title: "Overview",
          url: RoutesConstants.COMPANY_ANALYTICS_DASHBOARD(settings.companyId),
          icon: {
            svg: navigationIcons.dashobard
          },
          allowedRoles: [RoleEnum.CULTURE_LEAD, RoleEnum.CONSULTANT_ADMIN, RoleEnum.COMPANY_ADMIN]
        },
        {
          type: "item",
          title: "Dashboard",
          url: RoutesConstants.OVERVIEW_DASHBOARD,
          icon: {
            svg: navigationIcons.dashobard
          },
          allowedRoles: [RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN]
        },
        {
          type: "item",
          title: "Company",
          url: RoutesConstants.COMPANY_DASHBOARD(settings.companyId),
          icon: {
            svg: navigationIcons.companies
          },
          allowedRoles: [RoleEnum.COMPANY_ADMIN, RoleEnum.CONSULTANT_ADMIN]
        },
        {
          type: "item",
          title: "Users",
          url: `/company/${settings.companyId}/users`,
          icon: {
            svg: navigationIcons.userManagement
          },
          allowedRoles: [RoleEnum.COMPANY_ADMIN, RoleEnum.CONSULTANT_ADMIN]
        },
        {
          type: "item",
          title: "Users",
          url: "/users",
          icon: {
            svg: navigationIcons.userManagement
          },
          allowedRoles: [RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN]
        },
        {
          type: "item",
          title: "Analytics",
          url: `/company-analytics`,
          icon: {
            svg: navigationIcons.anayltics
          },
          allowedRoles: [RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN]
        },
        {
          type: "item",
          title: "Companies",
          url: "/companies",
          allowedRoles: [RoleEnum.SUPER_ADMIN, RoleEnum.CC_ADMIN],
          icon: {
            svg: navigationIcons.companies
          }
        },
        {
          type: "item",
          title: "Supporting Documents",
          url: `/company/${settings.companyId}/supporting-documents`,
          icon: {
            svg: navigationIcons.supprtingDocuments
          },
          allowedRoles: [RoleEnum.COMPANY_ADMIN, RoleEnum.CONSULTANT_ADMIN, RoleEnum.CULTURE_LEAD]
        },
        {
          type: "item",
          title: "Events",
          url: `/company/${settings.companyId}/events`,
          icon: {
            svg: navigationIcons.events
          },
          allowedRoles: [RoleEnum.COMPANY_ADMIN, RoleEnum.CONSULTANT_ADMIN, RoleEnum.CULTURE_LEAD]
        },
        {
          type: "item",
          title: "Themes & Focus Areas",
          url: `/company/${settings.companyId}/themes-and-focus`,
          icon: {
            svg: navigationIcons.focusAreas
          },
          allowedRoles: [RoleEnum.COMPANY_ADMIN, RoleEnum.CULTURE_LEAD, RoleEnum.CONSULTANT_ADMIN]
        }
      ]
    },
    {
      type: "group",
      title: "SETTINGS",
      seperator: true,
      classes: "nav-group-title--lg",
      children: [
        {
          type: "item",
          title: "Profile",
          url: "/profile",
          icon: {
            svg: navigationIcons.userEdit
          }
        },
        {
          type: "item",
          title: "Log out",
          icon: {
            svg: navigationIcons.logout
          },
          function: () => {
            settings.triggerLogout();
          }
        }
      ]
    }
  ];
}
