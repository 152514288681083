<div class="company-onboarding">
  <app-company-onboarding-sidebar
    [steps]="steps"
    [activeStep]="activeStep"
    (onStepClick)="onStepClick($event)"
  ></app-company-onboarding-sidebar>

  <div class="company-onboarding__container">
    <div class="company-onboarding__header">
      <p class="company-onboarding__header-company-name">{{ dataSource?.name }}</p>
      <p class="company-onboarding__header-message">
        You are assigned as CC Consultant for {{ dataSource?.name }} . Please follow these steps to complete the set up of this company.
      </p>
    </div>
    <app-company-onboarding-logo-colors
      [formGroup]="form"
      *ngIf="activeStep === stepEnums.LOGO_AND_COLORS"
      class="company-onboarding__child"
      (onSkip)="onSkip()"
      (onNext)="onNext()"
      [data]="dataSource"
    ></app-company-onboarding-logo-colors>
    <app-company-onboarding-values
      [formGroup]="form"
      *ngIf="activeStep === stepEnums.COMPANY_VALUES"
      class="company-onboarding__child"
      (onSkip)="onSkip()"
      (onNext)="onNext()"
      (onPrev)="onPrev()"
      [company]="dataSource"
    ></app-company-onboarding-values>
    <app-company-onboarding-strategies
      [formGroup]="form"
      *ngIf="activeStep === stepEnums.COMPANY_GOALS"
      class="company-onboarding__child"
      (onSkip)="onSkip()"
      (onNext)="onNext()"
      (onPrev)="onPrev()"
      [company]="dataSource"
    ></app-company-onboarding-strategies>
    <app-company-onboarding-kick-off
      [formGroup]="form"
      *ngIf="activeStep === stepEnums.KICK_OFF"
      class="company-onboarding__child"
      (onSkip)="onSkip()"
      (onNext)="onNext()"
      (onPrev)="onPrev()"
    ></app-company-onboarding-kick-off>
    <app-company-onboarding-documents
      [formGroup]="form"
      *ngIf="activeStep === stepEnums.SUPPORTING_DOCUMENTS"
      class="company-onboarding__child"
      (onSkip)="onSkip()"
      (onNext)="onNext()"
      (onPrev)="onPrev()"
    ></app-company-onboarding-documents>
    <app-company-onboarding-teams
      *ngIf="activeStep === stepEnums.TEAMS"
      [formGroup]="form"
      [company]="dataSource"
      [isLoading]="isLoading"
      (onPrev)="onPrev()"
      class="company-onboarding__child"
      (onSkip)="onSkip()"
      (onNext)="onNext()"
      [company]="dataSource"
    ></app-company-onboarding-teams>
    <app-company-onboarding-success
      *ngIf="activeStep === stepEnums.COMPLETE"
      class="company-onboarding__child success"
      [formGroup]="form"
    ></app-company-onboarding-success>

    <div class="company-onboarding__footer">
      <button class="back-btn" (click)="onPrev()" *ngIf="activeStep !== stepEnums.LOGO_AND_COLORS && activeStep !== stepEnums.COMPLETE">
        <span>&lsaquo;</span> Back
      </button>
      <div class="steps-wrapper">
        <!--  <button class="cc-btn cc-btn--lg cc-btn__stroked" (click)="onClickSkip()">Skip</button> -->
        <button
          class="cc-btn cc-btn--lg cc-btn__primary"
          (click)="activeStep === stepEnums.COMPLETE ? goToDashboard() : onNext()"
          [disabled]="form.get('kickOffVisibleToCultureLead').invalid"
        >
          {{ activeStep === stepEnums.COMPLETE ? "Go to Dashboard" : "Next" }}
        </button>
      </div>
    </div>
  </div>
</div>
