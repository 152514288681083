import { DOCUMENT } from "@angular/common";
import { Directive, ElementRef, HostListener, Inject, Input, OnDestroy, Renderer2 } from "@angular/core";

enum KissTooltipTypes {
  TEXT = "text",
  HTML = "html"
}

enum KissTooltipStickTo {
  CROSSHAIR = "crosshair",
  PARENT = "parent"
}

@Directive({
  selector: "[kissTooltip]",
  host: {
    class: "kiss-tooltip"
  }
})
export class KissTooltipDirective implements OnDestroy {
  @Input() kissTooltip: string = "";
  @Input() kissTooltipType: `${KissTooltipTypes}` = KissTooltipTypes.TEXT;
  @Input() kissTooltipTimeout: any = 2000;
  @Input() kissTooltipStickTo: `${KissTooltipStickTo}` = KissTooltipStickTo.PARENT;

  @HostListener("mousemove", ["$event"]) onMouseMove($event) {
    this.checkMove($event);
  }

  @HostListener("mouseleave") onMouseLeave() {
    this._clearTimeout();
  }

  private _timeout: any;
  private _childRef: Element;
  constructor(
    private _renderer: Renderer2,
    private _elRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  private checkMove(event) {
    this._clearTimeout();
    const contains = this._elRef.nativeElement === event.target || this._elRef.nativeElement.contains(event.target);
    if (!contains) return;

    this._timeout = setTimeout(() => {
      this._createChild(event);
    }, this.kissTooltipTimeout);
  }

  private _createChild(event) {
    if (!this.kissTooltip) return;

    //create el
    this._childRef = this._renderer.createElement("div");
    this._renderer.addClass(this._childRef, "kiss-tooltip__tag");

    //add content
    if (this.kissTooltipType === KissTooltipTypes.TEXT) {
      const text = this._renderer.createText(this.kissTooltip || "");
      this._renderer.appendChild(this._childRef, text || "");
    } else if (this.kissTooltipType === KissTooltipTypes.HTML) {
      this._renderer.setProperty(this._childRef, "innerHTML", this.kissTooltip || "");
    }

    //append the child to body
    this._renderer.appendChild(this.document.body, this._childRef);

    let yPos = 0;
    let xPos = 0;

    if (this.kissTooltipStickTo === KissTooltipStickTo.CROSSHAIR) {
      //calc the position
      yPos = event.clientY + 10;
      xPos = event.clientX;

      this._renderer.setStyle(this._childRef, "top", yPos + "px");
      this._renderer.setStyle(this._childRef, "left", xPos + "px");
    }

    if (this.kissTooltipStickTo === KissTooltipStickTo.PARENT) {
      const parentPosition = this._getElPosition(this._elRef.nativeElement);

      //calc the position
      yPos = parentPosition.bottom;
      xPos = parentPosition.left;

      this._renderer.setStyle(this._childRef, "top", yPos + "px");
      this._renderer.setStyle(this._childRef, "left", xPos + "px");
    }

    this._renderer.setAttribute(this._childRef, "tabindex", "-1");
  }

  private _destroyChild() {
    if (!this._childRef) return;
    this._renderer.removeChild(this.document.body, this._childRef);
    this._childRef = null;
  }

  private _clearTimeout() {
    clearTimeout(this._timeout);
    this._destroyChild();
  }

  private _getElPosition(el: HTMLElement) {
    return el?.getBoundingClientRect() || undefined;
  }

  ngOnDestroy(): void {
    this._clearTimeout();
  }
}
