export enum RoleEnum {
  SUPER_ADMIN = "SUPER_ADMIN",
  CC_ADMIN = "CC_ADMIN",
  CONSULTANT_ADMIN = "CONSULTANT_ADMIN",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  CULTURE_LEAD = "CULTURE_LEAD"
}

export const RoleEnumValues: {
  [key in RoleEnum]: string;
} = {
  [RoleEnum.SUPER_ADMIN]: "Super Admin",
  [RoleEnum.CC_ADMIN]: "CC Admin",
  [RoleEnum.CONSULTANT_ADMIN]: "Consultant Admin",
  [RoleEnum.COMPANY_ADMIN]: "Company Admin",
  [RoleEnum.CULTURE_LEAD]: "Team Facilitator"
};
