import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KissSetPositionModule } from '@kiss/directives/kiss-set-position/kiss-set-position.module';
import { KissOverlayModule } from '../kiss-overlay/kiss-overlay.module';
import { KissMenuComponent } from './kiss-menu.component';
import { KissMenuItemDirective } from './kiss-menu-item/kiss-menu-item.component';

@NgModule({
  declarations: [KissMenuComponent, KissMenuItemDirective],
  imports: [CommonModule, KissOverlayModule, KissSetPositionModule],
  exports: [KissMenuComponent, KissMenuItemDirective],
})
export class KissMenuModule {}
