import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  ViewChild
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { asyncScheduler, scheduled, Subject } from "rxjs";
import { KissSelectOptionComponent, KISS_SELECT_OPTION_TOKEN } from "./kiss-select-option/kiss-select-option.component";
import { mergeAll, startWith, takeUntil } from "rxjs/operators";
import { kissAnimations } from "@kiss/animations";
import { KissIconPostDirective, KissIconPreDirective } from "@kiss/directives/kiss-icon-pre-post";

@Component({
  selector: "kiss-select",
  templateUrl: "./kiss-select.component.html",
  host: {
    class: "kiss-select",
    "[attr.tabindex]": "tabindex",
    "(focus)": "onFocus()",
    "(blur)": "onBlur()"
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: kissAnimations,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KissSelectComponent),
      multi: true
    }
  ]
})
export class KissSelectComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {
  // -----------------------------------------------------------------------------------------------------
  // @ BUILT IN
  // -----------------------------------------------------------------------------------------------------

  /**
   * Holds the current value of the control
   */
  @Input() set value(newValue: any) {
    // Always re-assign an array, because it might have been mutated.

    if (newValue !== this._value || (this._multiple && Array.isArray(newValue))) {
      this._preselectValue(newValue);
      this._value = newValue;
    }
  }

  private _value: any;
  get value() {
    return this._value;
  }

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {};

  /**
   * Invoked when the model is disabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._renderer.setProperty(this._elRef.nativeElement, "disabled", isDisabled);
  }

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.onChange(this.value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value): void {
    this.value = value;
    this.updateChanges();
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ CUSTOM
  // -----------------------------------------------------------------------------------------------------
  @ViewChild("container") container: ElementRef;
  @ViewChild("search", { read: ElementRef }) search: ElementRef;
  @ViewChild("optionContainerRef") optionContainerRef: ElementRef;
  @ViewChild("tabTrap") tabTrap: ElementRef;

  /**
   * Holds the selected option values
   */
  selected: KissSelectOptionComponent[] = [];

  /**
   * LABEL
   */
  label: string = "";

  /**
   * Set the attr.tabIndex
   */
  @Input() tabindex: string = "0";

  /**
   * Adds a class and controls if select is disabled
   */
  @Input() @HostBinding("class.kiss-disabled") set disabled(value: any) {
    this._disabled = value || value === "";

    this.tabindex = this.disabled ? "" : "0";
  }

  _disabled: boolean = false;
  get disabled() {
    return this._disabled;
  }

  @ContentChildren(KISS_SELECT_OPTION_TOKEN)
  options: QueryList<KissSelectOptionComponent>;

  /**
   * Select placeholder
   */
  @Input() placeholder: string = "";

  /**
   * Enable/Disable  filtering options
   */
  @Input() filter = false;

  //OPEN
  @HostBinding("class.kiss-select--open") _open = false;
  @Input() set open(value: boolean) {
    this._setupOpen(value);
  }

  get open() {
    return this._open;
  }

  //multiple
  _multiple: boolean = false;
  /**
   * Enable/Disable selection of multiple options
   */
  @Input() set multiple(value: any) {
    this._multiple = value || value === "";
  }

  get multiple() {
    return this._multiple;
  }

  /**
   * Enable/Disable toggle all button
   * when multiple selection is enabled
   *
   * Default: `true`
   */
  @Input() toggleAll = true;

  //focused
  @Input() set focus(value: any) {
    this._focus = value || value === "";
    this._renderer.setProperty(this._elRef.nativeElement, "focus", this._focus);
  }

  @HostBinding("class.kiss-select--focus") _focus = false;
  get focus() {
    return this._focus;
  }

  /**
   * Allow using custom icons
   */
  @ContentChild(KissIconPreDirective) preIconDirective: KissIconPreDirective;
  @ContentChild(KissIconPostDirective) postIconDirective: KissIconPostDirective;

  /**
   * Emits value on change event
   */
  @Output() onSelectChange: EventEmitter<{ value: any }> = new EventEmitter();

  @Input() dropdownClass: string = "";

  /**
   * Enable/Disable showing the header template
   */
  @Input() enableHeaderTemplate: boolean = false;

  /**
   * Enable/Disable dropdown overflow
   */
  @Input() handleOverflow: boolean = true;

  /**
   * Inherit parent width
   */
  @Input() inheritWidth: boolean = true;

  /**
   * Add text in case if there are no options found
   */
  @Input() showEmptySelectText: boolean = true;
  @Input() emptySelectText: string;

  private _selectedChange: Subject<void>;
  private _unsubscribeAll: Subject<void>;
  private _rendererKeydown: any;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _renderer: Renderer2,
    private _elRef: ElementRef
  ) {
    this._unsubscribeAll = new Subject();
    this._selectedChange = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * AfterContentInit
   */
  ngAfterContentInit(): void {
    this._selectedChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this._updateSelected();

      this._updateLabel();

      this._writeNewValue();

      this._cdr.markForCheck();

      this.onSelectChange.next(this.value);
    });

    this.options.changes.pipe(startWith(""), takeUntil(this._unsubscribeAll)).subscribe(() => {
      this._listenForOptionStateChange();

      setTimeout(() => {
        // AVOID EXPRESSION CHANGED ERROR
        this._preselectValue(this.value);
      });
    });
  }

  /**
   * OnDestroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._removeKeydownListener();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Method that runs when open input is changed
   * @param boolean
   */
  private _setupOpen(value: boolean) {
    this._open = value;

    if (this._open) {
      this.toggleOpen();
    } else {
      this.toggleClose();
    }
  }

  /**
   * method that listenens for option click
   */
  private _listenForOptionStateChange() {
    const changedOrDestroyed = scheduled([this.options.changes, this._unsubscribeAll], asyncScheduler).pipe(mergeAll());

    const optionsStateChanges = [...this.options.toArray().map((option) => option.onStateChange)];

    //PIPE HAS TO BE IN THIS ORDER TO UNSUBSCRIBE SUCCESSFULLY
    scheduled(optionsStateChanges, asyncScheduler)
      .pipe(mergeAll(), takeUntil(changedOrDestroyed))
      .subscribe((option: KissSelectOptionComponent) => {
        this._selectedChange.next();

        if (option.selected && !this.multiple) {
          this.toggleClose();
        }
      });
  }

  /**
   * Update selected if one option is selected
   *
   */
  private _updateSelected() {
    if (this.disabled || !this.options?.length) return;

    if (!this.multiple && this.selected?.length) {
      for (const item of this.selected) {
        item.setSelected(false);
      }
    }

    this.selected = [];

    const options = this.options.toArray();
    for (const option of options) {
      if (option.selected) {
        this.selected.push(option);
      }
    }
  }

  /**
   * Update selection when value is changed
   */
  private _preselectValue(newValue) {
    if (!this.options?.length) return;
    this._resetOptionsAndSelected();

    const optionsArray = this.options.toArray();

    if (this.multiple && Array.isArray(newValue)) {
      for (const option of optionsArray) {
        for (const value of newValue) {
          //compare options and check if it exists inside selected
          if (this.selected.indexOf(option) !== -1 || option.value !== value) continue;

          option.setSelected(true);
          this.selected.push(option);
        }
      }
    } else {
      for (const option of optionsArray) {
        //compare options and check if it exists inside selected
        if (this.selected.indexOf(option) !== -1 || option.value !== newValue) continue;

        option.setSelected(true);
        this.selected.push(option);
      }
    }

    this._updateLabel();

    this._cdr.markForCheck();
  }

  private _resetOptionsAndSelected() {
    for (const options of this.options) {
      options.setSelected(false);
    }

    this.selected = [];
  }

  /**
   * Update label
   */
  private _updateLabel() {
    this.label = this.selected.map((option) => option.getLabel()).join(", ");
  }

  /**
   * Get the value from all selected options
   */
  private _getValueFromSelected() {
    const values = this.selected.map((option) => option?.value);

    if (this.multiple) {
      return values;
    } else {
      return values?.length ? values[0] : undefined;
    }
  }

  /**
   * Trigger update on the new value
   */
  private _writeNewValue() {
    const newValue = this._getValueFromSelected();
    this.writeValue(newValue);
  }

  private _setFocus() {
    this._focus = true;
  }

  private _setFocusOnOpen() {
    setTimeout(() => {
      let child;

      if (this.filter) {
        child = this.search?.nativeElement;
      } else if (this.optionContainerRef) {
        child = this.optionContainerRef?.nativeElement?.firstChild;
      } else {
        child = this.container?.nativeElement?.firstChild;
      }

      if (child?.focus) {
        child.focus();
      }
    });
  }

  private _setFocusOnNextSibling() {
    const exists = this.optionContainerRef?.nativeElement;
    if (!exists) return;

    let sibling: any;
    const containsFocus = !this.optionContainerRef?.nativeElement?.contains(document.activeElement);
    if (containsFocus) {
      sibling = this.optionContainerRef?.nativeElement?.firstChild;
    } else {
      sibling = document.activeElement.nextSibling;
    }

    if (sibling?.focus) {
      sibling.focus();
    }
  }

  private _setFocusOnPrevSibling() {
    const exists = this.optionContainerRef?.nativeElement;
    if (!exists) return;

    let sibling: any;
    const containsFocus = !this.optionContainerRef?.nativeElement?.contains(document.activeElement);
    if (containsFocus) {
      sibling = this.optionContainerRef?.nativeElement?.firstChild;
    } else {
      sibling = document.activeElement.previousSibling;
    }

    if (sibling?.focus) {
      sibling.focus();
    }
  }

  private _setFocusOnClose() {
    this._elRef.nativeElement.focus();

    this._setFocus();
  }

  private _setBlur() {
    this._focus = false;
    this.onTouched();
  }

  /**
   * Set all options to `hidden=false`
   */
  private _showAllOptions() {
    if (!this.filter) return;
    const options = this.options.toArray();
    for (let option of options) {
      option.hidden = false;
    }
  }

  private _listenOpenEvents() {
    //listen for keydown on the open menu
    this._rendererKeydown = this._renderer.listen(window, "keydown", (event) => {
      const container = this.container?.nativeElement;
      const contains = container && container.contains(document.activeElement);
      const tabTrap = this.tabTrap?.nativeElement;
      const optionLength = this.options.toArray().length;

      if (event.key === "Escape" && (contains || !optionLength)) {
        this.toggleClose();

        this._setFocusOnClose();
        return;
      }

      if (event.keyCode == "38" && optionLength) {
        // up arrow
        this._setFocusOnPrevSibling();
      }

      if (event.keyCode == "40" && optionLength) {
        // down arrow
        this._setFocusOnNextSibling();
      }

      if (tabTrap === document.activeElement || !optionLength) {
        this.toggleClose();

        this._setFocusOnClose();
      }
    });
  }

  private _removeKeydownListener() {
    if (this._rendererKeydown) this._rendererKeydown();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open select
   */
  toggleOpen() {
    if (this.disabled) return;
    this._open = true;
    this._setFocus();
    this._setFocusOnOpen();
    this._listenOpenEvents();

    this._cdr.markForCheck();
  }

  /**
   * Close select
   */
  toggleClose() {
    this._open = false;
    this._setBlur();

    this._removeKeydownListener();

    this._cdr.detectChanges();
    this._showAllOptions();
  }

  /**
   * Opens the dropdown when kiss-select is clicked
   */
  onSelectClick() {
    this.toggleOpen();
  }

  /**
   * If element is not focused on
   */
  onFocus() {
    if (this.disabled) return;
    this._setFocus();
  }

  /**
   * If element is blured
   */
  onBlur() {
    if (this.open) return;
    this._setBlur();
  }

  /**
   * On Select All checkbox click
   */
  onToggleSelectAll(event: boolean) {
    const options = this.options.toArray();

    for (const option of options) {
      option.setSelected(event);
    }

    this._selectedChange.next();
  }

  /**
   * SEARCH EVENT
   *
   * Hide option if search value matches the label
   */
  onSearch(event) {
    const options = this.options.toArray();
    for (const option of options) {
      if (!event.value) {
        option.hidden = false;
      } else {
        const label = option?.getLabel()?.toLowerCase();
        option.hidden = !label.includes(event.value.toLowerCase());
      }
    }
  }

  onContainerClick() {
    this.toggleClose();
  }
}
