import { Component } from "@angular/core";

@Component({
  selector: "kiss-card",
  template: "<ng-content></ng-content>",
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  host: {
    class: "kiss-card"
  }
})
export class KissCardComponent {
  constructor() {}
}
