import { gql } from "apollo-angular";

export const STRATEGIC_GOAL_FRAGMENT = gql`
  fragment StrategicGoalFragment on StrategicGoal {
    createdAt
    description
    id
    name
    updatedAt
  }
`;
