import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { KissContentComponent } from './kiss-content.component';

@NgModule({
  declarations: [KissContentComponent],
  imports: [RouterModule, CommonModule],
  exports: [KissContentComponent],
})
export class KissContentModule {}
