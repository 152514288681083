import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2 } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => KissToggleSwitchComponent),
  multi: true
};

@Component({
  selector: "kiss-toggle-switch",
  templateUrl: "./kiss-toggle-switch.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "kiss-toggle-switch",
    "[attr.tabindex]": "tabindex",
    "(keyup.enter)": "onEnter()"
  },
  providers: [VALUE_ACCESSOR]
})
export class KissToggleSwitchComponent implements ControlValueAccessor {
  // -----------------------------------------------------------------------------------------------------
  // @ BUILT IN
  // -----------------------------------------------------------------------------------------------------

  // Step 3: Copy paste this stuff here
  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * Invoked when the model is disabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ CUSTOM
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle checkbox
   */
  private _checked: boolean = false;
  @Input() set checked(value: boolean) {
    this._checked = value;
    this._updateCheckedClass(this._checked);
  }

  get checked(): boolean {
    return this._checked;
  }

  private _disabled: boolean = false;
  /**
   * ENABLE/DISABLE checkbox
   */
  @Input() set disabled(value: any) {
    this._disabled = value || value === "";
    this._updateDisabledClass(this._disabled);
  }

  get disabled() {
    return this._disabled;
  }

  /**
   * Set the attr.tabIndex
   */
  @Input() tabindex: string = "0";

  /**
   * Event that triggers when checkbox value changes
   */
  @Output() onValueChange: EventEmitter<boolean> = new EventEmitter();

  // Step 4: Define what should happen in this component, if something changes outside
  writeValue(checked: boolean) {
    this.checked = checked;
  }

  constructor(
    private _renderer: Renderer2,
    private _elRef: ElementRef
  ) {}

  private _updateCheckedClass(checked: boolean) {
    if (checked) {
      this._renderer.addClass(this._elRef.nativeElement, "checked");
      this._renderer.setAttribute(this._elRef.nativeElement, "checked", "");
    } else {
      this._renderer.removeClass(this._elRef.nativeElement, "checked");
      this._renderer.removeAttribute(this._elRef.nativeElement, "checked");
    }
  }

  private _updateDisabledClass(disabled: boolean) {
    if (disabled) {
      this._renderer.addClass(this._elRef.nativeElement, "disabled");
      this._renderer.setAttribute(this._elRef.nativeElement, "disabled", "");
    } else {
      this._renderer.removeClass(this._elRef.nativeElement, "disabled");
      this._renderer.removeAttribute(this._elRef.nativeElement, "disabled");
    }
  }

  onModelChange(value: boolean) {
    // Step 5a: bind the changes to the local value
    this.checked = value;

    // Step 5b: Handle what should happen on the outside, if something changes on the inside
    this.onChange(this.checked);

    this.onValueChange.next(this.checked);
  }

  onEnter() {
    if (this.disabled) return;

    this.checked = !this.checked;

    this.onChange(this.checked);

    this.onValueChange.next(this.checked);
  }
}
