import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KissOverlay } from './kiss-overlay';
import { KissOverlayRef } from './kiss-overlay-ref';
import { KissOverlayService } from './kiss-overlay.service';

type OverlayMapType = Map<KissOverlayRef, KissOverlay>;

/**
 * Component used to create overlays for the `KISS` library
 *
 * Component `kiss-overlay-item` is used so `ChangeDetectionStrategy.OnPush`
 * does not affect the templates
 */
@Component({
  selector: 'kiss-overlay',
  templateUrl: './kiss-overlay.component.html',
  styleUrls: ['./kiss-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'kiss-overlay',
  },
})
export class KissOverlayComponent implements OnDestroy {
  overlays: OverlayMapType = new Map();
  keysToArr: any = [];

  private _unsubscribeAll: Subject<void>;
  constructor(private _kissOverlayService: KissOverlayService, private _cdr: ChangeDetectorRef) {
    this._unsubscribeAll = new Subject();
    this._init();
  }

  //-------------------------------------
  // Lifecycle hooks
  //-------------------------------------

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._removeAllOverlays();
  }

  //-------------------------------------
  // PRIVATE METHODS
  //-------------------------------------

  private _init() {
    this._kissOverlayService.onOverlayCreated
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((overlay) => {
        this._insertOverlay(overlay);
      });

    this._kissOverlayService.onOverlayRemoved
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((overlayRef) => this._removeOverlay(overlayRef));
  }

  private _insertOverlay(overlay: KissOverlay): void {
    if (this.overlays.has(overlay.ref)) return;

    this.overlays.set(overlay.ref, overlay);

    this.updateKeys();

    this._cdr.detectChanges();
  }

  private _removeOverlay(overlayRef: KissOverlayRef): void {
    if (!this.overlays.has(overlayRef)) return;
    overlayRef.overlayRemoved();

    this.overlays.delete(overlayRef);
    setTimeout(() => {
      //Avoiding viewRef not updating when multiple overlays are removed very fast

      this.updateKeys();

      this._cdr.markForCheck();
    });
  }

  private _removeAllOverlays() {
    for (const ref of this._getOverlaysArray()) {
      ref.overlayRemoved();
    }

    this.overlays.clear();

    this.updateKeys();
  }

  private _getOverlaysArray(): any[] {
    return Array.from(this.overlays.keys());
  }

  //-------------------------------------
  // PUBLIC METHODS
  //-------------------------------------

  onContainerClicked(event: Event, itemContainer: any, overlayRef: KissOverlayRef) {
    if (!itemContainer.contains(event.target)) {
      overlayRef.containerClicked();
    }
  }

  updateKeys() {
    this.keysToArr = Array.from(this.overlays.keys());
  }
}
