import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Host,
  HostBinding,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { KissSelectComponent } from '../kiss-select.component';
import { KISS_OPTION_HEADER_TEMPLATE_DIRECTIVE_TOKEN } from '../directives/kiss-select-option-header-content.directive';

export const KISS_SELECT_OPTION_TOKEN = new InjectionToken<KissSelectOptionComponent>(
  'kiss-select-option-token'
);

@Component({
  selector: 'kiss-select-option',
  templateUrl: './kiss-select-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'kiss-select__option',
    '(click)': 'onClicked($event)',
    '[attr.tabindex]': 'tabindex',
  },
  providers: [
    {
      provide: KISS_SELECT_OPTION_TOKEN,
      useExisting: KissSelectOptionComponent,
    },
  ],
})
export class KissSelectOptionComponent implements OnInit, OnDestroy {
  @HostBinding('class.kiss-select__option--multiple') multiple = false;

  @Input() tabindex = '0';

  /**
   * Set option value
   */
  @Input() value: any;

  /**
   * Set option as disabled
   */
  @Input() set disabled(value: boolean) {
    this._disabled = value;

    this.tabindex = value ? '0' : '';

    this._toggleClass({ toggle: this._disabled }, 'kiss-select__option--disabled');
  }

  _disabled: boolean = false;
  get disabled() {
    return this._disabled;
  }

  /**
   * Set option as selected
   */
  @Input()
  set selected(value: boolean) {
    this._selected = value;
    this._toggleClass({ toggle: this._selected }, 'kiss-select__option--selected');
  }

  _selected: boolean = false;
  get selected() {
    return this._selected;
  }

  /**
   * Set option as hidden
   */

  @Input()
  set hidden(value: boolean) {
    this._hidden = value;
    this._toggleClass({ toggle: this._hidden }, 'kiss-select__option--hidden');
  }

  _hidden: boolean = false;
  get hidden() {
    return this._hidden;
  }

  private _showCheckbox: boolean = false;
  @Input() set showCheckbox(value: boolean) {
    this._showCheckbox = value;
    this._toggleClass({ toggle: this._showCheckbox }, 'kiss-select__option--checkbox-shown');
  }

  get showCheckbox(): boolean {
    return this._showCheckbox;
  }

  /**
   * Event emitter used to notify the parent when the child is clicked
   *
   * @returns {undefined} undefined
   */
  @Output() onStateChange: EventEmitter<KissSelectOptionComponent> =
    new EventEmitter<KissSelectOptionComponent>();

  @ContentChild(KISS_OPTION_HEADER_TEMPLATE_DIRECTIVE_TOKEN)
  headerTemplate: any;

  private _onKeydownListener: any;
  constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    @Host() private parent: KissSelectComponent,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    //PARENT USED TO AVOID CHANGES AFTER CHECKED WHEN INITIALIZED
    //(TODO) SHOULD BE UPDATED IN THE FUTURE
    this.multiple = this.parent?.multiple;

    this._listenForEvents();
  }

  onClicked(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.disabled) return;

    this.selected = !this.selected;
    this._stateChanged();
  }

  setSelected(value: boolean) {
    this.selected = value;
    this._cdr.markForCheck();
  }

  setMultiple(value: boolean) {
    this.multiple = value;
    this._cdr.markForCheck();
  }

  /** Get the label for this element which is required by the FocusableOption interface. */
  getLabel() {
    // we know that the current node is an element type
    const clone = this._elRef.nativeElement.cloneNode(true) as Element;
    return clone.textContent?.trim() || '';
  }

  /**
   * USING RENDERER TO AVOID EXPRESSION CHANGED AFTER CHECKED WITH HOSTBINDING
   * @param option
   * @param className
   */
  private _toggleClass(option: { toggle: boolean }, className: string) {
    if (option?.toggle) {
      this._renderer.addClass(this._elRef.nativeElement, className);
    } else {
      this._renderer.removeClass(this._elRef.nativeElement, className);
    }
  }

  private _listenForEvents() {
    this._onKeydownListener = this._renderer.listen(
      this._elRef.nativeElement,
      'keyup',
      (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          this.onClicked(event);
        }
      }
    );
  }

  private _stateChanged() {
    this.onStateChange.next(this);
  }

  ngOnDestroy(): void {
    if (this._onKeydownListener) this._onKeydownListener();
    this.onStateChange.complete();
  }
}
