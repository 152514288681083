const DEFAULT_SETTINGS = {
  enabled: false,
  showHours: true,
  showMinutes: true,
  showSeconds: true,
  showMilliseconds: false,
  minuteIncrement: 1
};

export class KissDatepickerTimeSettings {
  enabled: boolean;
  showHours: boolean;
  showMinutes: boolean;
  showSeconds: boolean;
  showMilliseconds: boolean;
  minuteIncrement?: number;

  constructor(data: any) {
    data = data || {};

    this.enabled = data.enabled ?? DEFAULT_SETTINGS.enabled;
    this.showHours = data.showHours ?? DEFAULT_SETTINGS.showHours;
    this.showMinutes = data.showMinutes ?? DEFAULT_SETTINGS.showMinutes;
    this.showSeconds = data.showSeconds ?? DEFAULT_SETTINGS.showSeconds;
    this.showMilliseconds = data.showMilliseconds ?? DEFAULT_SETTINGS.showMilliseconds;
    this.minuteIncrement = data.minuteIncrement ?? DEFAULT_SETTINGS.minuteIncrement;
  }
}
