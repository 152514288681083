<div class="kiss-toolbar__item">
  <!-- <img src="assets/general/logo.svg" style="margin-left: 30px" /> -->
  <!-- <div class="input_div">
    <input class="elastic_input" placeholder="Search" />
    <img src="assets/kiss_toolbar/search.svg" class="absolute_search" />
  </div> -->
</div>

<!-- <div class="kiss-toolbar__actions" *ngIf="user">
  <div class="kiss-toolbar__user"> -->
<!-- USER-->
<!-- <button #menuTrigger class="kiss-toolbar__user-btn">
      <div class="kiss-toolbar__user-container">
        <img [src]="user?.image || '/assets/svg/icons/no-profile-picture.svg'" />
      </div>
      <kiss-svg-icon icon="caret-down" class="ooblee-btn__icon"></kiss-svg-icon>
    </button>
    <kiss-menu #menu="kissMenu" [menuTrigger]="menuTrigger">
      <div style="width: auto">
        <button kiss-menu-item style="cursor: default">
          <div style="display: flex; padding: 20px 0">
            <div class="kiss-toolbar__user-container">
              <img [src]="user?.image || '/assets/svg/icons/no-profile-picture.svg'" />
            </div>
            <div style="display: flex; flex-direction: column; align-items: flex-start">
              <span style="font-weight: 600; padding-left: 10px">{{ user.name }}</span>
              <span style="padding-left: 10px">{{ user.email }}</span>
            </div>
          </div>
        </button>
      </div>
      <div style="width: auto; border-bottom: 0.2px solid #d5d5d5; border-top: 0.2px solid #d5d5d5">
        <button (click)="onChangePassword()" kiss-menu-item>
          <div style="width: 12px">
            <img style="width: 18px" src="assets/product%20catalog/pen-to-square.svg" />
          </div>

          <span>Change password</span>
        </button>
      </div>
      <div style="width: auto">
        <button (click)="onLogout()" kiss-menu-item>
          <div style="width: 12px">
            <img style="width: 18px" src="assets/kiss_toolbar/right-from-bracket.svg" />
          </div>

          <span>Log out</span>
        </button>
      </div>
    </kiss-menu>
  </div> -->

<!-- !USER -->
<!-- </div> -->
