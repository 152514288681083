import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SafeHtmlPipeModule } from '@kiss/pipes/safe-html';
import { KissSvgIconModule } from '../kiss-svg-icon/kiss-svg-icon.module';
import { KissBreadcrumbsSettings } from './kiss-breadcrumb-settings.type';
import { KissBreadcrumbsComponent } from './kiss-breadcrumbs.component';
import { KissBreadcrumbsSettingsService } from './services/kiss-breadcrumbs-settings.service';
import { KissBreadcrumbService } from './services/kiss-breadcrumbs.service';

@NgModule({
  declarations: [KissBreadcrumbsComponent],
  imports: [CommonModule, RouterModule, SafeHtmlPipeModule, KissSvgIconModule],
  providers: [KissBreadcrumbService],
  exports: [KissBreadcrumbsComponent],
})
export class KissBreadcrumbsModule {
  static forRoot?(settings?: KissBreadcrumbsSettings): ModuleWithProviders<KissBreadcrumbsModule> {
    if (!settings) {
      return {
        ngModule: KissBreadcrumbsModule,
      };
    }

    return {
      ngModule: KissBreadcrumbsModule,
      providers: [KissBreadcrumbsSettingsService, { provide: 'kissBreadcrumbSettings', useValue: settings }],
    };
  }
}
