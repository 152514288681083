import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, ControlContainer, FormBuilder } from "@angular/forms";
import { COMPANY_COLOR_MAP } from "app/shared/services/company-color/company-color.map";
import { KissLoadingBarService } from "@kiss/components/common";
import { ApiConstants } from "app/shared/constants/api.constants";

@Component({
  selector: "app-company-onboarding-logo-colors",
  templateUrl: "./company-onboarding-logo-colors.component.html",
  styleUrl: "./company-onboarding-logo-colors.component.scss"
})
export class CompanyOnboardingLogoColorsComponent implements OnInit {
  form: FormGroup;
  imagePreview: string;
  colors = Object.entries(COMPANY_COLOR_MAP).map(([key, color]) => ({
    id: key,
    base: color.base,
    active: false
  }));
  @Input() data: any;

  @Output() onSkip = new EventEmitter();
  @Output() onNext = new EventEmitter();

  constructor(
    private controlContainer: ControlContainer,
    private _fb: FormBuilder,
    private http: HttpClient,
    private kissLoader: KissLoadingBarService
  ) {}

  ngOnInit(): void {
    this.form = <FormGroup>this.controlContainer.control;
    this.colors.forEach((c) => {
      if (this.form.get("companyColors").value === c.id) {
        c.active = true;
      }
    });

    if (this.data?.logo?.accessToken) {
      this.imagePreview = ApiConstants.API_MEDIA_PREVIEW + "/" + this.data.logo.accessToken;
    }
  }

  toggleActive(color: any) {
    this.colors.forEach((c) => (c.active = false));
    color.active = true;
    this.form.controls.companyColors.patchValue(color.id);
  }

  onClickSkip() {
    this.onSkip.next(true);
  }

  onClickNext() {
    this.onNext.next(true);
  }
}
