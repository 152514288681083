import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";

@Directive({
  selector: "[iconFallback]"
})
export class IconFallbackDirective implements OnInit {
  @Input("iconFallbackName") iconFallbackName: string;

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const pseudoEl = window.getComputedStyle(this._el.nativeElement, ":before");

    if (!pseudoEl["content"] || pseudoEl["content"] === "none") {
      this._renderer.addClass(this._el.nativeElement, this.iconFallbackName);
    }
  }
}
