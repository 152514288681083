import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KissTableModule } from "@kiss/components/common";
import { FilesUploadComponent } from "./files-upload.component";
import { DocumentIconsComponent } from "../document-icons/document-icons.component";

@NgModule({
  declarations: [FilesUploadComponent],
  imports: [CommonModule, KissTableModule, DocumentIconsComponent],
  exports: [FilesUploadComponent]
})
export class FilesUploadModule {}
