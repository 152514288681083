import { Component, Input, OnDestroy } from '@angular/core';
import { KissNavigationService } from '@kiss/components/common/kiss-navigation/services/kiss-navigation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutVerticalClassic } from './types/layout-vertical-classic.types';

@Component({
  selector: 'layout-vertical-classic',
  templateUrl: './layout-vertical-classic.component.html',
  styleUrls: ['./layout-vertical-classic.component.scss'],
})
export class LayoutVerticalClassicComponent implements OnDestroy {
  @Input() config: LayoutVerticalClassic;

  private _unsubscribeAll: Subject<void>;
  constructor(private _navigationService: KissNavigationService) {
    this._unsubscribeAll = new Subject();

    this._navigationService.onItemCollapseToggled
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.config.navigationSidebar.expand = true;
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
