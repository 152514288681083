export enum KickoffStatusEnum {
  SCHEDULED = "SCHEDULED",
  COMPLETED = "COMPLETED",
  OVERDUE = "OVERDUE"
}

export const KickoffStatusEnumValues: {
  [key in KickoffStatusEnum]: string;
} = {
  [KickoffStatusEnum.COMPLETED]: "Completed",
  [KickoffStatusEnum.OVERDUE]: "Overdue",
  [KickoffStatusEnum.SCHEDULED]: "Scheduled"
};