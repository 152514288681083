export class KissTableHeaderSettings {
  sticky?: boolean;

  constructor(data: any) {
    data = data || {};

    this.sticky = data.sticky || undefined;
  }
}

export class KissTableSettings {
  name?: string;
  saveResize?: boolean;
  type?: "table" | "flex";
  header?: KissTableHeaderSettings;
  trackBy?: (index: number, data: any) => any;

  constructor(data: any) {
    data = data || {};
    this.name = data.name || undefined;
    this.saveResize = data.saveResize || false;
    this.type = data.type || "flex";
    this.header = new KissTableHeaderSettings(data?.header);
    this.trackBy = data.trackBy || undefined;
  }
}
