export const navigationIcons = {
  dashobard: `<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3333 9.58268V3.91602C18.3333 2.66602 17.8 2.16602 16.475 2.16602H13.1083C11.7833 2.16602 11.25 2.66602 11.25 3.91602V9.58268C11.25 10.8327 11.7833 11.3327 13.1083 11.3327H16.475C17.8 11.3327 18.3333 10.8327 18.3333 9.58268Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3333 17.084V15.584C18.3333 14.334 17.8 13.834 16.475 13.834H13.1083C11.7833 13.834 11.25 14.334 11.25 15.584V17.084C11.25 18.334 11.7833 18.834 13.1083 18.834H16.475C17.8 18.834 18.3333 18.334 18.3333 17.084Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.74935 11.416V17.0827C8.74935 18.3327 8.21602 18.8327 6.89102 18.8327H3.52435C2.19935 18.8327 1.66602 18.3327 1.66602 17.0827V11.416C1.66602 10.166 2.19935 9.66602 3.52435 9.66602H6.89102C8.21602 9.66602 8.74935 10.166 8.74935 11.416Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.74935 3.91602V5.41602C8.74935 6.66602 8.21602 7.16602 6.89102 7.16602H3.52435C2.19935 7.16602 1.66602 6.66602 1.66602 5.41602V3.91602C1.66602 2.66602 2.19935 2.16602 3.52435 2.16602H6.89102C8.21602 2.16602 8.74935 2.66602 8.74935 3.91602Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  userManagement: `<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.63411 9.55768C7.55078 9.54935 7.45078 9.54935 7.35911 9.55768C5.37578 9.49102 3.80078 7.86602 3.80078 5.86602C3.80078 3.82435 5.45078 2.16602 7.50078 2.16602C9.54245 2.16602 11.2008 3.82435 11.2008 5.86602C11.1924 7.86602 9.61745 9.49102 7.63411 9.55768Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.6747 3.83398C15.2914 3.83398 16.5914 5.14232 16.5914 6.75065C16.5914 8.32565 15.3414 9.60898 13.7831 9.66732C13.7164 9.65898 13.6414 9.65898 13.5664 9.66732" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.46758 12.634C1.45091 13.984 1.45091 16.184 3.46758 17.5257C5.75924 19.059 9.51758 19.059 11.8092 17.5257C13.8259 16.1757 13.8259 13.9757 11.8092 12.634C9.52591 11.109 5.76758 11.109 3.46758 12.634Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.2832 17.166C15.8832 17.041 16.4499 16.7993 16.9165 16.441C18.2165 15.466 18.2165 13.8577 16.9165 12.8827C16.4582 12.5327 15.8999 12.2993 15.3082 12.166" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  events:
    '<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.66797 2.16797V4.66797" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.332 2.16797V4.66797" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M2.91797 8.07422H17.0846" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.5 7.58464V14.668C17.5 17.168 16.25 18.8346 13.3333 18.8346H6.66667C3.75 18.8346 2.5 17.168 2.5 14.668V7.58464C2.5 5.08464 3.75 3.41797 6.66667 3.41797H13.3333C16.25 3.41797 17.5 5.08464 17.5 7.58464Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.0801 11.9167H13.0875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.0801 14.4167H13.0875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.99607 11.9167H10.0036" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.99607 14.4167H10.0036" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.91209 11.9167H6.91957" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.91209 14.4167H6.91957" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
  supprtingDocuments: `<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.166 11.6673V14.1673C14.166 17.5007 12.8327 18.834 9.49935 18.834H6.33268C2.99935 18.834 1.66602 17.5007 1.66602 14.1673V11.0007C1.66602 7.66732 2.99935 6.33398 6.33268 6.33398H8.83268" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.1673 11.6673H11.5007C9.50065 11.6673 8.83398 11.0007 8.83398 9.00065V6.33398L14.1673 11.6673Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.66602 2.16602H12.9993" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.83398 4.66602C5.83398 3.28268 6.95065 2.16602 8.33398 2.16602H10.5173" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3339 7.16602V12.3243C18.3339 13.616 17.2839 14.666 15.9922 14.666" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.334 7.16602H15.834C13.959 7.16602 13.334 6.54102 13.334 4.66602V2.16602L18.334 7.16602Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  logout: `<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.91602 6.29922C8.17435 3.29922 9.71602 2.07422 13.091 2.07422H13.1993C16.9243 2.07422 18.416 3.56589 18.416 7.29089V12.7242C18.416 16.4492 16.9243 17.9409 13.1993 17.9409H13.091C9.74102 17.9409 8.19935 16.7326 7.92435 13.7826" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.0009 10H3.51758" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.37565 7.20898L2.58398 10.0007L5.37565 12.7923" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  anayltics: `<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.66602 18.834H18.3327" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.125 3.83268V18.8327H11.875V3.83268C11.875 2.91602 11.5 2.16602 10.375 2.16602H9.625C8.5 2.16602 8.125 2.91602 8.125 3.83268Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.5 8.83268V18.8327H5.83333V8.83268C5.83333 7.91602 5.5 7.16602 4.5 7.16602H3.83333C2.83333 7.16602 2.5 7.91602 2.5 8.83268Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.166 13.0007V18.834H17.4993V13.0007C17.4993 12.084 17.166 11.334 16.166 11.334H15.4993C14.4993 11.334 14.166 12.084 14.166 13.0007Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  userEdit: `<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.4987 9.99935C12.7999 9.99935 14.6654 8.13387 14.6654 5.83268C14.6654 3.5315 12.7999 1.66602 10.4987 1.66602C8.19751 1.66602 6.33203 3.5315 6.33203 5.83268C6.33203 8.13387 8.19751 9.99935 10.4987 9.99935Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.5066 13.1162L13.5566 16.0662C13.4399 16.1829 13.3316 16.3995 13.3066 16.5579L13.1482 17.6828C13.0899 18.0912 13.3732 18.3745 13.7816 18.3162L14.9066 18.1579C15.0649 18.1329 15.2899 18.0245 15.3982 17.9079L18.3482 14.9579C18.8566 14.4495 19.0982 13.8579 18.3482 13.1079C17.6066 12.3662 17.0149 12.6079 16.5066 13.1162Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.082 13.541C16.332 14.441 17.032 15.141 17.932 15.391" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.33984 18.3333C3.33984 15.1083 6.5482 12.5 10.4982 12.5C11.3649 12.5 12.1982 12.625 12.9732 12.8583" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  settings: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.334 5.41602H13.334" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.99935 5.41602H1.66602" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.33268 8.33333C9.94351 8.33333 11.2493 7.0275 11.2493 5.41667C11.2493 3.80584 9.94351 2.5 8.33268 2.5C6.72185 2.5 5.41602 3.80584 5.41602 5.41667C5.41602 7.0275 6.72185 8.33333 8.33268 8.33333Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3333 14.584H15" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.66602 14.584H1.66602" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.6667 17.4993C13.2775 17.4993 14.5833 16.1935 14.5833 14.5827C14.5833 12.9719 13.2775 11.666 11.6667 11.666C10.0558 11.666 8.75 12.9719 8.75 14.5827C8.75 16.1935 10.0558 17.4993 11.6667 17.4993Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  companies: `<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3077 2.29219L17.8077 5.29217C18.0993 5.40883 18.3327 5.75883 18.3327 6.06716V8.83383C18.3327 9.29217 17.9577 9.66717 17.4993 9.66717H2.49935C2.04102 9.66717 1.66602 9.29217 1.66602 8.83383V6.06716C1.66602 5.75883 1.89935 5.40883 2.19102 5.29217L9.69102 2.29219C9.85769 2.22552 10.141 2.22552 10.3077 2.29219Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3327 18.8333H1.66602V16.3333C1.66602 15.875 2.04102 15.5 2.49935 15.5H17.4993C17.9577 15.5 18.3327 15.875 18.3327 16.3333V18.8333Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.33398 15.4993V9.66602" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.66602 15.4993V9.66602" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 15.4993V9.66602" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.334 15.4993V9.66602" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.666 15.4993V9.66602" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M0.833984 18.834H19.1673" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 7.58398C10.6904 7.58398 11.25 7.02434 11.25 6.33398C11.25 5.64363 10.6904 5.08398 10 5.08398C9.30964 5.08398 8.75 5.64363 8.75 6.33398C8.75 7.02434 9.30964 7.58398 10 7.58398Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  focusAreas: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
<path fill="currentColor" d="M361.5 218.4c4.2 11.8 6.5 24.4 6.5 37.6c0 61.9-50.1 112-112 112s-112-50.1-112-112s50.1-112 112-112c13.2 0 25.9 2.3 37.6 6.5l4.9-4.9-7.6-45.8C279.6 97.3 268 96 256 96C167.6 96 96 167.6 96 256s71.6 160 160 160s160-71.6 160-160c0-12-1.3-23.6-3.8-34.9l-45.8-7.6-4.9 4.9zm97.4-8.1c3.3 14.7 5 30 5 45.7c0 114.9-93.1 208-208 208S48 370.9 48 256S141.1 48 256 48c15.7 0 31 1.7 45.7 5l38.8-38.8C314 5 285.6 0 256 0C114.6 0 0 114.6 0 256S114.6 512 256 512s256-114.6 256-256c0-29.6-5-58-14.3-84.5L459 210.3zm-93.9-29.4l50.7 8.4c10.2 1.7 20.6-1.6 27.9-8.9l51.2-51.2c8.1-8.1 5.3-21.9-5.4-26.2L432 80 409 22.5c-4.3-10.7-18-13.5-26.2-5.4L331.6 68.4c-7.3 7.3-10.6 17.7-8.9 27.9l8.4 50.7L239 239c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l92.1-92.1z"/>
</svg>`
};
