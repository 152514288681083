import { gql } from "apollo-angular";
import { TEAM_FRAGMENT } from "../../fragments/team.fragment";
import { USER_FRAGMENT } from "../../fragments/user.fragment";
import { CHECKIN_FRAGMENT } from "../../fragments/checkin.fragment";

export const GET_COMPANY_TEAM = gql`
  ${TEAM_FRAGMENT}
  ${USER_FRAGMENT}
  query GetCompanyTeam($companyId: Int!) {
    company(id: $companyId) {
      team {
        ...TeamFragment
      }
      companyCultureLead {
        companyId
        user {
          ...UserFragment
          status
        }
      }
    }
  }
`;

export const CREATE_TEAM = gql`
  ${TEAM_FRAGMENT}
  mutation CreateCompanyTeam($createTeamInput: CreateTeamInput!) {
    createTeam(createTeamInput: $createTeamInput) {
      ...TeamFragment
    }
  }
`;

export const UPDATE_TEAM = gql`
  ${TEAM_FRAGMENT}
  mutation UpdateCompanyTeam($teamId: Float!, $updateTeamInput: UpdateTeamInput!) {
    updateTeam(teamId: $teamId, updateTeamInput: $updateTeamInput) {
      ...TeamFragment
    }
  }
`;

export const TOGGLE_TEAM = gql`
  mutation TeamToggleStatus($teamToggleStatusId: Int!) {
    teamToggleStatus(id: $teamToggleStatusId) {
      id
    }
  }
`;

export const REMOVE_TEAM = gql`
  mutation RemoveCompanyTeam($teamId: Float!) {
    removeTeam(teamId: $teamId) {
      id
    }
  }
`;

export const REMOVE_TEAM_GROUP = gql`
  mutation RemoveCompanyTeamGroup($groupId: Float!) {
    removeGroup(groupId: $groupId) {
      id
    }
  }
`;

export const GET_SINGLE_TEAM = gql`
  ${TEAM_FRAGMENT}
  ${CHECKIN_FRAGMENT}
  query Team($teamId: Int!) {
    team(id: $teamId) {
      ...TeamFragment
      checkInSessions {
        checkInSession {
          ...CheckInFragment
        }
      }
      cultureLeads {
        user {
          name
          role
          id
          email
        }
      }
    }
  }
`;

export const GET_COMPANY_LEADS = gql`
  query CompanyCultureLeads($companyId: Float!, $search: String) {
    companyCultureLeads(companyId: $companyId, search: $search) {
      id
      name
    }
  }
`;

export const GET_TEAM_GROUPS_LEADS = gql`
  query GetCultureLeadsOfTeamsAndGroups($teamIds: [Int!]!, $groupIds: [Int!]) {
    getCultureLeadsOfTeamsAndGroups(teamIds: $teamIds, groupIds: $groupIds) {
      ... on TeamCultureLead {
        user {
          id
          name
        }
        teamId
      }
      ... on GroupCultureLeads {
        user {
          name
          id
        }
        groupId
      }
    }
  }
`;
