import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, Subject } from "rxjs";
import { CompanyColorService } from "../company-color/company-color.service";
import { CompanyModel } from "app/shared/models/company/company.model";
import { GET_COMPANY_BY_ID } from "app/shared/gql/operations/company/company.operations";
import { Apollo } from "apollo-angular";
import { StorageConstants } from "app/shared/constants/storage.constants";

@Injectable()
export class CompanyService {
  private _activeCompanyData = new BehaviorSubject<CompanyModel>(null);
  private _forceRefresh = new Subject<void>();

  constructor(
    private _companyColorService: CompanyColorService,
    private _apollo: Apollo
  ) {}

  setActiveCompany(company?: CompanyModel) {
    this._activeCompanyData.next(company);
    this._saveIdToStorage(company.id);
  }

  onCompanyChange() {
    return this._activeCompanyData.asObservable();
  }

  getActiveCompanyId() {
    const company = this.getActiveCompany();
    return company ? company.id : undefined;
  }

  clearActiveCompanyFromStorage() {
    this._companyColorService.resetColor();
    this._removeIdFromStorage();
  }

  clearActiveCompany() {
    this._companyColorService.resetColor();
    this._removeIdFromStorage();
    this._activeCompanyData.next(undefined);
  }

  /**
   * Get the saved active company
   * @returns
   */
  getActiveCompany() {
    return this._activeCompanyData.value;
  }

  onForceRefresh() {
    return this._forceRefresh.asObservable();
  }

  triggerForceRefresh() {
    this._forceRefresh.next();
  }

  private _saveIdToStorage(id: string) {
    localStorage.setItem(StorageConstants.ACTIVE_COMPANY_ID, id);
  }

  private _removeIdFromStorage() {
    localStorage.removeItem(StorageConstants.ACTIVE_COMPANY_ID);
  }

  getActiveCompanyIdFromStorage() {
    const data = localStorage.getItem(StorageConstants.ACTIVE_COMPANY_ID);
    return data ? JSON.parse(data) : undefined;
  }

  getActiveCompanyApi({ id }): Observable<CompanyModel> {
    return this._apollo
      .query({
        query: GET_COMPANY_BY_ID,
        variables: {
          companyId: +id
        }
      })
      .pipe(map((response: any) => response.data?.company));
  }
}
