import { KISS_MEDIA_OBSERVER_SCREEN_SIZE } from './types/kiss-media-observer-size.types';

export enum SCREEN_SIZE {
  SM,
  MD,
  LG,
  XL,
  XXL,
  IPHONE5,
}

export const KISS_MEDIA_OBSERVER_DEFAULT_SCREEN_SIZES: KISS_MEDIA_OBSERVER_SCREEN_SIZE[] = [
  {
    id: SCREEN_SIZE.SM,
    name: 'sm',
    minWidth: 0,
    maxWidth: 576,
  },
  {
    id: SCREEN_SIZE.MD,
    name: 'md',
    minWidth: 577,
    maxWidth: 768,
  },
  {
    id: SCREEN_SIZE.LG,
    name: 'lg',
    minWidth: 769,
    maxWidth: 1200,
  },
  {
    id: SCREEN_SIZE.XL,
    name: 'xl',
    minWidth: 1201,
    maxWidth: 1600,
  },
  {
    id: SCREEN_SIZE.XXL,
    name: 'xxl',
    minWidth: 1601,
    maxWidth: null,
  },
];

export const KISS_MEDIA_OBSERVER_CUSTOM_SCREEN_SIZES: KISS_MEDIA_OBSERVER_SCREEN_SIZE[] = [
  {
    id: SCREEN_SIZE.IPHONE5,
    name: 'iphone5',
    minWidth: 0,
    maxWidth: 320,
    minHeight: 0,
    maxHeight: 568,
    type: 'custom',
  },
];
