<div class="editable-text-control__container">
  <div
    class="editable-text-control__text"
    *ngIf="control.value || isEdit"
    contenteditable
    #childEl
    (blur)="onNameChange(childEl)"
    (focus)="setParentEditable({ triggerFocus: false })"
  >
    {{ control.value }}
  </div>

  <div class="editable-text-control__placeholder" *ngIf="!control.value && !isEdit" (click)="setParentEditable({ triggerFocus: true })">
    {{ placeholder }}
  </div>

  <button
    class="cc-btn cc-btn__icon cc-btn__icon editable-text-control__edit cc-btn__hover__color--secondary"
    (click)="toggleIconEditable()"
    [ngClass]="{
      'cc-btn__color--secondary': isEdit
    }"
  >
    <i class="fa-light fa-pen-to-square"></i>
  </button>
</div>
