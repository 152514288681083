<ng-container *ngIf="role.isAuthorized(premissions.multipleCompanies)">
  <div class="navigation-company-container">
    <div class="company-select" #menuTrigger>
      <app-company-image [companyName]="activeCompany?.name" [image]="activeCompanyLogo"></app-company-image>
      <div class="company-select__name">
        @if (!isLoading && activeCompany) {
          {{ activeCompany?.name }}
        } @else if (isLoading) {
          Loading...
        } @else if (!isLoading && !activeCompany) {
          No company found
        }
      </div>
      <div class="company-select__icon">
        <i class="fas fa-angle-down"></i>
      </div>
    </div>
  </div>

  <kiss-menu
    [menuTrigger]="menuTrigger"
    [contentClass]="isAuto ? 'company-select-menu company-select-menu__auto' : 'company-select-menu'"
    [xPosition]="isAuto ? 'auto' : 'right'"
    [yPosition]="isAuto ? 'auto' : undefined"
  >
    <span class="info">Your Companies</span>

    <div class="company-select-menu__content">
      <button
        kiss-menu-item
        *ngFor="let item of companies"
        (click)="onActiveCompanyChange($event, item, menuTrigger)"
        [ngClass]="{
          active: item?.id === activeCompany?.id
        }"
      >
        <app-company-image
          [image]="item?.id === activeCompany?.id ? activeCompanyLogo : item?.logo ? photoUrl + '/' + item?.logo?.accessToken : ''"
          [companyName]="item?.name"
        ></app-company-image>
        <div class="company-select-menut__name">{{ item?.name }}</div>
      </button>
    </div>
  </kiss-menu>
</ng-container>

<ng-container *ngIf="role.isAuthorized(premissions.singleCompany)">
  <div class="navigation-company-container">
    <div class="company-select">
      <app-company-image [companyName]="activeCompany?.name" [image]="activeCompanyLogo"></app-company-image>
      <div class="company-select__name">
        @if (!isLoading && activeCompany) {
          {{ activeCompany?.name }}
        } @else if (isLoading) {
          Loading...
        } @else if (!isLoading && !activeCompany) {
          No company found
        }
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="role.isAuthorized(premissions.isAdmin)">
  <div class="navigation-image-container">
    <img class="navigation-image__lg" src="assets/logo-sm.svg" alt="Culture Consultancy Logo" />
    <img class="navigation-image__circle" src="assets/logo-circle.svg" alt="Culture Consultancy Logo Circle" />
  </div>
</ng-container>
