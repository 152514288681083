import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { TeamModel } from "app/shared/models/company/team.model";
import { CompanyTeamFormService } from "./company-team-form.service";
import { UserStatusEnum } from "app/shared/enums/user-status.enum";

@Component({
  selector: "app-company-team-form",
  templateUrl: "./company-team-form.component.html"
})
export class CompanyTeamFormComponent {
  @Input() form: FormGroup;
  @Output() onDelete = new EventEmitter();
  @Input() companyLeads = [];
  @Input() hideDelete = false;
  @Input() isOnboarding = false;
  @Input() showInfoText = false;
  constructor(private _companyFormService: CompanyTeamFormService) {}

  onClickDelete(event: Event) {
    this.onDelete.next(event);
  }

  createGroup(data?: TeamModel["groups"][0]) {
    return this._companyFormService.onAddGroup({
      data,
      companyLeads: this.companyLeads
    });
  }

  onAddNew() {
    const array = this.form?.get("groups") as FormArray;
    const length = array.length + 1;

    const newGroup: any = {
      name: this._getPlaceholderName(length)
    };

    array.push(this.createGroup(newGroup));
  }

  private _getPlaceholderName(index: number) {
    return `Group name #${index}`;
  }

  onRemoveLead(group: any, leadId: any) {
    const leads = group.get("groupCultureLeadIds").value;
    const newLeads = leads.filter((lead) => lead.id !== leadId);

    group.get("groupCultureLeadIds").setValue(newLeads);
    this.form.markAsDirty();
  }

  onRemoveGroup(index: number) {
    const array = this.form?.get("groups") as FormArray;
    const group = array.at(index).value;
    const teamName = this.form.get("name").value;

    if (!group?.id) {
      this._removeGroupLocally(array, index);
    } else {
      this._companyFormService.promptGroupDelete({ group, teamName }).then((response) => {
        if (!response) return;
        this._companyFormService.onRemoveGroup(group).subscribe({
          next: () => {
            this._removeGroupLocally(array, index);
          }
        });
      });
    }
  }

  private _removeGroupLocally(array: FormArray, index: number) {
    array.removeAt(index);
    array.markAsDirty();
  }

  onToggleEdit(group: any) {
    group["isEdit"] = !group["isEdit"];
  }
}
