import { CustomToastComponent } from "../components/custom-toast/custom-toast.component";

export const DEFAULT_TOASTR_CONFIG: any = {
  toastComponent: CustomToastComponent,
  toastClass: "toast-bottom-right",
  positionClass: "toast-bottom-right"
};

export const TOASTR_AUTH_CONFIG: any = {
  toastComponent: CustomToastComponent,
  positionClass: "toast-custom-position__auth"
};

export const TOAST_AUTH_POSITION_URLS = ["/login", "/forgot-password", "/reset-password", "/set-password"];
