import { TemplateRef, Type } from '@angular/core';
import { KissOverlayRef } from './kiss-overlay-ref';

export class KissOverlay {
  template: TemplateRef<any> | Type<any> | any;
  ref: KissOverlayRef;

  constructor(template: TemplateRef<any> | Type<any>, ref: KissOverlayRef) {
    this.template = template || null;
    this.ref = ref || null;
  }
}
