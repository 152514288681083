<div class="choose-company">
  <div class="choose-company__header">
    <div class="choose-company__header--logo">
      <img src="assets/company-logo.svg" alt="logo" />
    </div>
  </div>

  <div class="choose-company__body">
    <div class="choose-company__body--text">
      <h1>Choose company</h1>
      <p>
        Hi, <span>{{ user?.name }}!</span> You have successfully logged in. The companies where you will serve as a
        {{ user?.role | enumToValue: { type: "role" } }} will be added soon and will appear on your dashboard.
      </p>
    </div>

    <div class="choose-company__body--no-companies">
      <img src="assets/svg/no-companies.svg" alt="no-companies" />
    </div>

    <div class="choose-company__body--cta">
      <button class="cc-btn cc-btn__stroked cc-btn--lg" (click)="onLogout()">Log Out</button>
    </div>
  </div>
</div>
