import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { KissNavigationModule } from "@kiss/components/common/kiss-navigation/kiss-navigation.module";
import { KissSidebarModule } from "@kiss/components/common/kiss-sidebar/kiss-sidebar.module";
import { KissNavigationSidebarComponent } from "./kiss-navigation-sidebar.component";
import { KissDialogService } from "@kiss/components/common/kiss-dialog/kiss-dialog.service";
import { NavigationUserModule } from "app/shared/components/navigation-user/navigation-user.module";
import { NavigationCompanyModule } from "app/shared/components/navigation-company/navigation-company.module";

@NgModule({
  declarations: [KissNavigationSidebarComponent],
  imports: [RouterModule, CommonModule, KissNavigationModule, KissSidebarModule, NavigationUserModule, NavigationCompanyModule],
  exports: [KissNavigationSidebarComponent],
  providers: [KissDialogService]
})
export class KissNavigationSidebarModule {}
