import { AfterViewInit, Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { KissNavigationItem } from "@kiss/components/common";
import { Subject, takeUntil } from "rxjs";
import { MainNavigationService } from "./shared/services/main-navigation.service";
import { PageTitleService } from "./shared/services/title/page-title.service";
import { CompanyColorHandlerService } from "./shared/services/company-color/company-color-handler.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements AfterViewInit {
  navigation: KissNavigationItem[] = [];

  private _destroy: Subject<void>;
  constructor(
    private router: Router,
    private _mainNavigationService: MainNavigationService,
    private _titleService: PageTitleService,
    private _companyColorHandlerService: CompanyColorHandlerService
  ) {
    this._destroy = new Subject();
  }

  ngOnInit(): void {
    this._mainNavigationService.init();
    this._titleService.init();
    this._companyColorHandlerService.init();

    this.router.events.pipe(takeUntil(this._destroy)).subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      var content = document.querySelector("kiss-content");
      content.scrollTop = 0;
      window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this._companyColorHandlerService.destroy();
    this._destroy.next();
    this._destroy.complete();
  }
}
