import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserModel } from "app/shared/models/user/user.model";
import { UserDataService } from "app/shared/services/auth/user-data.service";
import { Subscription } from "rxjs";

@Component({
  selector: "navigation-user",
  templateUrl: "./navigation-user.component.html"
})
export class NavigationUserComponent implements OnInit, OnDestroy {
  user: UserModel;
  private _subscription: Subscription;
  constructor(private _user: UserDataService) {}

  ngOnInit(): void {
    this._subscription = this._user.userData$.subscribe((response) => {
      this.user = response;
    });
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
