import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRouteSnapshot } from "@angular/router";
import { filter } from "rxjs";

@Injectable({ providedIn: "root" })
export class PageTitleService {
  private _prefix = "Culture Consultancy";

  get prefix() {
    return this._prefix;
  }

  constructor(
    private router: Router,
    private titleService: Title
  ) {}

  init(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const rootSnapshot = this.router.routerState.snapshot.root;
      const validSnapshot = this._getLastChildWithPageTitle(rootSnapshot);
      const pageTitle = validSnapshot?.data?.pageTitle;

      if (pageTitle) {
        this._setPageTitle(pageTitle, validSnapshot);
      }
    });
  }

  private _getLastChildWithPageTitle(route: ActivatedRouteSnapshot, prevValidRoute?: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    // valid route must contains page title
    const validRoute: ActivatedRouteSnapshot = route?.data["pageTitle"] ? route : prevValidRoute;

    if (route.firstChild) {
      return this._getLastChildWithPageTitle(route.firstChild, validRoute);
    }

    return validRoute;
  }

  private _setPageTitle(pageTitle: string | Function, snapshot: ActivatedRouteSnapshot) {
    let formattedTitle: string;

    if (typeof pageTitle === "function") {
      formattedTitle = pageTitle({ snapshot: snapshot });
    } else {
      formattedTitle = pageTitle;
    }

    this.titleService.setTitle(`${formattedTitle} | ${this.prefix}`);
  }
}
