import { forkJoin } from "rxjs";
import { AuthService } from "../services/auth/auth.service";
import { CompanyDataService } from "../services/company/company-data.service";
import { StorageControlService } from "../services/storage/storage-control.service";

export function initializeApp(storage: StorageControlService, auth: AuthService, company: CompanyDataService) {
  return (): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      storage.initStorageVersion();
      auth.initAuth();

      forkJoin([company.getInitialCompanyData()]).subscribe({
        next: () => {
          resolve(true);
        },
        error: async () => {
          // clear data if an error occurs on init
          await auth.clearAuthData();
          resolve(true);
        }
      });
    });
  };
}
