import { NgModule } from "@angular/core";
import { EnumToValuePipe } from "./enum-to-value.pipe";
import { FileSizePipe } from "./file-size.pipe";
import { IdPipe } from "./id.pipe";
import { TimePipe } from "./time.pipe";

const pipeList = [EnumToValuePipe, FileSizePipe, IdPipe, TimePipe];

@NgModule({
  declarations: pipeList,
  exports: pipeList
})
export class SharedPipesModule {}
