import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup, ControlContainer } from "@angular/forms";

@Component({
  selector: "app-company-onboarding-kick-off",
  templateUrl: "./company-onboarding-kick-off.component.html"
})
export class CompanyOnboardingKickOffComponent implements OnInit {
  form: FormGroup;
  @Output() onSkip = new EventEmitter();
  @Output() onNext = new EventEmitter();
  @Output() onPrev = new EventEmitter();

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = <FormGroup>this.controlContainer.control;
  }
  onClickSkip() {
    this.onSkip.next(true);
  }

  onClickNext() {
    this.onNext.next(true);
  }

  onClickPrev() {
    this.onPrev.next(true);
  }
}
