export class KissTableDefaults {
  public static styling = {
    resizable: `kiss-column-resizable`,
    colName: (name: string) => `kiss-column-${name}`,
    colAttr: "data-column-name",
    resizeHandle: "kiss-header-cell__resize-handle",
    showResizeHandle: "kiss-header-cell__show-resize-handle",
    stickyHeader: "kiss-table__sticky-header",
    typeFlex: "kiss-table__type-flex",
    typeTable: "kiss-table__type-table"
  };
}
