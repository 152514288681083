import { Component, Input } from "@angular/core";

import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CompanyModel } from "app/shared/models/company/company.model";
import { RoleService } from "app/shared/services/auth/role.service";
import { CompanyService } from "app/shared/services/company/company.service";

@Component({
  selector: "app-company-onboarding-success",
  templateUrl: "./company-onboarding-success.component.html",
  styleUrl: "./company-onboarding-success.component.scss"
})
export class CompanyOnboardingSuccessComponent implements OnInit {
  constructor(
    private _roleService: RoleService,
    private _router: Router
  ) {}

  ngOnInit(): void {}

  goToDashboard() {
    const baseRoute = this._roleService.getBaseUrl();
    this._router.navigate([baseRoute]);
  }
}
