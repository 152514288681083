import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { KissNavigationSidebar } from "./types/kiss-navigation-sidebar.types";
import { Subject } from "rxjs";

@Component({
  selector: "kiss-navigation-sidebar",
  templateUrl: "./kiss-navigation-sidebar.component.html",
  host: {
    class: "kiss-navigation-sidebar"
  }
})
export class KissNavigationSidebarComponent implements OnInit, OnDestroy {
  @Input() settings: KissNavigationSidebar;
  @Output() onExpand = new EventEmitter();

  private _unsubscribeAll: Subject<void>;
  constructor() {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openDialog() {}

  toggleExpand() {
    this.settings.expand = !this.settings.expand;
  }

  changeLang() {
    console.log("change lang");
  }
}
