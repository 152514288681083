import { gql } from "apollo-angular";
import { USER_FRAGMENT } from "../../fragments/user.fragment";
import { TEAM_FRAGMENT } from "../../fragments/team.fragment";
import { STRATEGIC_GOAL_FRAGMENT } from "../../fragments/strategic-goal.fragment";
import { COMPANY_VALUE_FRAGMENT } from "../../fragments/company-value.fragment";
import { COMPANY_FRAGMENT } from "../../fragments/company.fragment";
import { PAGINATION_FRAGMENT } from "../../fragments/pagination.fragment";
import { LOGO_FRAGMENT } from "../../fragments/logo.fragment";

export const GET_COMPANY_LIST = gql`
  ${COMPANY_FRAGMENT}
  ${TEAM_FRAGMENT}
  ${USER_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query GetCompanyList($paginate: PaginationInput!, $search: String, $filters: FilterCompanyInput) {
    companies(paginate: $paginate, search: $search, filters: $filters) {
      data {
        ...CompanyFragment
        team {
          ...TeamFragment
        }
        consultantAdminCompany {
          user {
            ...UserFragment
          }
          userId
          companyId
        }
      }
      pageInfo {
        ...PaginationFragment
      }
    }
  }
`;

export const GET_COMPANY_BY_ID = gql`
  ${COMPANY_FRAGMENT}
  ${USER_FRAGMENT}
  ${COMPANY_VALUE_FRAGMENT}
  ${STRATEGIC_GOAL_FRAGMENT}
  ${TEAM_FRAGMENT}
  query GetCompany($companyId: Int!) {
    company(id: $companyId) {
      ...CompanyFragment
      companyAdmin {
        ...UserFragment
      }
      consultantAdminCompany {
        companyId
        user {
          ...UserFragment
        }
      }
      companyValues {
        ...CompanyValueFragment
      }
      strategicGoals {
        ...StrategicGoalFragment
      }
      team {
        ...TeamFragment
      }
      companyCultureLead {
        companyId
        user {
          ...UserFragment
        }
      }
    }
  }
`;

export const GET_COMPANY_ADMIN_OF = gql`
  ${LOGO_FRAGMENT}
  query GetCompanyAdminOf {
    getMe {
      companyAdminOf {
        companyColor
        companyValues {
          id
          name
          description
          companyId
          createdAt
          updatedAt
        }
        name
        id
        logo {
          ...LogoFragment
        }
      }
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation CreateCompany($createCompanyInput: CreateCompanyInput!) {
    createCompany(createCompanyInput: $createCompanyInput) {
      isActive
    }
  }
`;

export const UPDATE_COMPANY = gql`
  ${COMPANY_FRAGMENT}
  mutation UpdateCompany($companyId: Float!, $updateCompanyInput: UpdateCompanyInput!) {
    updateCompany(companyId: $companyId, updateCompanyInput: $updateCompanyInput) {
      ...CompanyFragment
    }
  }
`;

export const TOGGLE_COMPANY_STATUS = gql`
  mutation CompanyToggleStatus($companyToggleStatusId: Int!) {
    companyToggleStatus(id: $companyToggleStatusId) {
      isActive
    }
  }
`;

export const REMOVE_COMPANY = gql`
  mutation RemoveCompany($companyId: Float!) {
    removeCompany(companyId: $companyId) {
      isDeleted
    }
  }
`;

export const ASSIGN_NEW_CONSULTANT = gql`
  mutation AssignConsultantAdminToCompanies($assignConsultantAdminToCompaniesInput: [AssignConsultantAdminToCompaniesInput!]!) {
    assignConsultantAdminToCompanies(assignConsultantAdminToCompaniesInput: $assignConsultantAdminToCompaniesInput) {
      companyId
      userId
    }
  }
`;

export const GET_ACTIVE_CONSULTANTS = gql`
  query GetActiveConsultants {
    getActiveConsultants {
      id
      name
      email
      role
    }
  }
`;

export const REASSIGN_CULTURE_LEAD = gql`
  mutation ReAssignCultureLead($userId: Float!, $teamIds: [Float!]!) {
    reAssignCultureLead(userId: $userId, teamIds: $teamIds) {
      id
      name
      email
      role
    }
  }
`;

export const REASSIGN_CONSULTING_ADMIN = gql`
  mutation ReAssignConsultantAdmin($userId: Float!, $companyIds: [Float!]!) {
    reAssignConsultantAdmin(userId: $userId, companyIds: $companyIds) {
      id
      name
      email
      role
    }
  }
`;

export const GET_CONSULTANT_AVAILABILITY = gql`
  mutation CheckConsultantAdminAvailability($userId: Float!) {
    checkConsultantAdminAvailability(userId: $userId) {
      id
      name
    }
  }
`;

export const GET_COMPANY_VALUES = gql`
  ${COMPANY_VALUE_FRAGMENT}
  query CompanyValues($companyId: Int!) {
    companyValues(companyId: $companyId) {
      ...CompanyValueFragment
    }
  }
`;

export const CREATE_COMPANY_VALUE = gql`
  ${COMPANY_VALUE_FRAGMENT}
  mutation CreateCompanyValue($createCompanyValueInput: CreateCompanyValueInput!) {
    createCompanyValue(createCompanyValueInput: $createCompanyValueInput) {
      ...CompanyValueFragment
    }
  }
`;

export const UPDATE_COMPANY_VALUE = gql`
  ${COMPANY_VALUE_FRAGMENT}
  mutation UpdateCompanyValue($companyValueId: Float!, $updateCompanyValueInput: UpdateCompanyValueInput!) {
    updateCompanyValue(companyValueId: $companyValueId, updateCompanyValueInput: $updateCompanyValueInput) {
      ...CompanyValueFragment
    }
  }
`;

export const REMOVE_COMPANY_VALUE = gql`
  mutation RemoveCompanyValue($removeCompanyValueId: Int!) {
    removeCompanyValue(id: $removeCompanyValueId) {
      id
    }
  }
`;

export const GET_COMPANY_GOALS = gql`
  ${STRATEGIC_GOAL_FRAGMENT}
  query StrategicGoals($companyId: Int!) {
    strategicGoals(companyId: $companyId) {
      ...StrategicGoalFragment
    }
  }
`;

export const CREATE_COMPANY_GOAL = gql`
  ${STRATEGIC_GOAL_FRAGMENT}
  mutation CreateStrategicGoal($createStrategicGoalInput: CreateStrategicGoalInput!) {
    createStrategicGoal(createStrategicGoalInput: $createStrategicGoalInput) {
      ...StrategicGoalFragment
    }
  }
`;

export const UPDATE_COMPANY_GOAL = gql`
  ${STRATEGIC_GOAL_FRAGMENT}
  mutation UpdateStrategicGoal($strategicGoalId: Float!, $updateStrategicGoalInput: UpdateStrategicGoalInput!) {
    updateStrategicGoal(strategicGoalId: $strategicGoalId, updateStrategicGoalInput: $updateStrategicGoalInput) {
      ...StrategicGoalFragment
    }
  }
`;

export const REMOVE_COMPANY_GOAL = gql`
  mutation RemoveStrategicGoal($removeStrategicGoalId: Int!) {
    removeStrategicGoal(id: $removeStrategicGoalId) {
      id
    }
  }
`;

export const GET_COMPANY_TEAMS = gql`
  query CompanyTeams($companyId: Int!) {
    companyTeams(companyId: $companyId) {
      id
      name
      groups {
        id
        name
        membersCount
      }
      cultureLeads {
        user {
          name
          role
          id
          email
        }
      }
    }
  }
`;

export const GET_COMPANY_GROUPS = gql`
  query CompanyGroups($companyId: Int!) {
    companyGroups(companyId: $companyId) {
      id
      name
    }
  }
`;
