import { gql } from "apollo-angular";

export const CHECKIN_FRAGMENT = gql`
  fragment CheckInFragment on CheckInSession {
    benefit
    createdAt
    date
    time
    focusAreas {
      focusArea {
        id
        title
      }
    }
    generatedId
    groups {
      groupId
      id
      group {
        id
        name
      }
    }
    learning
    links {
      url
      id
    }
    sessionId
    status
    teams {
      id
      teamId
      team {
        name
        id
        cultureLeads {
          user {
            name
            role
            id
            email
          }
        }
      }
    }
    type {
      id
      name
    }
    typeId
    updatedAt
    id
    session {
      createdAt
      companyId
      updatedAt
      type
      id
      cultureLeads {
        cultureLead {
          id
          name
        }
      }
    }
    owner {
      name
      id
      email
    }
  }
`;
