export enum WorkshopStatusEnum {
  SCHEDULED = "SCHEDULED",
  COMPLETED = "COMPLETED",
  OVERDUE = "OVERDUE"
}

export const WorkshopStatusEnumValues: {
  [key in WorkshopStatusEnum]: string;
} = {
  [WorkshopStatusEnum.COMPLETED]: "Completed",
  [WorkshopStatusEnum.OVERDUE]: "Overdue",
  [WorkshopStatusEnum.SCHEDULED]: "Scheduled"
};
