import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kissAutofocus]',
  inputs: ['kissAutofocus', 'autofocus'],
})
export class KissAutofocusDirective {
  @Input() set kissAutofocus(_) {
    this.handleElFocus(_);
  }
  @Input() set autofocus(_) {
    this.handleElFocus(_);
  }

  constructor(private elRef: ElementRef<HTMLDListElement>, private _renderer: Renderer2) {}

  private handleElFocus(value) {
    if (value) {
      this.elRef.nativeElement.focus();
      this._renderer.addClass(this.elRef.nativeElement, 'kiss-autofocus-active');
    } else {
      this.elRef.nativeElement.blur();
      this._renderer.removeClass(this.elRef.nativeElement, 'kiss-autofocus-active');
    }
  }
}
