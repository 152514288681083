<div class="navigation-user-container" *ngIf="user">
  <kiss-user-avatar [showInitials]="true" [fullName]="user.name"></kiss-user-avatar>
  <div class="user-info">
    <div class="user-info__name">
      {{ user.name }}
      <span *ngIf="user.isSponsored">(Sponsor)</span>
    </div>
    <div class="user-info__email">
      {{ user.email }}
    </div>
  </div>
</div>
