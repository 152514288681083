import { gql } from "apollo-angular";
import { USER_FRAGMENT } from "./user.fragment";

export const GROUP_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment GroupFragment on Group {
    id
    name
    isActive
    membersCount
    createdAt
    updatedAt
    teamId
    groupCultureLeads {
      user {
        ...UserFragment
      }
    }
  }
`;
