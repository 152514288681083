import { ContentChild, Directive, Inject, Input, Optional } from "@angular/core";
import { KISS_TABLE } from "../helpers/kiss-table-injector";
import { KissCellDefDirective } from "../kiss-cell/kiss-cell-def.directive";
import { KissHeaderCellDefDirective } from "../kiss-header-cell/kiss-header-cell-def.directive";

@Directive({
  selector: "[kissColumnDef]"
})
export class KissColumnDefDirective {
  name: string;
  className: string;
  /**
   * Set a column name matching a name inside `displayColumns` array
   */
  @Input() set kissColumnDef(value: string) {
    this.name = value;
    this.className = value;
  }

  private _resizable: boolean;
  /**
   * Enable/Disable resizing of a column
   */
  @Input() set resizable(value: boolean) {
    this._resizable = !!value;
  }

  get resizable(): boolean {
    return this._resizable;
  }

  constructor(@Inject(KISS_TABLE) @Optional() public _table?: any) {}

  @ContentChild(KissCellDefDirective) cell: KissCellDefDirective;
  @ContentChild(KissHeaderCellDefDirective) headerCell: KissHeaderCellDefDirective;
}
