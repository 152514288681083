import { NgModule } from '@angular/core';
import { KissContentModule } from '@kiss/components/common';
import { LayoutEmptyComponent } from './layout-empty.component';

@NgModule({
  declarations: [LayoutEmptyComponent],
  imports: [KissContentModule],
  exports: [LayoutEmptyComponent],
})
export class LayoutEmptyModule {}
