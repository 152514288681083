<div class="choose-company">
  <div class="choose-company__header">
    <div class="choose-company__header--logo">
      <img src="assets/company-logo.svg" alt="logo" />
    </div>
  </div>

  <div class="choose-company__body">
    <div class="choose-company__body--text">
      <h1>Choose company</h1>
      <p *ngIf="isAnyOnboarding">
        Hi, <span>{{ user.name }}!</span> You have been assigned as a CC Consultant for a new company. Click <br />
        <span>“Go to Onboarding”</span> to set up this company.
      </p>
      <p *ngIf="!isAnyOnboarding">
        Hi, <span>{{ user.name }}!</span> Below is the list of companies where you are assigned as a CC Consultant. Select a company below
        to view its dashboard and manage its settings.
      </p>
    </div>

    <div class="choose-company__body--companies">
      <div
        class="choose-company__body--companies__item"
        *ngFor="let company of companies"
        [ngClass]="{ inactive: !company.company.isOnboardingFinished }"
      >
        <app-company-image
          *ngIf="company.company.isOnboardingFinished"
          [companyName]="company.company.name"
          class="image"
          [image]="company.company.logo ? photoUrl + '/' + company.company.logo?.accessToken : ''"
        ></app-company-image>
        <div class="choose-company__body--companies__item--text">
          <h3>{{ company.company.name }}</h3>
          <p *ngIf="!company.company.isOnboardingFinished">This is your new company. Ready to set it up?</p>
        </div>
        <button class="cc-btn cc-btn__primary cc-btn--lg button" (click)="navigateToCompany(company.company)">
          {{ company.company.isOnboardingFinished ? "Go to " + company.company.name : "Go to Onboarding" }}
        </button>
      </div>
    </div>

    <div class="choose-company__body--cta">
      <button class="cc-btn cc-btn__stroked cc-btn--lg" (click)="onLogout()">Log Out</button>
    </div>
  </div>
</div>
